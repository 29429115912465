import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminDashboardComponent } from '../../dashboards/admin-dashboard/admin-dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/services/Auth/auth-guard.service';
import { AdminUserFilesComponent } from './UserFiles/admin-UserFiles.component';
import { AdminArchiveComponent } from './Archive/admin-Archive.component';
import { CreateFormComponent } from './CreateForm/create-form.component';
import { MyFormComponent } from './MyForms/my-form.component';
import { SharedFormComponent } from './SharedForms/shared-form.component';
import { AdminRolesAndPermissionsComponent } from './RolesAndPermissions/admin-RolesAndPermissions.component';
import { AdminManageUsersComponent } from './ManageUsers/admin-Manageusers.component';
import { AdminWidgetsComponent } from '../../dashboards/admin-dashboard/admin-widgets/admin-widgets.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '../../shared/Sharedmodule/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreviewFileComponent } from '../../shared/PreviewFile/previewFile.component';
import { MatInputModule } from '@angular/material/input';
import { myProfileComponent } from '../../shared/myProfile/myprofile.component';
import { AdminPermissionComponent } from './UserFiles/Permissions/admin-permissions.component';
import { MatButtonModule } from '@angular/material/button';
import { AddUserDialog } from './ManageUsers/add-user-dialog/add-user-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EditUserDialog } from './ManageUsers/edit-user-dialog/edit-user-dialog.component';
import { DeleteUserDialog } from './ManageUsers/delete-user-dialog/delete-user-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { PasswordResetComponent } from '../../shared/password-reset/password-reset.component';
import { DepartamentsComponent } from './Departaments/departaments.component';
import { CreateDepartamentDialog } from './Departaments/create-departament/create-departament.component';
import { EditDepartamentDialog } from './Departaments/edit-departament/edit-departament.component';
import { DeleteDepartamentDialog } from './Departaments/remove-departament/remove-departament.component';
import { SyncUserDialog } from './ManageUsers/sync-user-dialog/sync-user.component';
import { AddRoleUserDialog } from './ManageUsers/add-role-dialog/add-role-dialog.component';
import { FormBuilderComponent } from '../Form-builder/form-builder.component';
import { FormioModule } from 'angular-formio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { WorkflowConfigurationComponent } from './Workflow/workflow.component';
import { WorkflowListComponent } from './Workflow/ViewWorkflowList/workflow-list.component';
import { DisplayWorkflowComponent } from './Workflow/DisplayWorkflow/displayworkflow.component';
import { WorkflowFormsComponent } from './WorkflowForms/workflowForms.component';
import { DisplayWorkFlowFormComponent } from './WorkflowForms/ViewWorkflowForm/display-workflowForm.component';
import { EditForm } from './MyForms/edit-form.component';
import { PreviewFormDialog } from './MyForms/preview-form.component';
import { CompletedForm } from './MyForms/completed-form.component';
import { FormCompletedDialog } from './MyForms/preview-form-completed.component';
import { MarkFileAsImportantDialog } from '../../shared/dialogs/mark-as-important/mark-as-important-dialog.component';

const routes: Routes = [
  { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
  { path: 'Dashboard', component: AdminDashboardComponent },
  {
    path: 'UserFiles',
    children: [
      { path: '', redirectTo: 'Manage', pathMatch: 'full' },
      {
        path: 'Manage', component: AdminUserFilesComponent,
      },
      { 
        path: 'Permissions', redirectTo: 'Permissions/', pathMatch: 'full', 
     },
      {
        path: 'Permissions/:id', component: AdminPermissionComponent,
      }
    ]
  },
  { path: 'profile', component: myProfileComponent,   },
  { path: 'Departaments', component: DepartamentsComponent,   },
  { path: 'passwordreset', component: PasswordResetComponent,   },
  { path: 'RolesAndPermissions', component: AdminRolesAndPermissionsComponent,   },
  { path: 'Archive', component: AdminArchiveComponent,   },
  { path: 'MyForm/CreateForm', component: FormBuilderComponent,   }, 
  { path: 'workflow-form-instances', component: WorkflowFormsComponent,   }, 
  { path: 'workflow-form-instances/view/:id', component: DisplayWorkFlowFormComponent,   }, 
  { path: 'MyForm', component: MyFormComponent,   }, 
  { path: 'SharedForm', component: SharedFormComponent,   },
  { path: 'ManageUsers', component: AdminManageUsersComponent,   },
  { path: 'workflow', component: WorkflowListComponent,   },
  { path: 'workflow/workflow-configuration', component: WorkflowConfigurationComponent,   },
  { path: 'workflow/view/:id', component: DisplayWorkflowComponent,   },
  { path: 'FormBuilder', component: FormBuilderComponent,   },
  { path: 'PreviewFile', redirectTo: 'PreviewFile/', pathMatch: 'full',   },
  { path: 'PreviewFile/:id', component: PreviewFileComponent,   },
  { path: 'EditForm/:id', component: EditForm,  },
  { path: 'PreviewFormDialog', component: PreviewFormDialog,  },
  { path: 'CompletedForm/:id', component: CompletedForm,  },
  { path: 'FormCompletedDialog', redirectTo: 'FormCompletedDialog/', pathMatch: 'full',   }
  
];

@NgModule({
  declarations: [
    AdminDashboardComponent,
    AdminUserFilesComponent,
    AdminArchiveComponent,
    CreateFormComponent,
    AdminRolesAndPermissionsComponent,
    AdminManageUsersComponent,
    AdminWidgetsComponent,
    AdminPermissionComponent,
    AddUserDialog,
    EditUserDialog,
    DeleteUserDialog,
    DepartamentsComponent,
    CreateDepartamentDialog,
    EditDepartamentDialog,
    DeleteDepartamentDialog,
    MarkFileAsImportantDialog,
    SyncUserDialog,
    AddRoleUserDialog,
    FormBuilderComponent,
    MyFormComponent,
    WorkflowConfigurationComponent,
    WorkflowListComponent,
    DisplayWorkflowComponent,
    WorkflowFormsComponent,
    DisplayWorkFlowFormComponent,
    SharedFormComponent,
    EditForm,
    PreviewFormDialog,
    CompletedForm,
   FormCompletedDialog,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    BlockUIModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatSelectModule,
    FormioModule,
    MatStepperModule,
    MatRadioModule,
    MatListModule


  ],
  providers: []
})
export class AdministratorModule { }
