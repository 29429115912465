import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/User/user.service';
import decode from 'jwt-decode';

@Component({
  selector: 'app-admin-widgets',
  templateUrl: './admin-widgets.component.html',
  styleUrls: ['./admin-widgets.component.css']
})
export class AdminWidgetsComponent implements OnInit {
  public activitys;
  public userId: string;

  constructor(private _forminstanceService: UserService) { }

  ngOnInit(): void {
    this.getActivity();
  }

  public checkUserAdministrationRole(): boolean {
    const isAdmin = this.getDecodedToken().IsAdmin;
    console.log(this.getDecodedToken());;
    this.userId = this.getDecodedToken().UserId;
    if (isAdmin == 'True') {
      return true;
    }
    return false;
  }
  private getDecodedToken(): any {
    const token = localStorage.getItem('AuthToken');
    const tokenPayload: any = decode(token);
    return tokenPayload;
  }

  private getActivity() {
    this._forminstanceService. getAllActivites().subscribe(resp => {
      if (this.checkUserAdministrationRole()) {
        this.activitys = resp.body;
      } 
    });
  }
  

}


