import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { LoginService } from 'src/app/services/Login/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LogInComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private adalSvc: AdalService, private loginService: LoginService) {
    this.router.navigateByUrl('/');
  }

  ngOnInit(): void {

    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    let isAuthenticated = this.adalSvc.userInfo.authenticated;
    if (isAuthenticated) {
  
      this.router.navigateByUrl('/');
    } else {
      this.adalSvc.login();
      this.adalSvc.config.navigateToLoginRequestUrl = false;
    }
  }

}
