import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FolderService } from 'src/app/services/Folder/folder.service';


@Component({
    selector: 'app-breadcrum',
    templateUrl: './breadcrum.component.html',
    styleUrls:['./breadcrum.component.css']
})
export class BreadCrumComponent implements OnInit {
    @Output() refreshView: EventEmitter<any> = new EventEmitter();
    @Input() breadcrumElements: [];
    
    public elements = [];
    public isRoot: boolean = false;
    constructor(public dialog: MatDialog, private folderService: FolderService) {
    }
    ngOnInit(): void {
        if (this.folderService.currentParentFolderId == 766) {
            this.isRoot = true;
        }
    }
    ngOnChanges() {

        this.elements = this.breadcrumElements;
    }
    public listFiles(el): void {
        this.folderService.currentParentFolderId=el.ID;
        this.refreshView.emit(null);
    }

}

