<div class = "background-white">
<div class="btn bg-primary text-white mt-3 ml-3 mb-3" (click)="goBack()">
    <i class="fas fa-chevron-left mr-2"></i> Back
</div>
<div class="p-2" *ngIf="!spinner">

    <blockquote class="blockquote text-center">
        <h3 class="display-4 mb-0">{{currentWorkflowForm.name}}</h3>

    </blockquote>

</div>

<div *ngIf="spinner" class="d-flex justify-content-center align-items-center" style="height: 600px; width: 100%;">
    <spinner_small ></spinner_small>
</div>


<div class="ml-3 mt-3" *ngIf="!spinner">


    <mat-vertical-stepper [linear]="isLinear" #stepper [selectedIndex]='currentWorkflowForm.currentStepId'>
        <div *ngFor="let workflowstep of workflowSteps">
            <mat-step [stepControl]="firstFormGroup">
                <ng-template matStepLabel>{{workflowstep.stepDescription}}</ng-template>

                <div *ngIf="userID == workflowstep.userId">

                    <div *ngIf="workflowstep.statusDescription =='NEW'">
                        <formio [form]='form' (submit)="onSubmit($event,workflowstep)"
                            [submission]='formData'></formio>

                        <!-- <div class="btn btn-success" (click)="submitForm(workflowstep);">Submit Form</div> -->
                    </div>
                    <div *ngIf="workflowstep.statusDescription =='COMPLETED'">
                        <div class="alert alert-success mt-3" role="alert" style=" display: inline-block;">
                            You have already completed this form submition
                        </div>
                    </div>

                </div>

                <div *ngIf="userID != workflowstep.userId">

             

                    <div *ngIf="workflowstep.statusDescription =='COMPLETED'">

                        <div class="alert alert-success mt-3" role="alert" style=" display: inline-block;">
                            This step is currently compleated!
                        </div>
                    </div>
                    <div *ngIf="workflowstep.statusDescription =='NEW'">

                        <div class="alert alert-danger mt-3" role="alert" style=" display: inline-block;">
                            You dont have permission to complete this step!
                        </div>
    
                    </div>



                </div>

            </mat-step>

        </div>

        <!-- <mat-step [stepControl]="secondFormGroup">
                <ng-template matStepLabel>Fill out your name</ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Fill out your name</ng-template>
            </mat-step> -->
    </mat-vertical-stepper>




</div>
</div>