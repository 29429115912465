import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { createUserDTO, UserData } from 'src/app/components/shared/interfaces/user';
import { DepartamentService } from 'src/app/services/Departament/departament.service';
import { UserService } from 'src/app/services/User/user.service';
import { AddRoleUserDialog } from '../add-role-dialog/add-role-dialog.component';

@Component({
    selector: 'sync-user-dialog',
    templateUrl: './sync-user.component.html',
    styleUrls: ['./sync-user.component.css']
})

export class SyncUserDialog implements OnInit {

    public roles: any;
    public loading: boolean = true;
    public listOfUsers: Array<any> = [];
    public existingUsers: Array<any> = [];
    public newUsers: Array<any> = [];
    public listOfUsersWithoutRole;
    public state: number = 1;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data, private _snackBar: MatSnackBar, public dialog: MatDialog, private userService: UserService, private departamentService: DepartamentService) {

    }

    public ngOnInit(): void {
        this.state = 1;

        this.userService.getRolesForUser().subscribe(resp => {
            this.roles = resp.body;
        });

        this.listOfUsers = this.data;

        this.existingUsers = [];
        let UserToSync = [];

        this.listOfUsers.forEach(user => {

            let userDTO: createUserDTO = {

                UserData: {
                    Name: user.displayName,
                    Email: user.mail,
                    AboutMe: '',
                    Address: '',
                    Firstname: user.displayName,
                    Lastname: '',
                    Position: '',
                    Telephone: '',
                    O365UserID: user.id,
                    IsAdministrator: false
                },
                Password: '',
                RoleID: null
            }

            UserToSync.push(userDTO);

        })

        this.userService.syncUsersToDMS(UserToSync).subscribe(resp => {
         

            this.existingUsers = resp.body.ExistingUsers;
            this.newUsers = resp.body.NewUsers;
            this.loading = false;

            this.userService.getListOfUsersWithoutRole().subscribe(resp => {
                this.listOfUsersWithoutRole = resp.body;
            });

            this.openSnackBar('User synced successfully', 'bg-success');
        }, err => {
            this.loading = false;
            this.openSnackBar('User synced error', 'bg-danger');
        })
    }

    public roleAssign(): void {

        this.state = 2;

    };

    public userRole(role, user): void {

        const roleToAdd = {
            Role: role,
            User: user
        }
        const dialogRef = this.dialog.open(AddRoleUserDialog, {
            width: '600px',
            autoFocus: false,
            data: roleToAdd
        });

        dialogRef.afterClosed().subscribe(result => {
        
                this.loading = true;
                this.userService.getListOfUsersWithoutRole().subscribe(resp => {
                    this.listOfUsersWithoutRole = resp.body;
                    this.loading=false;
                },err=>{
                    this.loading=false;
                });

        });

    }

    public onNoClick(): void {
        this.dialogRef.close('close');

    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }



}