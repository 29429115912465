import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserInfo } from 'src/app/components/shared/interfaces/user';
import { DepartamentService } from 'src/app/services/Departament/departament.service';
import { DeleteUserDialog } from '../ManageUsers/delete-user-dialog/delete-user-dialog.component';
import { CreateDepartamentDialog } from './create-departament/create-departament.component';
import { EditDepartamentDialog } from './edit-departament/edit-departament.component';
import { DeleteDepartamentDialog } from './remove-departament/remove-departament.component';

@Component({
  selector: 'app-departaments',
  templateUrl: './departaments.component.html',
  styleUrls: ['./departaments.component.css']
})

export class DepartamentsComponent implements OnInit {

  public globalData;
  public dataSource: MatTableDataSource<UserInfo>;
  public document: any = null;
  public spinner: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['Logo', 'DepartmentName', 'Address', 'Description', 'CreatedDate', 'IsActive', 'Actions'];

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private departamentService: DepartamentService
  ) {
    this.getDepartamentsList();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {

  }

  private getDepartamentsList(): void {
    this.spinner = true;

    this.departamentService.getDepartaments().subscribe(resp => {
      this.globalData = resp.body;
      this.spinner = false;
      this.dataSource = new MatTableDataSource<UserInfo>(this.globalData);
      this.cdr.detectChanges();
      this.ngAfterViewInit();
    });

  }

  public addDepartament(): void {
    const dialogRef = this.dialog.open(CreateDepartamentDialog, {
      width: '600px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'submited') {
        this.getDepartamentsList();
      } else {

      }
    });

  }
  public editDepartament(departament): void {

    const dialogRef = this.dialog.open(EditDepartamentDialog, {
      width: '600px',
      autoFocus: false,
      data: departament
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'submited') {
        this.getDepartamentsList();
      } else {

      }
    });
  }

  public deleteDepartament(departament): void {

    const dialogRef = this.dialog.open(DeleteDepartamentDialog, {
      width: '600px',
      autoFocus: false,
      data: departament
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'submited') {
        this.getDepartamentsList();
      } else {

      }
    });
  }
  
}
