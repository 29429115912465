<h3 class=" p-3 display-4">Departaments</h3>


<spinner *ngIf="spinner"></spinner>
<div *ngIf="!spinner" class="animate">

    <div class="card p-3 m-3 shadow-sm">

        <div class="card-body ">

            <div class="">
                <div class="d-flex justify-content-between align-items-center">

                    <div>
 
                    </div>
                    <div>
                        <button class="btn bg-primary text-white" (click)="addDepartament()"><i class="fas fa-plus"></i> Add Departament</button>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>


                    <ng-container matColumnDef="Logo">
                        <th mat-header-cell *matHeaderCellDef > Logo </th>
                        <td mat-cell *matCellDef="let element">
                       <img [src]="element.Logo" style="width: 20px;height: 20px;"/>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="DepartmentName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Department Name </th>
                        <td mat-cell *matCellDef="let element">
                         {{element.DepartmentName}}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="Address">
                        <th mat-header-cell *matHeaderCellDef> Address </th>
                        <td mat-cell *matCellDef="let element">
                         {{element.Address}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Description">
                        <th mat-header-cell *matHeaderCellDef> Description </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.Description}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="CreatedDate">
                        <th mat-header-cell *matHeaderCellDef> CreatedDate </th>
                        <td mat-cell *matCellDef="let element">
                        {{element.CreatedDate | date}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="IsActive">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let element">
                                <span *ngIf="!element.IsActive" class="badge badge-danger p-1">InActive</span>
                                <span *ngIf="element.IsActive" class="badge badge-success p-1">Active</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button style="color:  #386CB9;" (click)="editDepartament(element)" title="edit">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button *ngIf="element.IsActive" mat-icon-button style="color: #386CB9;" title="change status"
                                (click)="deleteDepartament(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button *ngIf="!element.IsActive" mat-icon-button style="color: #386CB9;" title="change status"
                            (click)="deleteDepartament(element)">
                            <mat-icon>add</mat-icon>
                        </button>

                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10,20,50]"></mat-paginator>


            </div>
        </div>
    </div>


</div>