import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { AuthGuardService } from 'src/app/services/Auth/auth-guard.service';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { UserService } from 'src/app/services/User/user.service';
import decode from 'jwt-decode';
import * as signalR from '@aspnet/signalr';
import { NotificationDTO } from '../interfaces/workflow';
@Component({
  selector: 'wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.css'],
})
export class WrapperComponent implements OnInit {
  private hubConnection: signalR.HubConnection;
  isExpanded: boolean = true;
  isExpandedDropDown: boolean = false;
  testing: boolean = false;
  public spinner: boolean = true;
  public isAuthenticated: boolean;
  public name: string;
  public status: boolean;
  public notificationList: Array<NotificationDTO> = [];
  public numberOfUnreadNotifications: number;
  public simpleUserNavBarItems: Array<any> = [
    { label: 'Dashboard', link: 'user/Dashboard', icon: 'home' },
    { label: 'User files', link: 'user/UserFiles', icon: 'folder_shared' },
    {
      label: 'Shared with me',
      link: 'user/SharedWithMe',
      icon: 'dashboard_customize',
    },
    //{label:'Archive',link:'user/Archive',icon:'archive'},
    //{label:'Form Builder',link:'admin/FormBuilder',icon:'dashboard_customize'},
    {
      label: 'Workflow Forms',
      link: 'user/workflow-form-instances',
      icon: 'grid_view',
    },
    {
      label: 'Create Form',
      link: 'user/FormBuilder',
      icon: 'add_circle_outline',
    },
    { label: ' My Forms', link: 'user/MyForm', icon: 'list_alt' },
    { label: ' Shared Form', link: 'user/SharedForm', icon: 'folder_shared' },
  ];
  public adminNavBarItems: Array<any> = [
    { label: 'Dashboard', link: 'admin/Dashboard', icon: 'home' },
    { label: 'User files', link: 'admin/UserFiles', icon: 'folder_shared' },
    //{label:'Archive',link:'admin/Archive',icon:'archive'},
    //{label:'Roles & Permissions',link:'admin/RolesAndPermissions'},
    //{label:'Roles & Permissions',link:'admin/RolesAndPermissions'},
    {
      label: 'Departaments',
      link: 'admin/Departaments',
      icon: 'room_preferences',
    },
    { label: 'Manage Users', link: 'admin/ManageUsers', icon: 'groups' },
    { label: 'Workflow', link: 'admin/workflow', icon: 'grid_view' },
    { label: 'Manage Forms', link: 'admin/MyForm', icon: 'list_alt' },
    {
      label: 'Manage WF Forms',
      link: 'admin/workflow-form-instances',
      icon: 'model_training',
    },
  ];

  constructor(
    private auth: AuthGuardService,
    private authservice: AuthService,
    private router: Router,
    private AdalServ: AdalService,
    private userService: UserService
  ) {
    auth.checkAuthentication.subscribe((state) =>
      this.changeAuthentication(state)
    );
    auth.name.subscribe((state) => this.changeName(state));
    auth.isAdmin.subscribe((state) => this.changeRoleStatus(state));
  }

  ngOnInit(): void {
    this.isAuthenticated = this.authservice.isAuthenticated();
  }

  public getUserNotificationList(userID) {
    this.userService.getUserNotifications(userID).subscribe((resp) => {
      this.notificationList = resp.body;
        this.getUserUnreadNotifications(resp.body);
    });
  }

  public getUserUnreadNotifications(notificaitonsList){
    this.numberOfUnreadNotifications = notificaitonsList.filter((notification) => {
        return !notification.isReaded;
      }).length;
  }

  public startConnection = (userID) => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        `https://tdc-bpm-wf.azurewebsites.net/workflowHub?userId=${userID}`
      )
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch((err) => console.log('Error while starting connection: ' + err));
  };

  public addTransferChartDataListener = () => {
    this.hubConnection.on('ReceiveNotification', (data) => {
      this.notificationList.unshift(data);
      this.numberOfUnreadNotifications=this.numberOfUnreadNotifications+1;
    });
  };

  private changeAuthentication(state: boolean): void {
    this.isAuthenticated = state;
  }

  private changeName(state: string) {
    this.name = state;
  }

  private changeRoleStatus(state: boolean): void {
    this.status = state;
    this.spinner = false;

    if (this.notificationList.length == 0) {
      const token = localStorage.getItem('AuthToken');
      if (token != null) {
        const tokenPayload: any = decode(token);
        const userID = tokenPayload.UserGuidID.toUpperCase();
        this.getUserNotificationList(userID);
        this.notificationImplementation(userID);
      }
    }
  }

  public showhide(event: boolean) {
    this.isExpanded = event;
  }

  public notificationImplementation(userID) {
    this.startConnection(userID);
    this.addTransferChartDataListener();
  }

  public changeNotificationNumber(event){
    this.numberOfUnreadNotifications=event;
  }
}
