import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { FolderPost, FolderShare } from 'src/app/components/shared/interfaces/folder';
import { ServerDetails } from 'src/app/components/shared/AzureShareService/AzureshareService.component';
import { UserDataItemPut } from 'src/app/components/shared/interfaces/userDataItemInterface';

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  public currentParentFolderId: number = 766;
  public currentFolderPath: string = null;
  public linkToPreview:any=null;

  app_url: string = environment.app_Url;

  constructor(private http: HttpClient) { }

  createFolder(folerPostDto: FolderPost): Observable<any> {
    const url = this.app_url + `Api/Folder`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(url, folerPostDto, { headers: headers_object, observe: "response" });
  }

  uploadFile(formdata, folderPath: string, parentFolderID: number): Observable<any> {

    const url = this.app_url + `api/Documents/PostFile?folderPath=${folderPath}&ParentFolderID=${parentFolderID}`;

    return this.http.post(url, formdata,  {
      headers: {

      }
    });

  }

  getFolderRoot(): Observable<any> {
    const url = this.app_url + `api/NodeList/LoadTree`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  getFilesByFolderID(folderID: number): Observable<any> {
    const url = this.app_url + `api/NodeList?parentID=${folderID}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  getBreadcrumElements(folderID: number): Observable<any> {
    const url = this.app_url + `Api/BreadcrumbNode?parentID=${folderID}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  deleteFile(fileName: number): Observable<any> {
    const url = this.app_url + `api/Document?blobName=${fileName}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.delete(url, { headers: headers_object, observe: 'response' });
  }


  deleteFolder(folderid: number, blobFolderName: string): Observable<any> {
    const url = this.app_url + `api/Folder?folderId=${folderid}&blobFolderName=${blobFolderName}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.delete(url, { headers: headers_object, observe: 'response' });

  }

  downloadFile(blobName: number): Observable<any> {
    const url = this.app_url + `api/BlobStorage/DownloadBlob?blobName=${blobName}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }

  downloadFolder(blobName: string, folderPath: string): Observable<any> {
    const url = this.app_url + `api/Blobs/downloadFolderZip?blobName=${blobName}&folderPath=${folderPath}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  renameFolder(objPut, newAZName: string, oldName: string, newName: string,): Observable<any> {
    const url = this.app_url + `api/FolderEdit?newName=${newName}&oldName=${oldName}&newAZName=${newAZName}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(url, objPut, { headers: headers_object, observe: "response" });
  }


  renameFile(objPut, newAZName: string, folderPath: string, newName: string,): Observable<any> {
    const url = this.app_url + `api/DocumentEdit?newName=${newName}&folderPath=${folderPath}&newAZName=${newAZName}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(url, objPut, { headers: headers_object, observe: "response" });
  }

  shareFile(shareDTO: FolderShare): Observable<any> {

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.app_url + `api/ShareNode`;

    return this.http.post(url, shareDTO, { headers: headers_object });

  }

  getFoundFiles(searchText: string): Observable<any> {

    // let headers_object = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'api-key':ServerDetails.searchServiceAdminApiKey
    // });
    // return this.http.post(ServerDetails.searchUri+`&search=${searchText}`,{headers:headers_object,observe:'response'});

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(ServerDetails.searchUri + `&search=${searchText}&api-key=${ServerDetails.searchServiceAdminApiKey}`, options);


  }

  getFileNameAfterDecoded(storage_path:string){
    const url = this.app_url + `/api/Blobs/decodeName?storage_path=${storage_path}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }

  getFileByID(id:string):Observable<any>{
    
    const url = this.app_url + `api/Document?documentID=${id}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  getFolderAndDocumentByID(id:string):Observable<HttpResponse<any>>{
    
    const url = this.app_url + `api/file?documentID=${id}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }

  getAllAvailableTags():Observable<HttpResponse<any>>{
    
    const url = this.app_url + `api/getAllTags`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }

  getTagsByDocumentID(documentID:number):Observable<HttpResponse<any>>{
    
    const url = this.app_url + `api/getAllTagsByDocumentID?documentID=${documentID}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }

  updateTagsByDocumentID(documentID:number,tagList): Observable<any> {
    const url = this.app_url + `api/updateTagsByDocumentID?documentID=${documentID}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(url, tagList, { headers: headers_object, observe: "response" });
  }
  markAsImportant(userDataItemInterface:UserDataItemPut): Observable<any> {
    const url = this.app_url + `api/UserDataItemPermission/MarkAsImportant`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(url, userDataItemInterface, { headers: headers_object, observe: "response" });
  }


}
