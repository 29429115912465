



<h1 mat-dialog-title style="font-weight: 350;font-size: 30px;" *ngIf="state==1" >Synced users </h1>
<h1 mat-dialog-title style="font-weight: 350;font-size: 30px;" *ngIf="state==2" >Assign role to users </h1>

<spinner *ngIf="loading">

</spinner>
<mat-dialog-content class="mat-typography">
  <div *ngIf="!loading">
    <div class="row mt-3" *ngIf="state == 1" >
      <div class="col-md-6">
          <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center">
               <strong>Existing Users</strong> 
                <div class="badge badge-primary">{{existingUsers.length}}</div>
              </div>
              <div class="card-body">
                  <ul class="list-group">
                      <li class="list-group-item" *ngFor="let user of existingUsers" ><i class="fas fa-user-check mr-2"></i>  {{user.Email}} </li>
                  </ul>
              </div>
            </div>
      </div>
      <div class="col-md-6">
          <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center">
               <strong>New Users</strong> 
                <div class="badge badge-primary">{{newUsers.length}}</div>
              </div>
              <div class="card-body" *ngIf="newUsers.length > 0">
                  <ul class="list-group" >
                      <li class="list-group-item" *ngFor="let user of newUsers" ><i class="fas fa-user-plus mr-2"></i> {{user.Email}}  </li>
                  </ul>
              
              </div>
            </div>
      </div>
   </div>
  
  <div class="row p-3 tableFixHead" *ngIf="state==2">
      <table class="table  mt-3 p-2">
          <thead class="thead-dark">
            <tr>
              <th scope="col">User</th>
              <ng-container *ngFor="let role of roles">
                <th scope="col" >{{role.RoleName}}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of listOfUsersWithoutRole">
                  <th scope="row">{{user.Email}}</th>
                  <ng-container *ngFor="let role of roles">
                    <td><input type="checkbox" (change)="userRole(role,user)" ></td>
                  </ng-container>
            </tr>
          </tbody>
        </table>
  </div>
  </div>
  

</mat-dialog-content>



<mat-dialog-actions align="end" class="mt-2">
    <button mat-button mat-flat-button  (click)="onNoClick()">Cancel</button>
    <button mat-button mat-flat-button color="primary" (click)="roleAssign()" *ngIf="state==1">Assign role</button>
  </mat-dialog-actions>