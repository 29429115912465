<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node matTreeNodePadding matTreeNodePaddingIndent="20" *matTreeNodeDef="let node" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <ng-container *ngIf="!moveFile">
      <button mat-button style="background: transparent;border: none; outline: none;" (click)="showNode(node)">
        <i *ngIf="!moveFile" class="far fa-folder-open fa-2x text-warning"></i>

      </button>
      {{node.name}}

    </ng-container>

    <ng-container *ngIf="moveFile">
      <button mat-button style="background: transparent;border: none; outline: none;" (click)="showNode(node)">
      
      </button>

      <mat-checkbox *ngIf="moveFile" class="checklist-leaf-node" (change)="showSelectedNode(node)"> {{node.name}}</mat-checkbox>

    </ng-container>

  </mat-tree-node>



  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node matTreeNodePadding matTreeNodePaddingIndent="20" *matTreeNodeDef="let node;when: hasChild"
    matTreeNodePadding>
    <ng-container *ngIf="!moveFile">
      <button matTreeNodeToggle style="background: transparent;border: none;outline: none;"
        [attr.aria-label]="'Toggle ' + node.name" mat-button (click)="showNode(node)">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>

        <i *ngIf="!treeControl.isExpanded(node) " class="fas fa-folder fa-2x text-warning"></i>
        <i *ngIf="treeControl.isExpanded(node) " class="fas fa-folder-open fa-2x text-warning"></i>



      </button>
      {{node.name}}

    </ng-container>
    <ng-container *ngIf="moveFile">
      <button matTreeNodeToggle style="background: transparent;border: none;outline: none;"
      [attr.aria-label]="'Toggle ' + node.name" mat-button (click)="showNode(node)">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>

    </button>
    <mat-checkbox *ngIf="moveFile" class="checklist-leaf-node" (change)="showSelectedNode(node);"> {{node.name}}</mat-checkbox>
    </ng-container>

  </mat-tree-node>
</mat-tree>