import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-archive',
  templateUrl: './user-Archive.component.html',
  styleUrls:['./user-Archive.component.css']
})
export class SimpleUserArchiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
