<div>
    <spinner_small *ngIf="small_spinner" class="spinner_small"></spinner_small>
    <table *ngIf="!small_spinner" mat-table [dataSource]="dataSource2" matSort>

        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> <i class="fas fa-folder-open fa-2x text-warning"></i> </th>
            <td mat-cell *matCellDef="let element">
                <div (click)="viewFile(element)">
                    <div *ngIf="element.Title.includes('.pdf')"><i class="fas fa-file-pdf fa-2x text-danger"
                            style="cursor: pointer;"></i></div>
                    <div *ngIf="element.Title.includes('.xlsx')"><i class="fas fa-file-excel fa-2x text-success"
                            style="cursor: pointer;"></i></div>
                    <div *ngIf="element.Title.includes('.doc')"><i class="fas fa-file-word fa-2x text-primary"
                            style="cursor: pointer;"></i></div>
                    <div *ngIf="element.Title.includes('.PNG')"><i class="far fa-file-image fa-2x "
                            style="cursor: pointer;"></i></div>
                    <div *ngIf="element.Title.includes('.jpg')"><i class="far fa-file-image fa-2x "
                            style="cursor: pointer;"></i></div>
                    <div *ngIf="element.Title.includes('.png')"><i class="far fa-file-image fa-2x "
                            style="cursor: pointer;"></i></div>
                    <div *ngIf="element.Title.includes('.gif')"><i class="fas fa-file fa-2x "
                            style="cursor: pointer;"></i></div>
                    <div *ngIf="element.Title.includes('.txt')"><i class="fas fa-file fa-2x "
                            style="cursor: pointer;"></i></div>
                    <div *ngIf="element.Title.includes('.ppt')"><i class="fas fa-file-powerpoint fa-2x text-warning"
                            style="cursor: pointer;"></i>
                    </div>
                    <div *ngIf="element.Title.includes('.zip')"><i class="far fa-file-archive fa-2x"
                            style="cursor: pointer;"></i></div>
                </div>


                <div *ngIf="element.TypeName.includes('Folder')" (click)="showFolder(element)" style="cursor: pointer;">
                    <i class="fas fa-folder fa-2x text-warning"></i>
                </div>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.TypeName.includes('Folder')" (click)="showFolder(element)"
                    style="cursor: pointer;">
                    {{element.Title}}
                </span>
                <span *ngIf="element.TypeName.includes('Document')">
                    {{element.Title}}
                </span>
            </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="BlobSize">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
            <td mat-cell *matCellDef="let element"> {{(element.BlobSize / (1024*1024)).toFixed(2)}} MB</td>
        </ng-container>

        <ng-container matColumnDef="CreatedDate">
            <th mat-header-cell *matHeaderCellDef> Created Date </th>
            <td mat-cell *matCellDef="let element"> {{element.CreatedDate | date}}</td>
        </ng-container>
        <!-- mark as important -->
        <ng-container matColumnDef="AsImportant">
            <th mat-header-cell *matHeaderCellDef>Mark As Important </th>
            <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.MarkedAsImportant == false"  (click)="MarkAsImportant(element)" class="fa fa-star-o mr-2" style="padding-left: 10%; cursor: pointer;"></span>
                    <span *ngIf="element.MarkedAsImportant == true" (click)="MarkAsImportant(element)" class="fa fa-star mr-2" style="color: yellow; padding-left: 10%; cursor: pointer"></span>
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">

                <span *ngIf="element.TypeName.includes('Folder')">

                    <div style="position: relative;">
                        <i (click)="showModal($event)" style="cursor: pointer;"
                            class="fas fa-ellipsis-v p-2 showlittlemenu"></i>
                        <div class="smallmenu"
                            style="position: absolute;top: 0; left: 30px; display: none; z-index: 999;">
                            <ul class="list-group">
                                <li class="list-group-item list-group-item-action" (click)="deleteFolder(element)"><i
                                        class="fas fa-trash mr-2"></i> Delete</li>
                                <li class="list-group-item list-group-item-action" (click)="downloadFolder(element)"><i
                                        class="fas fa-file-download mr-2"></i>Download</li>
                                <li class="list-group-item list-group-item-action" (click)="renameFolder(element)"><i
                                        class="fas fa-file-signature mr-2"></i>Rename</li>
                                <!-- <li class="list-group-item list-group-item-action" (click)="folderDetails(element)"><i
                                        class="fas fa-info-circle mr-2"></i>Details</li> -->
                                <li class="list-group-item list-group-item-action" (click)="shareFolder(element)"><i
                                        class="fas fa-share-alt-square mr-2"></i>Share</li>
                                <li class="list-group-item list-group-item-action" (click)="FilePermissions(element)"><i
                                        class="fas fa-user-shield mr-2"></i>Permissions</li>
                                      
                            </ul>
                        </div>
                    </div>
                </span>
                <span *ngIf="element.TypeName.includes('Document')">

                    <div style="position: relative;">
                        <i (click)="showModal($event)" style="cursor: pointer;"
                            class="fas fa-ellipsis-v p-2 showlittlemenu"></i>
                        <div class="smallmenu"
                            style="position: absolute;top: 0; left: 30px; display: none; z-index: 999;">
                            <ul class="list-group">
                                <li class="list-group-item list-group-item-action" (click)="deleteFile(element)"><i
                                        class="fas fa-trash mr-2"></i> Delete</li>
                                <li class="list-group-item list-group-item-action" (click)="downloadFile(element)"><i
                                        class="fas fa-file-download mr-2"></i>Download</li>
                                <li class="list-group-item list-group-item-action" (click)="renameFile(element)"><i
                                        class="fas fa-file-signature mr-2"></i>Rename</li>
                                <!-- <li class="list-group-item list-group-item-action" (click)="fileDetails(element)"><i
                                        class="fas fa-info-circle mr-2"></i>Details</li> -->
                                <li class="list-group-item list-group-item-action" (click)="shareFile(element)"><i
                                        class="fas fa-share-alt-square mr-2"></i>Share</li>
                                <li class="list-group-item list-group-item-action" (click)="moveFile(element)"><i
                                        class="fas fa-copy mr-2"></i>Move file</li>
                                <li class="list-group-item list-group-item-action" (click)="FilePermissions(element)"><i
                                        class="fas fa-user-shield mr-2"></i>Permissions</li>
                                <li class="list-group-item list-group-item-action" (click)="fileProperties(element)"><i
                                        class="fas fa-info-circle mr-2"></i>Properties</li>
                            </ul>
                        </div>
                    </div>

                </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>


</div>