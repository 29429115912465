import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'spinner_small',
    templateUrl: './spinner_small.component.html',
    styleUrls: ['./spinner_small.component.css']
})
export class SpinnerSmallComponent implements OnInit {

    private fileID: string = null;
    public document:any=null;

    constructor() {


    }

    ngOnInit() {
   
    }

}