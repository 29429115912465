import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { AddDepartmentDTO } from 'src/app/components/shared/interfaces/departament';
import { DialogData } from 'src/app/components/shared/interfaces/folder';
import { DepartamentService } from 'src/app/services/Departament/departament.service';


@Component({
    selector: 'departament-create',
    templateUrl: './create-departament.component.html',
})

export class CreateDepartamentDialog implements OnInit {

    public hide: boolean = true;
    @BlockUI('add-departament') blockUI: NgBlockUI;

    public name = new FormControl('', [Validators.required]);
    public logo = new FormControl('', [Validators.required]);
    public description = new FormControl('', [Validators.required]);
    public address = new FormControl('', [Validators.required]);

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _snackBar: MatSnackBar,
        private departamentService: DepartamentService) { }

    public ngOnInit(): void {

    }

    public onNoClick(): void {
        this.dialogRef.close('close');
        this.openSnackBar('Action cancelled', 'bg-primary');
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    public onSubmit() {
        this.blockUI.start('Creating departament...');

        let groupDto = {
            description: this.description.value,
            displayName: this.name.value,
            groupTypes: ['Unified'],
            mailEnabled: true,
            mailNickname: this.name.value.replace(/\s/g, ''),
            securityEnabled: false
        }

        this.departamentService.createNewGroup(groupDto).subscribe(res => {

         let createDepartament: AddDepartmentDTO = {
            DepartmentName: this.name.value,
            Logo: this.logo.value,
            Address: this.address.value,
            Description: this.description.value,
            O365GroupID:res.body.id
        }

        this.departamentService.createDepartament(createDepartament).subscribe(resp=>{
            this.blockUI.stop();
            this.dialogRef.close('submited');
            this.openSnackBar('Created departament successfully', 'bg-success');
        },err=>{
            this.openSnackBar('Creating departament error', 'bg-danger');
            this.blockUI.stop();
            this.dialogRef.close('close');
        })
        },err=>{
            this.openSnackBar('Creating departament error', 'bg-danger');
            this.blockUI.stop();
            this.dialogRef.close('close');
        })
   
    }

}