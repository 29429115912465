import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { UserService } from 'src/app/services/User/user.service';


@Component({
    selector: 'add-role-dialog',
    templateUrl: './add-role-dialog.component.html',
})

export class AddRoleUserDialog implements OnInit {

    public fileData;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data, private _snackBar: MatSnackBar, private userService: UserService) { }

    ngOnInit() {
        this.fileData = this.data;
    }

    addRole(){

        this.userService.AddUserRole(this.fileData.User.UserID,this.fileData.Role.RoleID).subscribe(resp=>{
            this.openSnackBar(`Action completed successfully`, 'bg-success');
            this.dialogRef.close('submited');
        },err=>{
            this.openSnackBar(`Action error`, 'bg-success');
            this.dialogRef.close();
        });
        
    }

    public onNoClick(): void {
        this.dialogRef.close();
        this.openSnackBar(`Action cancelled`, 'bg-primary');
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}