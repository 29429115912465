<div *blockUI="'privew-form'">
    <h1 mat-dialog-title style="font-weight: 350;font-size: 30px;">Preview Form </h1>
    <form class="example-form"  >
    <div mat-dialog-content >
      <div class="row">
        <div class="col-md-12">
             <formio *ngIf="formJsonSrc" [formControl]="formJson" [form]="formJsonSrc"></formio>
              <mat-dialog-actions align="end" class="mt-2">
                <button mat-button (click)="onNoClick()" >Cancel</button>
              </mat-dialog-actions>
        </div>
    </div>
</div>