import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { LoginService } from 'src/app/services/Login/login.service';
import { UserService } from 'src/app/services/User/user.service';

@Component({
  selector: 'main-component',
  templateUrl: './main.component.html',
})
export class MainAppComponent implements OnInit {

  constructor(public router: Router, private userService: UserService, private loginService: LoginService, private AdalServ: AdalService) {

  }

  ngOnInit() {
    localStorage.getItem('AuthToken') ? this.getUserInfo() : this.setJWTToken();
  }

  private getUserInfo(): void {
    this.userService.getUserInfo().subscribe(data => {
      data.body.IsAdministrator == true ? this.router.navigateByUrl('/admin/Dashboard') : this.router.navigateByUrl('/user/Dashboard')
    });
  }

  private setJWTToken(): void {
    if (this.AdalServ.userInfo.authenticated) {
      this.loginService.login(this.AdalServ.userInfo.userName, this.AdalServ.userInfo.token).subscribe(response => {
        localStorage.setItem('AuthToken', response.body.token);
        this.getUserInfo();
      });
    } else {
      this.AdalServ.login();
    }

  }

}
