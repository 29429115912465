import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { UserPermissionPutDTO } from 'src/app/components/shared/interfaces/user';
import { userRoleDTO } from 'src/app/components/shared/interfaces/rolesInterface';

@Injectable({
  providedIn: 'root'
})
export class FilePermissionService {
  app_url: string = environment.app_Url;
  formBuilder_Url: string = environment.formBuilder_Url; 

  constructor(private http: HttpClient) {

  }

  public getFileUserListAndPermissions(dataItemID: string): Observable<HttpResponse<any>> {
    const url = this.app_url + `api/UserDataItemPermission/GetAllUsersDataItemPermissions?dataItemId=${dataItemID}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  public updatePermissionsOnFile(permissionDTO: UserPermissionPutDTO): Observable<HttpResponse<any>> {
    const url = this.app_url + `api/UserDataItemPermission/UpdatePermissions`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(url,permissionDTO, { headers: headers_object, observe: 'response' });
  }
  

  public getUserDataItemPermissions(dataItemID:number,userID:number):Observable<HttpResponse<any>>{
    const url = this.app_url + `api/UserDataItemPermission/GetUserPermissions?dataItemId=${dataItemID}&userId=${userID}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }

  public addRoleOnUser(instanceDTO: userRoleDTO):Observable<HttpResponse<any>>{

    const url = this.formBuilder_Url + 'api/userrole';

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url ,instanceDTO,{ headers: headers_object,observe:"response"});

  }



}