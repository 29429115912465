import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { workFlowListDTO } from 'src/app/components/shared/interfaces/workflow';
import { WorkflowService } from 'src/app/services/Workflow/workflow.service';
import { DeleteWorkflowDialog } from '../RemoveWorkflow/remove-workflow.component';

@Component({
  selector: 'workflow-list',
  templateUrl: 'workflow-list.component.html',
  styleUrls: ['workflow-list.component.css'],
})
export class WorkflowListComponent implements OnInit {
  public globalData;
  public dataSource: MatTableDataSource<workFlowListDTO>;
  public document: any = null;
  public spinner: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  displayedColumns: string[] = [
    'name',
    'description',
    'createdDate',
    'isActive',
    'Actions',
  ];

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private workflowService: WorkflowService,
    private _snackBar: MatSnackBar
  ) {
    this.getWorkflowList();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {}

  openSnackBar(name: string, className: string) {
    this._snackBar.open(name, `X`, {
      duration: 4000,
      panelClass: [className],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  private getWorkflowList(): void {
    this.spinner = true;

    this.workflowService.getListOfWorkflows().subscribe((resp) => {
      this.globalData = resp.body;
      this.spinner = false;
      this.dataSource = new MatTableDataSource<workFlowListDTO>(
        this.globalData
      );
      this.cdr.detectChanges();
      this.ngAfterViewInit();
    });
  }

  public addWorkflow(): void {
    this.router.navigateByUrl(this.router.url + '/workflow-configuration');
  }

  public deleteWorkflow(element): void {
    const dialogRef = this.dialog.open(DeleteWorkflowDialog, {
      width: '600px',
      autoFocus: false,
      data: element,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'submited') {
        this.getWorkflowList();
      } else {
      }
    });
  }
  public editWorkflow(element): void {
    this.router.navigateByUrl(this.router.url + '/view/' + element.id);
  }
  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
/*
    public deleteWorkflow(element): void {


        this.workflowService.deleteWorkflowByID(element.id).subscribe(resp => {
            this.openSnackBar('Workflow deleted successfully...', 'bg-success');
            this.getWorkflowList();
        }, err => {
            this.openSnackBar('Error while deleting workflow...', 'bg-danger');

        })
    }
    */
