import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { workflowFormModalStepsEnum } from 'src/app/components/shared/enums/workflowformsteps';
import { workflowForm } from 'src/app/components/shared/interfaces/workflow';
import { WorkflowService } from 'src/app/services/Workflow/workflow.service';

@Component({
    selector: 'create-workflow-dialog',
    templateUrl: 'create-workflowForm.component.html',
    styleUrls: ['create-workflowForm.component.css']
})

export class CreateWorkflowFormsDialog implements OnInit {

    public hide: boolean = true;
    @BlockUI('add-workflowform') blockUI: NgBlockUI;

    public name = new FormControl('', [Validators.required]);
    public workflowData = new FormControl('', [Validators.required]);
    public formListData = new FormControl('', [Validators.required]);
    public formList:any;
    public workflows: any;
    public stateEnum=workflowFormModalStepsEnum;
    public step:number=workflowFormModalStepsEnum.Intial_Step;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _snackBar: MatSnackBar,
        private workflowService: WorkflowService) { }

    public ngOnInit(): void {
        this.workflows = this.data.workflowList;
        this.formList =this.data.formList;
    }

    public onNoClick(): void {
        if(this.step==2){
            this.dialogRef.close('submited');
        }else{
            this.dialogRef.close('close');
        }
       
        //this.openSnackBar('Action cancelled', 'bg-primary');
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    public onSubmit() {


        //this.blockUI.start('Creating workflow form...');
        const workflowFormName = this.name.value;
        const workflowID = this.workflowData.value;
        const formID = this.formListData.value;

        let workflowPostDTO: workflowForm = {
            name: workflowFormName,
            workflowId: workflowID,
            formId: formID
        }
        this.step=workflowFormModalStepsEnum.Processing_Step;

        this.workflowService.createWorkflowForms(workflowPostDTO).subscribe(resp => {
            this.blockUI.stop();
          
            this.openSnackBar('Created Workflow Form successfully', 'bg-success');
            this.step=workflowFormModalStepsEnum.Success_step;

        }, err => {
            this.openSnackBar('Creating Workflow Form error', 'bg-danger');
            this.step=workflowFormModalStepsEnum.Error_step;
        })

    }
}