import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { FileElement } from '../../interfaces/folder';
import decode from 'jwt-decode';
import { UserDataItemPut } from '../../interfaces/userDataItemInterface';




@Component({
    selector: 'mark-as-important-dialog',
    templateUrl: './mark-as-important-dialog.component.html',
})

export class MarkFileAsImportantDialog implements OnInit {

    @BlockUI('mark-as-important') blockUI: NgBlockUI;
    public fileData: FileElement ;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
   private userID: number;
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private _snackBar: MatSnackBar,
        private folderService: FolderService
        
    ) { }


    public NameFormControl = new FormControl('', [
        Validators.required
    ]);

    ngOnInit() {
        const token = localStorage.getItem('AuthToken');
        // decode the token to get its payload
        const tokenPayload: any = decode(token);
    
        this.userID = tokenPayload.UserId;
    
        this.fileData = this.data;
    }
    public onUnMarkAsImportant(): void {
        this.FileStatusChange(false, 'Unmark');
    }

    public onMarkAsImportant() {
        this.FileStatusChange(true, 'Mark');
    }

    private FileStatusChange(status: boolean, text: string): void {
        this.blockUI.start();
        let changeStatusDTO: UserDataItemPut = {
            UserID: this.userID,
            DataItemID: this.fileData.ID,
            MarkedAsImportant: status
        }

        this.folderService.markAsImportant(changeStatusDTO).subscribe(resp => {
            this.blockUI.stop();
            this.openSnackBar(` ${text} as Important successfully`, 'bg-success');
            this.dialogRef.close('submited');

        }, err => {
            this.blockUI.stop();
            this.openSnackBar(`An error acurred!`, 'bg-danger');
            this.dialogRef.close('close');
        });
        
    }

    public onNoClick(): void {
        this.dialogRef.close();
        this.openSnackBar(`Action cancelled`, 'bg-primary');
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}