import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData } from '../../interfaces/folder';

@Component({
    selector: 'delete-file-dialog',
    templateUrl: './delete-file-dialog.component.html',
})

export class deleteFileDialog implements OnInit {
    
    @BlockUI('delete-file') blockUI: NgBlockUI;
    public fileData;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private folderService: FolderService) { }

    public NameFormControl = new FormControl('', [
        Validators.required
    ]);

    ngOnInit() {

        this.fileData = this.data;
    }

    onDeleteFile() {
        if (this.fileData.TypeName == 'Document') {

            this.blockUI.start('Deleting File...')

            this.folderService.deleteFile(this.fileData.BlobName).subscribe(response => {
                this.dialogRef.close();
                this.openSnackBar('File deleted successfully', 'bg-success');
            }, err => {
                this.dialogRef.close();
                this.blockUI.stop();

                this.openSnackBar('Error deleting file', 'bg-danger');
            })

        } else {
            this.blockUI.start('Deleting Folder...')
            let folderPath = this.folderService.currentFolderPath.slice(1) + this.fileData.Title +'/';

            this.folderService.deleteFolder(this.fileData.ID,folderPath).subscribe(response => {
                this.dialogRef.close();
                this.openSnackBar('Folder deleted successfully', 'bg-success');
            }, err => {
                this.dialogRef.close();
                this.blockUI.stop();

                this.openSnackBar('Error deleting Folder', 'bg-danger');
            })

        }

    }

    public onNoClick(): void {

        this.dialogRef.close();
        this.openSnackBar('Action cancelled', 'bg-primary');

    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}