<h1 class="display-4 p-3 ">Reset Password</h1>
<div class="animate" >
    <div class="container" *blockUI="'update-user'" >
        <div class="row justify-content-md-center" >
            <div class="col-md-6 card shadow-sm">
                <div class="card-body">
                    <div class="text-center">
                        <i class="fas fa-user-lock fa-3x"></i>
                    </div>
                    <form class="example-form" [formGroup]="updateUserForm" >
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="example-full-width" style="display: block;">
                                    <mat-label>Enter new password</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" formControlName='newpassword' autocomplete="off">
                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon style="font-size: 25px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12">
                                <mat-form-field class="example-full-width" style="display: block;">
                                    <mat-label>Repeat new password</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" formControlName='repeatpassword' autocomplete="new-password">
                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon style="font-size: 25px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        </div>
                        <button type="submit" class="btn bg-primary text-white" (click)="updatePassword()">Reset</button>
                    </form>
                </div>
          
            </div>
        </div>
    </div>
</div>
