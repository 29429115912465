import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { formDefinitionPostDTO, formInstacePostDTO } from 'src/app/components/shared/interfaces/formInstance';
import { existingRolePostDTO, rolePostDTO, userRoleDTO } from 'src/app/components/shared/interfaces/rolesInterface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

  export class FormBuilderService {
    formBuilder_Url: string = environment.formBuilder_Url;
    
    constructor(private http: HttpClient) {

    }
  
  public getFormInstance(organizationId: string): Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/forminstance?organizationId=${organizationId}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  public getInstanceNameById(instanceId: string): Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/forminstance/${instanceId}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  public getParentInstance(organizationId: string): Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/forminstance/dropdown?organizationId=${organizationId}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }
    
  public getParentForm(): Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/formdefinition`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }

  public createInstance(instanceDTO:formInstacePostDTO):Observable<HttpResponse<any>>{

    const url = this.formBuilder_Url + 'api/forminstance';

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url ,instanceDTO,{ headers: headers_object,observe:"response"});
  }

  public createForm(instanceDTO:formDefinitionPostDTO):Observable<HttpResponse<any>>{

    const url = this.formBuilder_Url + 'api/formdefinition';

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url ,instanceDTO,{ headers: headers_object,observe:"response"});

  }

  public getRoles(): Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/role`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  public createRole(instanceDTO:rolePostDTO):Observable<HttpResponse<any>>{

    const url = this.formBuilder_Url + 'api/role';

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url ,instanceDTO,{ headers: headers_object,observe:"response"});
  }

  
  
  public addRoleFromExisting(instanceDTO:existingRolePostDTO):Observable<HttpResponse<any>>{

    const url = this.formBuilder_Url + 'api/formrole/bulk';

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url ,instanceDTO,{ headers: headers_object,observe:"response"});

  }


  // Get user 
  public getUsers(): Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/user`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { headers: headers_object, observe: 'response' });
  }


  public addRoleOnUser(instanceDTO: userRoleDTO):Observable<HttpResponse<any>>{

    const url = this.formBuilder_Url + 'api/userrole';

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url ,instanceDTO,{ headers: headers_object,observe:"response"});
  }
  
  //Get All Forms
  public getAllFormsCreated():Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/formdefinition/` ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    return this.http.get(url, { headers: headers_object,observe: "response" });
  }

  //Display form by ID
  public getFormsCreated(formid:string ):Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/formdefinition/${formid}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get(url, { headers: headers_object,observe: "response" });
  }

  editForm(form: any):Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/formdefinition/${form.formId}`;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
       });
       return this.http.put(url, form, { headers: headers_object,observe: "response" });
  }
   //Form Combleted By ID
   public getFormCompleted(formid:string ):Observable<HttpResponse<any>> {
    const url = this.formBuilder_Url + `api/formdata/${formid}`;

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get(url, { headers: headers_object,observe: "response" });
  }
  
  //Form Completed
     public getFormsCompleted(formid:string):Observable<HttpResponse<any>> {
      const url = this.formBuilder_Url + `/api/formdata/formdefinition/${formid}`;
  
      let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      return this.http.get(url, { headers: headers_object,observe: "response" });
    }
}