<div *blockUI="'upload-file'">
    <h1 mat-dialog-title style="font-weight: 350;font-size: 30px;">Upload file to <small class="text-primary">/{{folderpathTitle}}</small></h1>

    <div mat-dialog-content class="my-3">
        <mat-form-field>

            <div class="myfilebrowser">

                <mat-toolbar>

                    <!-- Readonly Input to show File names -->
                    <input matInput [(ngModel)]="myfilename" readonly name="memberContactNo" />

                    <!-- Browse Button -->
                    <button mat-flat-button color="primary">
                        Browse
                    </button>

                </mat-toolbar>
                <form [formGroup]="uploadForm">
                    <!-- Fetch selected filed on change -->
                    <input type="file" #UploadFileInput id="fileUpload" (change)="fileChangeEvent($event)"
                        name="fileUpload" multiple="multiple" />
                </form>


            </div>

        </mat-form-field>

        <mat-form-field class="example-chip-list mt-3">
            <mat-label>Select tags</mat-label>
            <mat-chip-list #chipList aria-label="tag selection">
              <mat-chip
                *ngFor="let tag of tags"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(tag)">
                {{tag.Text}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="add tag..."
                #tagInput
                [formControl]="tagsCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                {{tag.Text}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>


    </div>


    <mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button mat-flat-button color="primary" [disabled]="myfilename=='Select File'" (click)="onUploadingFile()">Upload <i
                class="ml-2 fas fa-upload"></i></button>
    </mat-dialog-actions>


</div>