import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { FolderNode } from '../interfaces/folder';

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})

export class TreeViewComponent implements OnInit {

  @Output() viewFiles: EventEmitter<any> = new EventEmitter();
  @Output() selectedNode: EventEmitter<any> = new EventEmitter();
  private listOfNodes: Array<any> = [];
  ngOnChanges() {
    this.dataSource.data = this.treeData;
  }

  @Input() treeData: FolderNode[];
  @Input() moveFile: boolean = false;

  // tree view componenet options
  private _transformer = (node: FolderNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.Title,
      level: level,
      id: node.ID
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  constructor(private folderService: FolderService) {

  }

  ngOnInit(): void {

  }

  showSelectedNode(node) {

    let itemChecked = document.querySelectorAll('.mat-checkbox-input');

    this.listOfNodes.push(node);

    if (this.listOfNodes.length > 1) {
      this.listOfNodes = [];

      itemChecked.forEach(el => {
        let element = el as HTMLInputElement;
        element.checked = false;
      })

    } else {
      this.selectedNode.emit(this.listOfNodes[0].name);
    }

  }


  public showNode(node) {
    this.folderService.currentParentFolderId = node.id;
    this.viewFiles.emit(null);

  }

}
