import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { FilePermissionService } from 'src/app/services/Folder/permission.service';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData } from '../../interfaces/folder';
import { UserPermissionPutDTO } from '../../interfaces/user';

@Component({
    selector: 'add-permission-dialog',
    templateUrl: './add-permission-dialog.component.html',
})

export class AddPermissionFileDialog implements OnInit {

    @BlockUI('permission-file') blockUI: NgBlockUI;
    public fileData;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private permissionService: FilePermissionService) {


         }

    public NameFormControl = new FormControl('', [
        Validators.required
    ]);

    ngOnInit() {

        this.fileData = this.data;

    }

    onPermission() {
        let userPermisionDto: UserPermissionPutDTO = {

            DataItemID: this.fileData.element.DataItemID,
            OpValue: true,
            Operation: 'HasRead',
            UserID: this.fileData.element.UserID
        }

        
        if (this.fileData.permission == 'read' && this.fileData.action == true) {
            userPermisionDto.OpValue = true;
            userPermisionDto.Operation = 'HasRead'

        } else if (this.fileData.permission == 'read' && this.fileData.action == false) {
            userPermisionDto.OpValue = false;
            userPermisionDto.Operation = 'HasRead'

        } else if (this.fileData.permission == 'write' && this.fileData.action == true) {
            userPermisionDto.OpValue = true;
            userPermisionDto.Operation = 'HasWrite'
        } else if (this.fileData.permission == 'write' && this.fileData.action == false) {
            userPermisionDto.OpValue = false;
            userPermisionDto.Operation = 'HasWrite'
        }
        else if (this.fileData.permission == 'share' && this.fileData.action == true) {
            userPermisionDto.OpValue = true;
            userPermisionDto.Operation = 'CanShare'
        } else if (this.fileData.permission == 'share' && this.fileData.action == false) {
            userPermisionDto.OpValue = false;
            userPermisionDto.Operation = 'CanShare'
        }
        this.permissionService.updatePermissionsOnFile(userPermisionDto).subscribe(resp => {
            this.dialogRef.close();
            this.openSnackBar('Permission changed successfully','bg-success');
        },err=>{
            this.dialogRef.close();
            this.openSnackBar('Error changing permission','bg-danger');
        })
    }

    public onNoClick(): void {

        this.dialogRef.close();
        this.openSnackBar(`Action cancelled`, 'bg-primary');

    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}