import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {  FormBuilderService } from 'src/app/services/FormBuilder/formBuilder.services';
import { AddRoleDialog } from '../../shared/dialogs/add-role-users-dialog/add-role-users-dialog.component';
import { formDefinitionPostDTO, formInstacePostDTO, forminstance } from '../../shared/interfaces/formInstance';
import { existingRolePostDTO, rolePostDTO } from '../../shared/interfaces/rolesInterface';
import { Location } from '@angular/common'
@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.css']
})
export class FormBuilderComponent implements OnInit {

  
  @ViewChild('json') jsonElement?: ElementRef;
  public form: Object = { components: [] };
  public formJson: any;
  public formInstance: forminstance;
  public organizationid: string = '00000000-0000-0000-0000-000000000000'; 
  public roles;
  public parents;
  public forms;
  public selectedRadioButton:boolean;
  public roleSelectedRadioButton:boolean;
  public instanceCreatedId : string;
  public instanceCreatedName: string;
  public formCreatedId: string;
  public users: any;
  public isCompleted: boolean;
  public dataSource2: MatTableDataSource<any>;
  public selectedUsers:any;
  public roleNames: string[];
  public addedRoles: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['position', 'UserName'];
  private elementData: [];
  roleIds: any;
  public roleid:any;
  formJsonSrc: string;
  public formID:string;


  onChange(event) {

  }

  constructor(private _forminstanceService: FormBuilderService, public dialog: MatDialog,private location: Location) { }

    ngAfterViewInit(): void {
      this.dataSource2.paginator = this.paginator;
      this.dataSource2.sort = this.sort;
    }
  
  public ngOnInit(): void {
    this.getFormInstanceByOrgId();
    this.getRoles();
    this.getParentInstance();
    this.getUser();
    this.getParentForm();
  this.addNewRole()

  }

  public forminstance = new FormControl('', [Validators.required]);
  public formname = new FormControl('', [Validators.required]);
  public description = new FormControl('', [Validators.required]);
  public formName = new FormControl('', [Validators.required]);
  public formDescription = new FormControl('', [Validators.required]);
  public parent_instance = new FormControl('', [Validators.required]);
  public product_type = new FormControl('', [Validators.required]);
  public parent_form = new FormControl('', [Validators.required]);
  public role_name = new FormControl('', [Validators.required]);
  public formUser = new FormControl('', [Validators.required]);
  myselectedRoles = [];
  formRole: FormControl = new FormControl(this.myselectedRoles);
  public test:any;
  

  goBack(){
    this.location.back()
  }
  

  //Get Instance Name
  private getFormInstanceByOrgId() {
    this._forminstanceService.getFormInstance(this.organizationid).subscribe(resp => {
      this.formInstance = resp.body;
    });
  }

  //Get Roles
  private getRoles() {
    this._forminstanceService.getRoles().subscribe(resp => {
      this.roles = resp.body;
    });
  }
  
  //Get Parent Instance
  private getParentInstance() {
    this._forminstanceService.getParentInstance(this.organizationid).subscribe(resp => {
      this.parents = resp.body;
    });
  }

  //Get Parent Form
  private getParentForm() {
    this._forminstanceService.getParentForm().subscribe(resp => {
      this.forms = resp.body;
    });
  }

  //Get User
  private getUser() {
    this._forminstanceService.getUsers().subscribe(resp => {
      this.users = resp.body;
      this.elementData = this.users;
      this.dataSource2 = new MatTableDataSource<any>(this.elementData);
      this.ngAfterViewInit();
    });
  }
 

  //Submit Step1 Create Instance
  public submitInstance(){
    let formInstanceDTO: formInstacePostDTO = {
      instanceId:null,
      instanceName: this.formname.value,
      instanceDescription: this.description.value,
      parentId: this.parent_instance.value,
      organizationId:'00000000-0000-0000-0000-000000000000',
      productId: 1

    }

    this._forminstanceService.createInstance(formInstanceDTO).subscribe(resp => {
      this.instanceCreatedId = resp.body.instanceId;
      this.getInstanceNameById();

    }, err => {
    })
  }

  //Get InstanceNameById
  private getInstanceNameById() {
    this._forminstanceService.getInstanceNameById(this.instanceCreatedId).subscribe(resp => {
      this.instanceCreatedName = resp.body.instanceName;
    });
  }

  //Submit Step2 Design Form
  public submitForm(){
   this.formJson = JSON.stringify(this.form);
    let formInstanceDTO: formDefinitionPostDTO = {
      formId: null,
      instanceId: this.instanceCreatedId,
      formName: this.formName.value,
      formJsondata: this.formJson,
      description: this.formDescription.value,
      parentId: this.parent_form.value,
      isActive: true
    }

    this._forminstanceService.createForm(formInstanceDTO).subscribe(resp => {
      this.formCreatedId = resp.body.formId;
      this.GetFormByFormId();
    }, err => {
    })
  }
 
  // Submit Step3 Form Roles
  public submitRole(){
    
    let formInstanceDTO: rolePostDTO = {
      roleId: null,
      roleName: this.role_name.value,
      isActive: true
    }


    
    this._forminstanceService.createRole(formInstanceDTO).subscribe(resp => {
      this.getRoles();

      document.getElementById('add-new-role').style.display = "none";

    }, err => {
    })
      
    
  }


  //When we choose role form existing
  public chooseRoleFromExisting(){

    const roleIds: Array<any> = [];
    this.formRole.value.forEach(role => {
      roleIds.push(role.roleId)
    });

    let formInstanceDTO: existingRolePostDTO = {
      formId:this.formCreatedId,
      roleId: roleIds,
      editStatus: true,
      hiddenStatus: true,
      responsibleState: 0,
      productId: 1,
      isOwner: true,
      isActive: true
    }
    

    //roles added to get on users table
    this.addedRoles = this.formRole.value;
    this.roleNames = this.addedRoles.map(role => role.roleName);
    this.roleIds = this.addedRoles.map(role => role.roleId);
    this.displayedColumns = this.displayedColumns.concat(this.roleIds);

    this._forminstanceService.addRoleFromExisting(formInstanceDTO).subscribe(resp => {

    }, err => {
    })
  }

 
  //Submit Step4 Add User 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  addRole(user, role) {

    let objToSend = {
      user: user,
      role: role,
      action: true
    }
    
    const dialogRef = this.dialog.open(AddRoleDialog, {
      width: '600px',
      data: objToSend,
      autoFocus: false
  });
  dialogRef.afterClosed().subscribe(result => {
    objToSend.action = true;
 });
  }

  //Preview form 
  private GetFormByFormId () {
    this._forminstanceService.getFormsCreated(this.formCreatedId).subscribe(resp => {
      this.formJsonSrc =JSON.parse(resp.body.formJsondata);
    });
  }

  refresh(): void {
    window.location.reload();
}
  
  //Show form when click radio Button
  public radioChange(selected) {

    const selectedRadio = selected.value
    selectedRadio == "Create" ? this.selectedRadioButton =  true : this.selectedRadioButton = false;

  }

  // public rolesRadioChange(selected) {
  //   const selectedRadio = selected.value
  //   selectedRadio == "Create" ? this.roleSelectedRadioButton =  true : this.roleSelectedRadioButton = false;

  // }

  public addNewRole(){
    document.getElementById('add-new-role').style.display = "block";
    
  }
  public closeaddrole(){
    document.getElementById('add-new-role').style.display = "none";
    
  }
}


function organizationId(organizationId: any, string: any): any {
  throw new Error('Function not implemented.');
}
