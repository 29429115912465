<div *blockUI="'share-file'">

    <div *ngIf="state==1">

        <div mat-dialog-content>
            <div class="text-center mb-4">
                <div class="mb-2">
                    <i class="fas fa-folder fa-3x text-warning" *ngIf="fileData.TypeName=='Folder'"></i>
                    <i class="fas fa-file fa-3x" *ngIf="fileData.TypeName=='Document'"></i>
                </div>
                <div>
                    {{fileData.Title}}
                </div>
            </div>
            <h3 class="display-4" style="font-weight: 350;font-size: 28px;">Step 1: <span>Please select users</span></h3>
            <div class="my-3">
                <p >
                    <mat-form-field color="accent" style="width: 300px;" class="ml-4">
                        <mat-select [formControl]="usersMultiCtrl" placeholder="Users"  [multiple]="true" #multiSelect >
                            <mat-option>
                                <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                                    [formControl]="usersMultiFilterCtrl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let user of filteredUsersMulti | async" [value]="user">
                                {{user.UserName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
                <p class="text-primary" class="ml-4">
                    Selected Users:
                </p>
                <ul *ngFor="let user of usersMultiCtrl?.value" style="list-style: none; padding: 0px;" class="ml-4">
                    <i class="fas fa-user mr-2"></i>  <li style="display: inline;">{{user.UserName}}</li>
                </ul>
            </div>
          
    
    
        </div>
    
        <mat-dialog-actions align="end" class="mt-2">
            <button mat-button (click)="onNoClick()">Cancel</button>
            <button mat-button mat-flat-button color="primary" (click)="changeState(2)">Next</button>
        </mat-dialog-actions>

    </div>

    <div *ngIf="state==2">

       
        <div mat-dialog-content>

            
            <div class="text-center mb-4">
                <div class="mb-2">
                    <i class="fas fa-folder fa-3x text-warning" *ngIf="fileData.TypeName=='Folder'"></i>
                    <i class="fas fa-file fa-3x" *ngIf="fileData.TypeName=='Document'"></i>
                </div>
                <div>
                    {{fileData.Title}}
                </div>
            </div>

 
            <div class="my-3">
                <h3 class="display-4 mb-3" style="font-weight: 350;font-size: 28px;"> Step 2: Attach permissions to users</h3>

                <table mat-table [dataSource]="dataSource2" >
            
                 
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef> <i class="fas fa-user-alt" style="cursor: pointer;"></i> </th>
                        <td mat-cell *matCellDef="let element">
                            <div >
                                <div ><i class="fas fa-user-alt" style="cursor: pointer;"></i></div>
                            </div>
                          
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="UserName">
                        <th mat-header-cell *matHeaderCellDef> User name </th>
                        <td mat-cell *matCellDef="let element">
                       {{element.UserName}}
                        </td>
                    </ng-container>
            
                   
                    <ng-container matColumnDef="HasRead">
                        <th mat-header-cell *matHeaderCellDef > Read </th>
                        <td mat-cell *matCellDef="let element">
        
                            <label class="switch" style="margin-bottom: -0.5rem;">
                                <input type="checkbox" disabled [checked]="element.HasRead"  >
                                <div class="slider round"></div>
                            </label>
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="HasWrite">
                        <th mat-header-cell *matHeaderCellDef> Write </th>
                        <td mat-cell *matCellDef="let element">
                            <label class="switch" style="margin-bottom: -0.5rem;">
                                <input type="checkbox" [checked]="element.HasWrite" (change)="addPermission(element,'HasWrite')">
                                <div class="slider round"></div>
                              </label> </td>
                    </ng-container>
                
                    <ng-container matColumnDef="CanShare">
                        <th mat-header-cell *matHeaderCellDef> Share </th>
                        <td mat-cell *matCellDef="let element">
                            <label class="switch" style="margin-bottom: -0.5rem;">
                                <input type="checkbox" [checked]="element.CanShare" (change)="addPermission(element,'CanShare')">
                                <div class="slider round"></div>
                            </label>
                        </td>
                    </ng-container>
    
    
                    <ng-container *ngIf="fileData.TypeName=='Folder'" matColumnDef="CanUpload">
                        <th mat-header-cell *matHeaderCellDef> Upload </th>
                        <td mat-cell *matCellDef="let element">
                            <label class="switch" style="margin-bottom: -0.5rem;">
                                <input type="checkbox" [checked]="element.CanUpload" (change)="addPermission(element,'CanUpload')">
                                <div class="slider round"></div>
                            </label>
                        </td>
                    </ng-container>
    
    
    
    
                
    
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
                </table>
    

            </div>

    
        </div>
    
        <mat-dialog-actions align="end" class="mt-2">
            <button mat-button (click)="changeState(1)">Back</button>
            <button mat-button mat-flat-button color="primary" (click)="shareFile()">Share</button>
        </mat-dialog-actions>

    </div>

   




</div>