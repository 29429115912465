import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormBuilderService } from 'src/app/services/FormBuilder/formBuilder.services';
import { FormCompletedDialog } from './preview-form-completed.component';

@Component({
    selector: 'completed-form',
    templateUrl: './completed-form.component.html',
    styleUrls: ['./completed-form.component.css']
  })

  export class CompletedForm implements OnInit {
    public formDefinationId:string;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public dataSource: MatTableDataSource<any>;
    public allForms: any;
     public spinner: boolean = true;
    public forms = new FormControl('', [Validators.required]);
    displayedColumns: string[] = ['FormNr','CompletedDate', 'ViewCompletedForm'];


    

    constructor(public dialog: MatDialog,
      private formService: FormBuilderService,private route: ActivatedRoute) { 
        this.formDefinationId = this.route.snapshot.paramMap.get('id');
      }
        public ngOnInit(): void {
         this.getFormsCompleted();

      }
      ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }

      getFormsCompleted(): void {
       this.spinner = true;
  
      this.formService.getFormsCompleted(this.formDefinationId).subscribe(resp => {
         this.allForms = resp.body;
        this.spinner = false;
        this.dataSource = new MatTableDataSource<any>(this.allForms);
        this.ngAfterViewInit();
      });
     }

     public OnPreviewClick(form): void {

      const dialogRef = this.dialog.open(FormCompletedDialog, {
        width: '600px',
        autoFocus: false,
        data: form
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'submited') {
        } else {
  
        }
      });
    }
}
