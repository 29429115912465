<div *blockUI="'departament-remove'">
    <h1 mat-dialog-title class="text-center"><i class="fas fa-3x fa-exclamation-triangle text-warning"></i> </h1>
    <h2 class="text-center">Are you sure ?</h2>
    <h4 *ngIf="this.fileData.IsActive" class="text-center display-4 py-4" style="font-size: 1.2rem;">You are about to make departament <strong>{{fileData.DepartmentName}}</strong> InActive !</h4>
    <h4 *ngIf="!this.fileData.IsActive" class="text-center display-4 py-4" style="font-size: 1.2rem;">You are about to make departament <strong>{{fileData.DepartmentName}}</strong> Active !</h4>

    <mat-dialog-actions align="center" class="mt-2">
        <button *ngIf="this.fileData.IsActive" mat-button mat-flat-button color="primary"  (click)="onMakeDepartamentInActive()">Yes! procceed</button>
        <button *ngIf="!this.fileData.IsActive" mat-button mat-flat-button color="primary"  (click)="onMakeDepartamentActive()">Yes! procceed</button>
        <button mat-button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
    </mat-dialog-actions>
    
</div>