import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { ChangeDepStatusDTO } from 'src/app/components/shared/interfaces/departament';
import { DepartamentService } from 'src/app/services/Departament/departament.service';

@Component({
    selector: 'remove-departament-dialog',
    templateUrl: './remove-departament.component.html',
})

export class DeleteDepartamentDialog implements OnInit {

    @BlockUI('departament-remove') blockUI: NgBlockUI;
    public fileData;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private _snackBar: MatSnackBar,
        private departamentService: DepartamentService
    ) { }


    public NameFormControl = new FormControl('', [
        Validators.required
    ]);

    ngOnInit() {
        this.fileData = this.data;
    }

    public onMakeDepartamentActive(): void {
        this.departamentStatusChange(true, 'addedd');
    }

    public onMakeDepartamentInActive() {
        this.departamentStatusChange(false, 'removed');
    }

    private departamentStatusChange(status: boolean, text: string): void {
        this.blockUI.start();

        let changeStatusDTO: ChangeDepStatusDTO = {
            NewStatus: status,
            DepartmentData: this.fileData
        }

        this.departamentService.changeStatusOfDepartament(changeStatusDTO).subscribe(resp => {
            this.blockUI.stop();
            this.openSnackBar(`Departament ${text} successfully`, 'bg-success');
            this.dialogRef.close('submited');

        }, err => {
            this.blockUI.stop();
            this.openSnackBar(`An error acurred!`, 'bg-danger');
            this.dialogRef.close('close');
        });
        
    }

    public onNoClick(): void {
        this.dialogRef.close();
        this.openSnackBar(`Action cancelled`, 'bg-primary');
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}