import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-body-pagination',
  templateUrl: './card-body-pagination.component.html',
  styleUrls: ['./card-body-pagination.component.css']
})
export class CardBodyPaginationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
