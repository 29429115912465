import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkflowDTO } from 'src/app/components/shared/interfaces/workflow';
import { FormBuilderService } from 'src/app/services/FormBuilder/formBuilder.services';
import { UserService } from 'src/app/services/User/user.service';
import decode from 'jwt-decode';
import { WorkflowService } from 'src/app/services/Workflow/workflow.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
declare var flowy: any;
declare var $:any;
@Component({
    selector: 'workflow-configuration',
    templateUrl: 'workflow.component.html',
    styleUrls: ['workflow.component.css']
})

export class WorkflowConfigurationComponent implements OnInit, OnDestroy {
    @BlockUI('create-workflow') blockUI: NgBlockUI;
    public currentElementID: number = 0;
    public spinner:boolean = true;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(private userService: UserService,
         private formService: FormBuilderService
         ,private workflowService:WorkflowService,
          private _snackBar: MatSnackBar,
          public router: Router
          ) {


    }


    ngOnDestroy(): void {
        location.reload();
    }

    ngOnInit() {
        this.handleMultipleRequests();
        this.initializeWorkflow();
    }

    public goBack(): void {
        this.router.navigateByUrl(this.router.url.replace('/workflow-configuration', ''));
    }


    private handleMultipleRequests() {

        //const inputHiddenFormList = document.getElementById('formsList');
        const inputHiddenUserList = document.getElementById('userList');

        forkJoin([
            this.userService.getListOfUsers(),
        ]).subscribe(allResults => {
         inputHiddenUserList.setAttribute('value', JSON.stringify(allResults[0].body));
         this.spinner=false;
        });

    }
    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 4000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    public saveWorkflow(): void {
     
        if(!(document.getElementById('workflowName') as HTMLInputElement).value && !(document.getElementById('workflowDescription') as HTMLInputElement).value ){
            (document.getElementById('workflow-dsc-error') as HTMLInputElement).style.display = "block"; 
            (document.getElementById('workflow-name-error') as HTMLInputElement).style.display = "block"; 
            return;
        }
        else if ( !(document.getElementById('workflowName') as HTMLInputElement).value ){
            (document.getElementById('workflow-name-error') as HTMLInputElement).style.display = "block";
            (document.getElementById('workflow-dsc-error') as HTMLInputElement).style.display = "none";
            return;
        }
        else if ( !(document.getElementById('workflowDescription') as HTMLInputElement).value ){
            (document.getElementById('workflow-dsc-error') as HTMLInputElement).style.display = "block";
            (document.getElementById('workflow-name-error') as HTMLInputElement).style.display = "none"; 
            return;
        }
        else{
            (document.getElementById('workflow-name-error') as HTMLInputElement).style.display = "none"; 
            (document.getElementById('workflow-dsc-error') as HTMLInputElement).style.display = "none";

            this.spinner=true;
            this.openSnackBar('Creating Workflow...', 'bg-primary');
            const token = localStorage.getItem('AuthToken');
            // decode the token to get its payload
            const tokenPayload: any = decode(token);

            const userID  = tokenPayload.UserGuidID;

            //const formID = (document.getElementById('formID') as HTMLInputElement).value;
            const wfName = (document.getElementById('workflowName') as HTMLInputElement).value;
            const wfDescription = (document.getElementById('workflowDescription') as HTMLInputElement).value;
            let cofigurationWorkflowSchema :WorkflowDTO = {
        
                Name: wfName,
                UserId: userID,
                Description: wfDescription,
                Content: flowy.output()
            }

            this.workflowService.createWorkflow(cofigurationWorkflowSchema).subscribe(resp=>{
            console.log("🚀 ~ file: workflow.component.ts ~ line 78 ~ WorkflowConfigurationComponent ~ this.workflowService.createWorkflow ~ resp", resp)
            this.openSnackBar('Workflow created successfully...', 'bg-success');
            this.spinner=false;
            this.goBack();
            },err=>{
                this.spinner=false;
                this.openSnackBar(JSON.stringify(err.error.errors), 'bg-danger');
            });
        }
    }

    public initializeWorkflow() {

        var rightcard = false;
        var tempblock;
        var tempblock2;
        var currentSelectedElementID;
        var hasStepStart = false;
        var hasStepEnd = false;
        var cuurrentFormID = null;
        console.log(tempblock);

        console.log(tempblock2);
        //document.getElementById("blocklist").innerHTML = '';
        flowy(document.getElementById("canvas"), drag, release, snapping);
        function addEventListenerMulti(type, listener, capture, selector) {
            var nodes = document.querySelectorAll(selector);
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].addEventListener(type, listener, capture);
            }
        }
        function snapping(drag, first) {
            console.log("🚀 ~ file: workflow.component.ts ~ line 64 ~ WorkflowConfigurationComponent ~ snapping ~ drag", drag)

            var grab = drag.querySelector(".grabme");
            grab.parentNode.removeChild(grab);
            var blockin = drag.querySelector(".blockin");
            blockin.parentNode.removeChild(blockin);
            if (drag.querySelector(".blockelemtype").value == "1") {

                if (!hasStepStart) {
                    drag.innerHTML += "<div class='blockyleft'><img src='./assets/img/eyeblue.svg'><p class='blockyname'>Start</p></div><div class='blockydiv'></div><div class='blockyinfo'>When a <span>approval process</span> starts the action</div>";
                    hasStepStart = true;

                } else {
                    return false;
                }

            } else if (drag.querySelector(".blockelemtype").value == "2") {
                drag.innerHTML += "<div class='blockyleft'><img src='./assets/img/actionorange.svg'><p class='blockyname'>Approve User</p></div> <div class='blockydiv'></div><div class='blockyinfo'>Assign user to  <span>approving</span> proccess</div>";
            } else if (drag.querySelector(".blockelemtype").value == "3") {


             
                    drag.innerHTML += "<div class='blockyleft'><img src='./assets/img/timeblue.svg'><p class='blockyname'>End</p></div> <div class='blockydiv'></div><div class='blockyinfo'>End approval <span>proccess</span> </div>";
                   

                

            }
            return true;
        }
        function drag(block) {
            console.log("drag");
            block.classList.add("blockdisabled");
            tempblock2 = block;
        }
        function release() {
            console.log("relesed");
            if (tempblock2) {
                tempblock2.classList.remove("blockdisabled");
            }
        }
        var disabledClick = function () {
            document.querySelector(".navactive").classList.add("navdisabled");
            document.querySelector(".navactive").classList.remove("navactive");
            this.classList.add("navactive");
            this.classList.remove("navdisabled");

            if (this.getAttribute("id") == "loggers") {
                document.getElementById("blocklist").innerHTML = '<div class="blockelem create-flowy noselect"><input type="hidden" name="blockelemtype" class="blockelemtype" value="9"><div class="grabme"><img src="./assets/img/grabme.svg"></div><div class="blockin">                  <div class="blockico"><span></span><img src="./assets/img/log.svg"></div><div class="blocktext">                        <p class="blocktitle">Add new log entry</p><p class="blockdesc">Adds a new log entry to this project</p>        </div></div></div><div class="blockelem create-flowy noselect"><input type="hidden" name="blockelemtype" class="blockelemtype" value="10"><div class="grabme"><img src="./assets/img/grabme.svg"></div><div class="blockin">                  <div class="blockico"><span></span><img src="./assets/img/log.svg"></div><div class="blocktext">                        <p class="blocktitle">Update logs</p><p class="blockdesc">Edits and deletes log entries in this project</p>        </div></div></div><div class="blockelem create-flowy noselect"><input type="hidden" name="blockelemtype" class="blockelemtype" value="11"><div class="grabme"><img src="./assets/img/grabme.svg"></div><div class="blockin">                  <div class="blockico"><span></span><img src="./assets/img/error.svg"></div><div class="blocktext">                        <p class="blocktitle">Prompt an error</p><p class="blockdesc">Triggers a specified error</p>        </div></div></div>';
            }
        }

        addEventListenerMulti("click", disabledClick, false, ".side");
        document.getElementById("close").addEventListener("click", function () {
            closeRightCard();
        });

        // Remove elements from workflow
        document.getElementById("removeblock").addEventListener("click", function () {

            if(flowy.output() != undefined){
                $('.mat-drawer-content').css("z-index", " unset");
                $('#delete-config-popup').modal('show');
            }
        });
        document.getElementById("delete-config").addEventListener("click", function () {
            flowy.deleteBlocks();
            resetStartEndElement();
            closeRightCard();
            $('#delete-config-popup').modal('hide');
            $('.mat-drawer-content').css("z-index", " 1");
        });
        document.getElementById("cancel-delete").addEventListener("click", function () {
            $('.mat-drawer-content').css("z-index", " 1");
        });

        function resetStartEndElement() {
            hasStepStart = false;
            hasStepEnd = false;
        }

        function closeRightCard() {
            if (rightcard) {
                rightcard = false;
                document.getElementById("properties").classList.remove("expanded");
                setTimeout(function () {
                    document.getElementById("propwrap").classList.remove("itson");
                }, 300);
                tempblock.classList.remove("selectedblock");
            }

        }


        var aclick = false;
        var noinfo = false;
        var beginTouch = function (event) {
            aclick = true;
            noinfo = false;
            if (event.target.closest(".create-flowy")) {
                noinfo = true;
            }
        }
        var checkTouch = function (event) {
            aclick = false;
        }
        var doneTouch = function (event) {

            if (event.type === "mouseup" && aclick && !noinfo) {

                if (!rightcard && event.target.closest(".block") && !event.target.closest(".block").classList.contains("dragging")) {

                    tempblock = event.target.closest(".block");
                    currentSelectedElementID = parseInt(tempblock.firstChild.getAttribute("value"));

                    document.getElementById('elementID').setAttribute('value', tempblock.firstChild.getAttribute("value"));
                    rightcard = true;
                    document.getElementById("properties").classList.add("expanded");
                    document.getElementById("propwrap").classList.add("itson");

                    tempblock.classList.add("selectedblock");

                    var elemetDetails = null;
                    tempblock.children.forEach(elemt => {
                        if (elemt.getAttribute('name') == 'configurationadata') {
                            elemetDetails = JSON.parse(elemt.getAttribute('value'));
                        }
                    });

                    if (currentSelectedElementID == 1) {
                        configureStartElementRightCard();

                    } else if (currentSelectedElementID == 2) {
                        configureApproveElementRightCard();

                    } if (currentSelectedElementID == 3) {
                        configureEndElementRightCard();
                    }

                    assignValuesOnUpdateElement(elemetDetails);

                }
            }
        }

            // <div class="form-group">
            // <label for="chooseForm">Choose Form</label>
            // <select class="form-control" id="chooseForm">
            // </select>
            // </div>



        function configureStartElementRightCard() {

            const userList = JSON.parse(document.getElementById('userList').getAttribute('value'));

            // const formsList = JSON.parse(document.getElementById('formsList').getAttribute('value'));

            document.getElementById("proplist").innerHTML = `

            <div class="form-group">
            <label for="activityName">Activity Name</label>
            <input type="text" class="form-control" id="activityName" >
            <mat-error id = "activity-name-error" style="display: none;" class="mat-error">Activity Name is required...</mat-error>
            </div>

            <div class="form-group">
            <label for="activityDescriptionStart">Activity Description</label>
            <textarea class="form-control" id="activityDescriptionStart" rows="3"></textarea>
            <mat-error id = "activity-dsc-error" style="display: none;" class="mat-error">Activity Description is required...</mat-error>
            </div>

   
            <div class="form-group">
            <label for="chooseUser">Assign User</label>
            <select class="form-control" id="chooseUser">
           
          </select>
        </div>

            `;


            // let formDropdownList = document.getElementById('chooseForm');
            // formsList.forEach(form => {
            //     formDropdownList.innerHTML = formDropdownList.innerHTML +
            //         `<option value="${form.formId}">${form.formName} </option>`;
            // })


            let userDropDownList = document.getElementById('chooseUser');
            userList.forEach(user => {
                userDropDownList.innerHTML = userDropDownList.innerHTML +
                    `<option value="${user.GuidID}">${user.Firstname} ${user.Lastname}</option>`;
            })

            // if (cuurrentFormID != null) {

            //     (document.getElementById('chooseForm') as HTMLInputElement).value = cuurrentFormID;
            // }
          

        }

        function configureApproveElementRightCard() {


            const userList = JSON.parse(document.getElementById('userList').getAttribute('value'));

            document.getElementById("proplist").innerHTML = `

            <div class="form-group">
            <label for="activityName">Activity Name</label>
            <input type="text" class="form-control" id="activityName" >
            <mat-error id = "activity-name-error" style="display: none;" class="mat-error">Activity Name is required...</mat-error>
            </div>
            <div class="form-group">
            <label for="activityDescriptionStart">Activity Description</label>
            <textarea class="form-control" id="activityDescriptionStart" rows="3"></textarea>
            <mat-error id = "activity-dsc-error" style="display: none;" class="mat-error">Activity Description is required...</mat-error>
        </div>

        <div class="form-group">
            <label for="chooseUser">Assign User</label>
            <select class="form-control" id="chooseUser">

            </select>
          </div>
            `;

            let userDropDownList = document.getElementById('chooseUser');
            userList.forEach(user => {
                userDropDownList.innerHTML = userDropDownList.innerHTML +
                    `<option value="${user.GuidID}">${user.Firstname} ${user.Lastname}</option>`;
            })

        }


        function configureEndElementRightCard() {
            const userList = JSON.parse(document.getElementById('userList').getAttribute('value'));

            document.getElementById("proplist").innerHTML = `

            <div class="form-group">
            <label for="activityName">Activity Name</label>
            <input type="text" class="form-control" id="activityName" >
            <mat-error id = "activity-name-error" style="display: none;" class="mat-error">Activity Name is required...</mat-error>
            </div>
            <div class="form-group">
            <label for="activityDescriptionStart">Activity Description</label>
            <textarea class="form-control" id="activityDescriptionStart" rows="3"></textarea>
            <mat-error id = "activity-dsc-error" style="display: none;" class="mat-error">Activity Description is required...</mat-error>
        </div>

        <div class="form-group">
            <label for="chooseUser">Assign User</label>
            <select class="form-control" id="chooseUser">

            </select>
          </div>
            `;

            let userDropDownList = document.getElementById('chooseUser');
            userList.forEach(user => {
                userDropDownList.innerHTML = userDropDownList.innerHTML +
                    `<option value="${user.GuidID}">${user.Firstname} ${user.Lastname}</option>`;
            })

        }



        function assignValuesOnUpdateElement(elemetDetails) {
            if (elemetDetails != null) {
                console.log("🚀 ~ file: workflow.component.ts ~ line 341 ~ WorkflowConfigurationComponent ~ doneTouch ~ elemetDetails", elemetDetails);
                (document.getElementById('activityName') as HTMLInputElement).value = elemetDetails.ElementName;
                (document.getElementById('activityDescriptionStart') as HTMLInputElement).value = elemetDetails.ElementDescription;
                (document.getElementById('chooseUser') as HTMLInputElement).value = elemetDetails.AssignUser;
            }
        }



        // Button that saves the configuration for a specific element
        document.getElementById("saveConfiguration").addEventListener("click", function () {


            if(!(document.getElementById('activityName') as HTMLInputElement).value && !(document.getElementById('activityDescriptionStart') as HTMLInputElement).value ){
                (document.getElementById('activity-dsc-error') as HTMLInputElement).style.display = "block"; 
                (document.getElementById('activity-name-error') as HTMLInputElement).style.display = "block"; 
            }
            else if ( !(document.getElementById('activityName') as HTMLInputElement).value ){
                (document.getElementById('activity-name-error') as HTMLInputElement).style.display = "block";
                (document.getElementById('activity-dsc-error') as HTMLInputElement).style.display = "none";
            }
            else if ( !(document.getElementById('activityDescriptionStart') as HTMLInputElement).value ){
                (document.getElementById('activity-dsc-error') as HTMLInputElement).style.display = "block";
                (document.getElementById('activity-name-error') as HTMLInputElement).style.display = "none"; 
            }
            else{
                storeElementConfiguration();
                closeRightCard();
            }
        });

        function storeElementConfiguration() {
            // create input type hidden to store data of configuration
            var configurationWorkflow = document.createElement('input');
            configurationWorkflow.setAttribute("type", "hidden");
            configurationWorkflow.setAttribute("name", "configurationadata");

            let userID = (document.getElementById('chooseUser') as HTMLInputElement).value;
            let activityDescription = (document.getElementById('activityDescriptionStart') as HTMLInputElement).value;
            let activityName = (document.getElementById('activityName') as HTMLInputElement).value;
            let activityType = '';


            if (currentSelectedElementID == 1) {
                // let formID = (document.getElementById('chooseForm') as HTMLInputElement).value;
                // cuurrentFormID = formID;

    
                // (document.getElementById('formID') as HTMLInputElement).value = formID;

                activityType = 'Start';
            }

            if (currentSelectedElementID == 2) {
                activityType = 'Approve';
            }

            if (currentSelectedElementID == 3) {
                activityType = 'End';
            }


            // object that will be used to send the configuration data to backend 
            let ConfigurationElement = {
                ElementType: activityType,
                ElementName: activityName,
                ElementDescription: activityDescription,
                AssignUser: userID,
                Links: []
            }

            configurationWorkflow.setAttribute("value", JSON.stringify(ConfigurationElement));

            // check if element exists than delete it 

            tempblock.children.forEach(elemt => {
                if (elemt.getAttribute('name') == 'configurationadata') {
                    elemt.remove();
                }

                if (elemt.getAttribute('class') == 'blockyleft') {
                    let icon = '';
                    if (currentSelectedElementID == 1) {
                        icon = `<img src='./assets/img/eyeblue.svg'>`;
                    }
                    if (currentSelectedElementID == 2) {
                        icon = `<img src='./assets/img/actionblue.svg'>`;
                    }
                    if (currentSelectedElementID == 3) {
                        icon = `<img src='./assets/img/timeblue.svg'>`;
                    }

                    elemt.innerHTML = `
                  ${icon}<p class='blockyname'>${activityName}</p>
                  `
                }

                if (elemt.getAttribute('class') == 'blockyinfo') {
                    elemt.innerHTML = `
                  ${activityDescription}
                  `
                }

            })
            tempblock.appendChild(configurationWorkflow);


            
        }


        addEventListener("mousedown", beginTouch, false);
        addEventListener("mousemove", checkTouch, false);
        addEventListener("mouseup", doneTouch, false);
        addEventListenerMulti("touchstart", beginTouch, false, ".block");

    }
}