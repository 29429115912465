<h3 class="p-3 display-4">User files</h3>

<div class="px-5 animate mb-3" >
  <div class="card shadow-sm">
    <app-card-header (refresh)="onRefresh($event)" (refreshui)="onRefreshUi($event)"></app-card-header>
    <div class="card-body" style="padding: 0px; height: 70vh;">
      <div class="row">
        <div id="tree-list" class="col-md-2" style="border-right: 1px solid rgb(230, 224, 224); height: 70vh; overflow-y: auto;">
          <app-tree-view [treeData]="tree_data" (viewFiles)="onViewFiles($event)"> </app-tree-view>
        </div>
        <div id="table-list" class="col-md-10" style="height: 70vh; overflow-y: auto;">
          <div class="d-flex justify-content-between align-items-center mt-2"
            style="border-bottom: solid 1px rgb(230, 224, 224);">
            <div class="nav">
              <app-breadcrum [breadcrumElements]='breadcrumElements' (refreshView)="onViewFiles($event)"></app-breadcrum>
            </div>
            <div class="search">
              <div class="col-auto">
                <label class="sr-only" for="inlineFormInputGroup">Username</label>
                <form class="input-group mb-2" (ngSubmit)="searchForFile()">
                  <div class="input-group-prepend">
                    <button class="input-group-text" type="submit" style="cursor: pointer;" ><i class="fas fa-search"></i></button>
                  </div>
                  <input type="text" [formControl]="searchFormControl"  class="form-control" id="inlineFormInputGroup" placeholder="Search document"> 
                </form>
              </div>
            </div>
          </div>
          <div class="files">
            <app-card-body [elementData]='element_data' [small_spinner]='spinner_small' (listView)="onViewFiles($event)"></app-card-body>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>