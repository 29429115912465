import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilePermissionService } from 'src/app/services/Folder/permission.service';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData } from '../../interfaces/folder';
import { userRoleDTO } from '../../interfaces/rolesInterface';

@Component({
    selector: 'add-role-users-dialog',
    templateUrl: './add-role-users-dialog.component.html',
})

export class AddRoleDialog implements OnInit {

    @BlockUI('permission-file') blockUI: NgBlockUI;
    public fileData;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private permissionService: FilePermissionService) {


         }

    public NameFormControl = new FormControl('', [
        Validators.required
    ]);

    ngOnInit() {

        this.fileData = this.data;

    }

    addRole() {
        let userRoleDto: userRoleDTO = {
        userId: this.fileData.user.userId,
        roleId: this.fileData.role.roleId
        }
        this.permissionService.addRoleOnUser(userRoleDto).subscribe(resp => {
            
            this.dialogRef.close();
            this.openSnackBar('Permission changed successfully','bg-success');
        },err=>{
            this.dialogRef.close();
            this.openSnackBar('Error changing permission','bg-danger');
        })
    }

    public onNoClick(): void {

        this.dialogRef.close();
        this.openSnackBar(`Action cancelled`, 'bg-primary');

    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}