<div >
   
    <div mat-dialog-content class="my-3">
    

    
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" data-toggle="tab" href="#general" >General</a>
            </li>
            <!-- <li class="nav-item" role="presentation">
              <a class="nav-link" data-toggle="tab" href="#Permissions">Permissions</a>
            </li> -->
            <li class="nav-item" role="presentation">
              <a class="nav-link" data-toggle="tab" href="#Archive">Archive</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" data-toggle="tab" href="#WF">Configure WF</a>
            </li>
          </ul>
          <div class="tab-content py-3" id="myTabContent">
            <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="home-tab">

                <div class="border-bottom py-2">
                    <div class="mb-2">
                        <i class="fas fa-folder fa-3x text-warning" *ngIf="fileData.TypeName=='Folder'"></i>
                        <i class="fas fa-file fa-3x" *ngIf="fileData.TypeName=='Document'"></i>
                    </div>
                    <div>
                        {{fileData.Title}}
                    </div>
                </div>
                <div class="border-bottom py-2">
                    <div class="d-flex mb-2 justify-content-between align-items-center">
                        <strong >
                            Tags
                        </strong>
                        <div class="btn btn-secondary" (click)="editTags()">
                            {{state ? 'Edit' : 'Cancel'}}
                        </div>
                    </div>

         
              
            <div *ngIf="state">
                <span class="border btn-light btn mr-2 mb-1" *ngFor="let tag of tagList">
                    #{{tag.Text}}
                </span>
            </div>
            <div *ngIf="!state">
                <mat-form-field class="example-chip-list mt-3" style="width: 100% !important;" >
                    <mat-label>Select tags</mat-label>
                    <mat-chip-list #chipList aria-label="tag selection">
                      <mat-chip
                        *ngFor="let tag of tags"
                        [selectable]="selectable"
                        [removable]="removable"
                        (removed)="remove(tag)">
                        {{tag.Text}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>
                      <input
                        placeholder="add tag..."
                        #tagInput
                        [formControl]="tagsCtrl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                      <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                        {{tag.Text}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div>
          

            <div *ngIf="!state">
                <div class="btn btn-secondary" (click)="saveTags()">
                    Save
                </div>
            </div>
          
               
                </div>

            </div>
            <!-- <div class="tab-pane fade" id="Permissions" role="tabpanel" aria-labelledby="profile-tab">Permissions</div> -->
            <div class="tab-pane fade" id="Archive" role="tabpanel" aria-labelledby="contact-tab">Archive</div>
            <div class="tab-pane fade" id="WF" role="tabpanel" aria-labelledby="contact-tab">Work Flow</div>
          </div>
      
    </div>
    <mat-dialog-actions align="end">
        <button mat-button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
    </mat-dialog-actions>

</div>