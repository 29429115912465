import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-archive',
  templateUrl: './admin-Archive.component.html',
  styleUrls:['./admin-Archive.component.css']
})
export class AdminArchiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
