<!-- <div  class="d-flex justify-content-center py-2">
        <div class="d-flex ">
            <div class="btn btn-light mr-2 border">
                Create Workflow
            </div>
            <div class="btn btn-light border">
                View Workflows
            </div>
        </div>
    </div> -->
    <div id="leftcard" style="padding-top: 0px;">

      <div class="btn bg-primary text-white mt-3" (click)="goBack()">
          <i class="fas fa-chevron-left mr-2"></i> Back
      </div>
  
      <h3 class="p-3 display-4" style="margin-top: 20px;">Configure Workflow</h3>
  
      <div id="subnav">
          <div id="loggers" class="navactive ">Steps</div>
      </div>
  
      <div id="blocklist">
          <div class="blockelem create-flowy noselect"><input type="hidden" name="blockelemtype" class="blockelemtype"
                  value="1">
              <div class="grabme"><img src="../../../../../assets/img/grabme.svg"></div>
              <div class="blockin">
                  <div class="blockico"><span></span><img src="../../../../../assets/img/eye.svg"></div>
                  <div class="blocktext">
                      <p class="blocktitle">Start </p>
                      <p class="blockdesc">Starts the process of approval</p>
                  </div>
              </div>
          </div>
          <div class="blockelem create-flowy noselect"><input type="hidden" name="blockelemtype" class="blockelemtype"
                  value="2">
              <div class="grabme"><img src="../../../../../assets/img/grabme.svg"></div>
              <div class="blockin">
                  <div class="blockico"><span></span><img src="../../../../../assets/img/action.svg"></div>
                  <div class="blocktext">
                      <p class="blocktitle">Approve</p>
                      <p class="blockdesc">Assign user to approve form</p>
                  </div>
              </div>
          </div>
  
          <div class="blockelem create-flowy noselect"><input type="hidden" name="blockelemtype" class="blockelemtype"
                  value="3">
              <div class="grabme"><img src="../../../../../assets/img/grabme.svg"></div>
              <div class="blockin">
                  <div class="blockico"><span></span><img src="../../../../../assets/img/time.svg"></div>
                  <div class="blocktext">
                      <p class="blocktitle">End</p>
                      <p class="blockdesc">End the process of approval</p>
                  </div>
              </div>
          </div>
  
          <div  style="border-bottom: 1px solid #E8E8EF;" class="mt-2 ">
  
          </div>
          <div class="mt-2">
              <div class="form-group mr-3">
                  <label for="workflowName">Name</label>
                  <input type="text" class="form-control" id="workflowName">
                  <mat-error id = "workflow-name-error" style="display: none;">Workflow Name is required...</mat-error>
              </div>
  
              <div class="form-group  mr-3">
                  <label for="workflowDescription">Description</label>
                  <textarea class="form-control" id="workflowDescription" rows="3"></textarea>
                  <mat-error id = "workflow-dsc-error" style="display: none;">Workflow Description is required...</mat-error>
              </div>
          </div>
      </div>
      <div id="saveblock" class="btn-primary text-white" style="bottom: 170px !important;" (click)="saveWorkflow()">Save
          Workflow</div>
      <div id="removeblock" class="btn-dark text-white">Delete configuration</div>
      <!--<button type="button" class="btn-dark text-white delete-config" data-toggle="modal" data-target="#exampleModal">Delete configuration</button>-->
  </div>
  
  <input type="hidden" id="formID" value="">
  <input type="hidden" id="elementID" value="">
  <input type="hidden" id="userList" value="">
  <input type="hidden" id="formsList" value="">
  
  <div id="propwrap">
      <div id="properties">
          <div id="close" style="margin-top: 20px !important;">
              <img src="../../../../../assets/img/close.svg">
          </div>
          <p id="" class="p-3 display-4" style="margin-top: 20px;">Properties</p>
          <div id="propswitch">
              <div id="loggers" style="border-bottom: 4px #217CE8 solid;">Data</div>
  
          </div>
          <div id="proplist">
  
  
          </div>
          <div class="btn-primary btn text-white btn-block" id="saveConfiguration">Save</div>
  
  
      </div>
  
  
  </div>
  
  <spinner *ngIf="spinner"></spinner>
  
  
  <div id="canvas">
  </div>
  
  
  
  <!-- Modal -->
  <div class="modal fade" id="delete-config-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <h1 mat-dialog-title class="text-center"><i class="fas fa-3x fa-exclamation-triangle text-warning"></i> </h1>
              <h2 class="text-center modal-title" id="exampleModalLabel">Are you sure ?</h2>
              <h4 class="text-center display-4 py-4" style="font-size: 1.2rem !important;">You are about to delete workflow configuration! </h4>
          </div>
          <div class="modal-footer">
           
            <button type="button" class="btn btn-primary" mat-button mat-flat-button color="primary" data-dismiss="modal" id = "delete-config">Yes! procceed</button>
            <button type="button" class="btn btn-secondary" mat-button mat-flat-button color="warn" data-dismiss="modal" id = "cancel-delete" >Cancel</button>
          </div>
        </div>
      </div>
    </div>
    