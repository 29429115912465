<h3 class="p-3">Form Completed</h3>
<div class="card p-3 m-3 shadow-sm">
    <spinner *ngIf="spinner"></spinner> 
    <div class="card-body ">

        <div class="d-flex justify-content-between align-items-center"></div>
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="FormNr">
                <th mat-header-cell *matHeaderCellDef> Form Nr </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <div>
                        {{i+1}}
                    </div>
            </ng-container>

            <ng-container matColumnDef="CompletedDate">
                <th mat-header-cell *matHeaderCellDef> Completed Date </th>
                <td mat-cell *matCellDef="let element">
                    {{element.createdDate | date}}
                </td>
            </ng-container>

            <ng-container matColumnDef="ViewCompletedForm">
                <th mat-header-cell *matHeaderCellDef> View Completed Form </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button style="color: #386CB9;" title="view" (click)="OnPreviewClick(element)">
                        <mat-icon>preview</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[5,10,50]"></mat-paginator>
    </div>
</div>