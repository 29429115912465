<div *blockUI="'delete-file'">
    <h1 mat-dialog-title *ngIf="fileData.TypeName=='Document'" style="font-weight: 350;font-size: 30px;">Delete file ? </h1>
    <h1 mat-dialog-title *ngIf="fileData.TypeName=='Folder'" style="font-weight: 350;font-size: 30px;">Delete Folder ? </h1>
    <div mat-dialog-content>
        <div class="text-center">
            <div class="mb-2">
                <i class="fas fa-folder fa-3x text-warning" *ngIf="fileData.TypeName=='Folder'"></i>
                <i class="fas fa-file fa-3x" *ngIf="fileData.TypeName=='Document'"></i>
            </div>
            <div>
                {{fileData.Title}}
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end" class="mt-2">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button mat-flat-button color="warn" (click)="onDeleteFile()">Yes delete</button>
    </mat-dialog-actions>
</div>