<div >
    <h1 mat-dialog-title class="text-center"><i class="fas fa-3x fa-exclamation-triangle text-warning"></i> </h1>
    <h2 class="text-center">Are you sure ?</h2>
    <h4  class="text-center display-4 py-4" style="font-size: 1.2rem;">You are about to add <strong><u>{{fileData.Role.RoleName}}</u></strong> role to user <strong>{{fileData.User.Name}}</strong></h4>


    <mat-dialog-actions align="center" class="mt-2">

        <button mat-button mat-flat-button color="primary"  (click)="addRole()">Add role</button>
        <button mat-button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
    </mat-dialog-actions>
    
</div>