<div *blockUI="'add-workflowform'">
  <div *ngIf="step == stateEnum.Intial_Step">
    <h1 mat-dialog-title style="font-weight: 350; font-size: 30px">
      Create Workflow Form
    </h1>
    <form class="example-form">
      <div mat-dialog-content>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field
              class="example-full-width"
              style="display: block"
              appearance="fill"
            >
              <mat-label>Name</mat-label>
              <input matInput type="text" [formControl]="name" />
              <mat-error *ngIf="name.invalid"
                >Please enter workflow form name</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <mat-form-field appearance="fill" style="display: block">
              <mat-label>Choose workflow</mat-label>
              <mat-select [formControl]="workflowData">
                <mat-option
                  *ngFor="let workflow of workflows"
                  [value]="workflow.id"
                  >{{ workflow.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="fill" style="display: block">
              <mat-label>Choose Form</mat-label>
              <mat-select [formControl]="formListData">
                <mat-option
                  *ngFor="let form of formList"
                  [value]="form.formId"
                  >{{ form.formName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-dialog-actions align="end" class="mt-2">
        <button mat-stroked-button color="warn" (click)="onNoClick()">
          Close
        </button>
        <button
          mat-button
          mat-flat-button
          color="primary"
          type="submit"
          (click)="onSubmit()"
          [disabled]="!name.valid || !workflowData.valid || !formListData.valid"
        >
          Submit
        </button>
      </mat-dialog-actions>
    </form>
  </div>

  <div *ngIf="step == stateEnum.Processing_Step">
    <h1 mat-dialog-title style="font-weight: 350; font-size: 30px">
      Processing
    </h1>
    <spinner_small></spinner_small>
  </div>

  <div *ngIf="step == stateEnum.Success_step">
    <div class="d-flex justify-content-center flex-column align-items-center">
      <mat-icon
        mat-list-icon
        class="text-success"
        style="height: auto; width: auto; font-size: 65px"
        >check_circle</mat-icon
      >
      <h5 class="mt-2">
        <strong>{{ name.value }}</strong>
      </h5>
      <h1 style="font-weight: 350; font-size: 30px">Created successfully</h1>
    </div>

    <mat-dialog-actions align="end" class="mt-2">
      <button mat-button mat-flat-button color="primary" (click)="onNoClick()">
        Close
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngIf="step == stateEnum.Error_step">
    <div class="d-flex justify-content-center flex-column align-items-center">
      <mat-icon
        mat-list-icon
        class="text-danger"
        style="height: auto; width: auto; font-size: 65px"
        >error</mat-icon
      >
      <h5 class="mt-2">
        <strong>{{ name.value }}</strong>
      </h5>
      <h1 style="font-weight: 350; font-size: 30px">An error has occurred</h1>
      <h4 style="font-weight: 350; font-size: 20px">Please try again!</h4>
    </div>

    <mat-dialog-actions align="end" class="mt-2">
      <button mat-button mat-flat-button color="primary" (click)="onNoClick()">
        Close
      </button>
    </mat-dialog-actions>
  </div>
</div>
