<div class="no-freeze-spinner">
    <div id="no-freeze-spinner">
        <div>
            <i class="fas fa-folder"></i>
            <i class="material-icons">
                description
            </i>
            <div>
            </div>
        </div>
    </div>
</div>