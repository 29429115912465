<h1 class="display-4 p-3">My Profile</h1>

<spinner *ngIf="myProfile==null" ></spinner>
<div class="container animate" *ngIf="myProfile!=null" >
    <div class="mb-3 text-center" *blockUI="'update-user'">
        <a><img src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png" style="border-radius: 50%;"
                class="avatar img-circle img-thumbnail" alt="avatar"></a>
        <div>{{myProfile.Name}}</div>
    </div>
    <div class="row animate" >
        <div class="col-md-3">
            <ul class="list-group mb-3">
                <li class="list-group-item  bg-light ">Email </li>
                <li class="list-group-item text-left"><span
                        ><strong style="font-size: 14px;">{{myProfile.Email}}</strong></span></li>
            </ul>
            <ul class="list-group mb-3">
                <li class="list-group-item  bg-primary text-white">Activity </li>
                <li class="list-group-item text-"><span><strong>Shares</strong></span> 125</li>
                <li class="list-group-item text-left"><span><strong>uploads</strong></span> 13</li>
                <li class="list-group-item text-left"><span><strong>Files removed</strong></span> 37</li>
            </ul>
            <ul class="list-group">
            <a [href]="delveLink" target="_blank" class="list-group-item  bg-light "> <img src="../../../../assets/img/delve.png" style=" width: 40px;"
            alt="avatar"> </a>
            </ul>
        </div>
        <div class="col-md-9 card shadow-sm">
            <div class="card-body p-4 ">
                <form class="example-form" [formGroup]="updateUserForm" (ngSubmit)="updateProfile()">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="example-full-width" style="display: block;">
                                <mat-label>Email </mat-label>
                                <input matInput formControlName='email'>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width" style="display: block;">
                                <mat-label>First name</mat-label>
                                <input matInput formControlName='firstname'>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
    
                            <mat-form-field class="example-full-width" style="display: block;">
                                <mat-label>Last Name </mat-label>
                                <input matInput formControlName='lastname'>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="example-full-width" style="display: block;">
                                <mat-label>Address</mat-label>
                                <input matInput formControlName='address' >
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width" style="display: block;">
                                <mat-label>Telephone</mat-label>
                                <input matInput formControlName='telephone'>
                            </mat-form-field>
                        </div>
                   
                       
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width" style="display: block;">
                                <mat-label>Job position</mat-label>
                                <input matInput  formControlName='position'>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="example-full-width" style="display: block;">
                                <mat-label>About Me</mat-label>
                                <textarea matInput rows="5" formControlName='about' ></textarea>
                            </mat-form-field>
                        </div>
                    </div>
    
                    <button type="submit" class="btn bg-primary text-white">Update</button>
    
                </form>
            </div>
       
        </div>
    </div>
</div>