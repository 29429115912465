import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { DialogData } from 'src/app/components/shared/interfaces/folder';
import { createUserDTO, EditUser, UserData } from 'src/app/components/shared/interfaces/user';
import { DepartamentService } from 'src/app/services/Departament/departament.service';
import { UserService } from 'src/app/services/User/user.service';

@Component({
    selector: 'rename-folder-dialog',
    templateUrl: './edit-user-dialog.component.html',
})

export class EditUserDialog implements OnInit {
    public departaments;
    public hide: boolean = true;
    @BlockUI('edit-user') blockUI: NgBlockUI;

    
    public email = new FormControl('');
    public username = new FormControl('');
    public firstname = new FormControl('');
    public lastname = new FormControl('');
    public aboutme = new FormControl('');
    public address = new FormControl('');
    public position = new FormControl('');
    public telephone = new FormControl('');
    public departament = new FormControl('', [Validators.required]);

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data, private _snackBar: MatSnackBar, private userService: UserService,private departamentService:DepartamentService) {

        this.email = new FormControl(data.Email, [
            Validators.required,
            Validators.email,
        ]);
        this.username = new FormControl(data.Name, [
            Validators.required
        ]);
        this.firstname = new FormControl(data.Firstname, [
            Validators.required
        ]);
        this.lastname = new FormControl(data.Lastname, [
            Validators.required
        ]);
        this.aboutme = new FormControl(data.AboutMe, [
            Validators.required
        ]);
        this.address = new FormControl(data.Address, [
            Validators.required
        ]);
        this.position = new FormControl(data.Position, [
            Validators.required
        ]);
        this.telephone = new FormControl(data.Telephone, [
            Validators.required
        ]);
        this.departament = new FormControl(data.DepartmentID, [
            Validators.required
        ]);

    }

    public ngOnInit(): void {
        this.departamentService.getDepartaments().subscribe(resp => {
            this.departaments = resp.body;
        })
    }

    public onNoClick(): void {
        this.dialogRef.close('close');
        this.openSnackBar('Action cancelled', 'bg-primary');
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    public onEditSubmit() {

        this.blockUI.start('Editing user...');
        if (this.email.valid && this.username.valid ) {

            let userDTO:EditUser={
          
                UserData:{
                    UserID:this.data.UserID,
                    Name:this.username.value,
                    Email:this.email.value,
                    IsAdministrator:this.data.IsAdministrator,
                    IsActive:this.data.IsActive,
                    Firstname:this.firstname.value,
                    Lastname:this.lastname.value,
                    Position:this.position.value,
                    Telephone:this.telephone.value,
                    Address:this.address.value,
                    AboutMe:this.aboutme.value,
                    DepartmentID:this.departament.value

                }
            }


            
            this.userService.updateUser(userDTO).subscribe(response=>{
                this.blockUI.stop();
                this.dialogRef.close('submited');
                this.openSnackBar('Updating user successfully', 'bg-success');
            },err=>{
                this.dialogRef.close('close');
                this.openSnackBar('Updating user error', 'bg-danger');
                this.blockUI.stop();
               
            })

        }

        
    }




}