import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ChangePasswordDTO, changeStatusDTO, createUserDTO } from 'src/app/components/shared/interfaces/user';
import { InterceptorSkipHeader } from '../Auth/token.interceptor';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  app_url:string = environment.app_Url;

  public userNotificaitonsList;
  public showNotification:boolean=false;
  constructor(private http:HttpClient) { }

  getUserInfo():Observable<HttpResponse<any>> {
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    return this.http.get(url +'Api/UserInfo' ,{ headers: headers_object,observe: "response" });

  }

  getListOfUsers():Observable<HttpResponse<any>> {

    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.get(url +'api/UserList/Get' ,{ headers: headers_object,observe: "response" });

  }
  
  getListOfUsersWithoutRole():Observable<HttpResponse<any>> {

    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.get(url +'api/UserList/GetUsersWithoutRole' ,{ headers: headers_object,observe: "response" });

  }


  createUser(userDTO:createUserDTO):Observable<HttpResponse<any>>{

    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url +'api/User/Add' ,userDTO,{ headers: headers_object,observe:"response"});

  }

  AddUserRole(userID,roleID):Observable<HttpResponse<any>>{

    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url +`api/User/AddUserRole?userID=${userID}&roleID=${roleID}`,{},{ headers: headers_object,observe:"response"});

  }
  updateUser(userDTO:createUserDTO):Observable<HttpResponse<any>>{
    
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.put(url +'api/User/Edit' ,userDTO,{ headers: headers_object,observe:"response"});
  }

  getRolesForUser():Observable<HttpResponse<any>>{
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
    });
      
    return this.http.get(url +'api/role' ,{ headers: headers_object,observe: "response" });
  }

  deleteUser(changeStatusDTO:changeStatusDTO):Observable<HttpResponse<any>>{
    
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.put(url +'api/User/ChangeStatus' ,changeStatusDTO,{ headers: headers_object,observe:"response"});
  }

  changePassword(ChangePassword:ChangePasswordDTO):Observable<HttpResponse<any>>{
    
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url +'api/ChangePassword' ,ChangePassword,{ headers: headers_object,observe:"response"});
  }

  forgotPassword(userEmail:string):Observable<any>{
    
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url +`api/forgotPassword?email=${userEmail}`,{ headers: headers_object,observe:"response"});
  }

  resetPassword(ChangePassword:ChangePasswordDTO,uniquecode:string){

    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.post(url +`api/ChangePassword/${uniquecode}/Forgotten` ,ChangePassword,{ headers: headers_object,observe:"response"});

  }

  getO365Users(): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

    const url = `https://graph.microsoft.com/beta/users?top=500`;
    return this.http.get(url ,{ headers: headers,observe: "response" });
    
  }
  syncUsersToDMS(userRequest: any): Observable<any> {
    
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
    });

      return this.http.post(this.app_url +`api/User/AddUsersInBulk` ,userRequest,{ headers: headers_object,observe:"response"});
      
  }

  getUserNotifications(userid):Observable<HttpResponse<any>> {

    const url = environment.workflow_Url;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.get(url +'/api/WorkflowForm/notification/'+userid ,{ headers: headers_object,observe: "response" });

  }
   updateNotificationStatus(notificationID:number):Observable<HttpResponse<any>>{
    
    const url = environment.workflow_Url;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      
    return this.http.put(url +'/api/WorkflowForm/notification/state/'+notificationID ,null,{ headers: headers_object,observe:"response"});
  }


  getAllActivites():Observable<HttpResponse<any>>{
    const url = this.app_url;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
    });
      
    return this.http.get(url +'api/UserActivity/GetAllActivities' ,{ headers: headers_object,observe: "response" });
  }





}
