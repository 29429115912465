import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { EditDepartment } from 'src/app/components/shared/interfaces/departament';
import { DepartamentService } from 'src/app/services/Departament/departament.service';


@Component({
    selector: 'edit-departament-dialog',
    templateUrl: './edit-departament.component.html',
})

export class EditDepartamentDialog implements OnInit {
    public hide: boolean = true;
    @BlockUI('edit-departament') blockUI: NgBlockUI;

    
    public name = new FormControl('');
    public logo = new FormControl('');
    public description = new FormControl('');
    public address = new FormControl('');


    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data, private _snackBar: MatSnackBar, private departamentService:DepartamentService) {


        this.name = new FormControl(data.DepartmentName, [
            Validators.required
        ]);
        this.description = new FormControl(data.Description, [
            Validators.required
        ]);
        this.logo = new FormControl(data.Logo, [
            Validators.required
        ]);
        this.address = new FormControl(data.Address, [
            Validators.required
        ]);

    }

    public ngOnInit(): void {

    }

    public onNoClick(): void {
        this.dialogRef.close('close');
        this.openSnackBar('Action cancelled', 'bg-primary');
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    public onEditSubmit() {

        this.blockUI.start('Editing departament...');
 
            let departament:EditDepartment={
                Description:this.description.value,
                Logo:this.logo.value,
                DepartmentName:this.name.value,
                DepartmentID:this.data.DepartmentID,
                Address:this.address.value,
            }

            this.departamentService.editDepartament(departament).subscribe(response=>{
                this.blockUI.stop();
                this.dialogRef.close('submited');
                this.openSnackBar('Updating departament successfully', 'bg-success');
            },err=>{
                this.dialogRef.close('close');
                this.openSnackBar('Updating departament error', 'bg-danger');
                this.blockUI.stop();
               
            })

        
    }




}