import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  app_url:string = environment.app_Url;

  constructor(private http:HttpClient) { }

  login(email: string,userID:string):Observable<HttpResponse<any>> {
    const url = this.app_url ;

    const data = {
      AdalToken:userID,
      Email:email
   }
    const reqHeader = new HttpHeaders({  'No-Auth': 'True' });

    return this.http.post(url + 'api/token', data, { headers: reqHeader,observe: "response" });
  }


}
