<spinner *ngIf="spinner"></spinner>
<div id="leftcard" style="padding-top: 0px;" >

    <div class="btn bg-primary text-white mt-3" (click)="goBack()">
        <i class="fas fa-chevron-left mr-2"></i>  Back
    </div>
    
    <h3 class="p-3 display-4" style="margin-top: 20px;">Workflow Details</h3>

    <div id="subnav">
      
    </div>

    <div class="p-3">
        <div class="mb-2">
            <strong>Workflow Name :</strong> {{currentWorkflow.name}}
        </div>
        <div>
            <strong>Created Date :</strong> {{currentWorkflow.createdDate | date}}
        </div>

    </div>

</div>
<div id="canvas">
</div>

<div id="canvas2">

</div>