
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetWorkflowDTO, WorkflowDTO, workFlowListDTO } from 'src/app/components/shared/interfaces/workflow';
import { WorkflowService } from 'src/app/services/Workflow/workflow.service';
declare var flowy: any;
@Component({
    selector: 'display-workflow',
    templateUrl: 'displayworkflow.component.html',
    styleUrls: ['displayworkflow.component.css']
})

export class DisplayWorkflowComponent implements OnInit,OnDestroy {
    constructor(private route: ActivatedRoute, private workflowService: WorkflowService, private router: Router) { }
    ngOnDestroy(): void {
        window.location.reload();
    }

    public spinner: boolean = true;

    public currentWorkflow:GetWorkflowDTO=null;

    ngOnInit() {

        const id = this.route.snapshot.paramMap.get('id');
        this.workflowService.getWorkflowByID(id).subscribe(resp => {
            this.spinner = false;
            this.initializeWorkflow();
            this.currentWorkflow = resp.body;
            flowy.import(resp.body.content);
        })

    }



    public goBack(): void {
        this.router.navigateByUrl('/admin/workflow');
    }


    public initializeWorkflow() {
        var tempblock;
        //document.getElementById("blocklist").innerHTML = '';
        flowy(document.getElementById("canvas"));


    }
}