import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { changeStatusDTO } from 'src/app/components/shared/interfaces/user';
import { UserService } from 'src/app/services/User/user.service';


@Component({
    selector: 'add-permission-dialog',
    templateUrl: './delete-user-dialog.component.html',
})

export class DeleteUserDialog implements OnInit {

    @BlockUI('permission-file') blockUI: NgBlockUI;
    public fileData;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data, private _snackBar: MatSnackBar, private userService: UserService) { }

    public NameFormControl = new FormControl('', [
        Validators.required
    ]);

    ngOnInit() {
        this.fileData = this.data;

    }
    public onMakeUserActive(): void {
        this.userStatusChange(true,'addedd');
    }
    public onMakeUserInActive() {
        this.userStatusChange(false,'removed');
    }

    private userStatusChange(status: boolean,text:string): void {
        this.blockUI.start();
        let changeStatusDTO: changeStatusDTO = {
            NewStatus: status,
            UserData: this.fileData
        }
        this.userService.deleteUser(changeStatusDTO).subscribe(resp => {
            this.blockUI.stop();
            this.openSnackBar(`User ${text} successfully`, 'bg-success');
            this.dialogRef.close('submited');

        }, err => {
            this.blockUI.stop();
            this.openSnackBar(`An error acurred!`, 'bg-danger');
            this.dialogRef.close('close');
        });
    }

   

    public onNoClick(): void {

        this.dialogRef.close();
        this.openSnackBar(`Action cancelled`, 'bg-primary');

    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}