<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <mat-list-item class="arrow-menu navbar-dark bg-dark">
    <button class="header__title-button" mat-mini-fab (click)="openMenu()">
      <mat-icon *ngIf="isMenuOpened" mat-list-icon class="chevron-button"
        >more_vert</mat-icon
      >
      <mat-icon *ngIf="!isMenuOpened" mat-list-icon class="chevron-button"
        >menu</mat-icon
      >
    </button>
  </mat-list-item>

  <a class="navbar-brand" href="#"
    ><i class="fas fa-folder mr-2 text-warning"></i> 3i-DMS</a
  >

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-lg-0" *ngIf="isAuthenticated">
      <span class="mr-2 text-white">{{ name }}</span>
      <!-- <button class="btn btn-light my-2 my-sm-0" (click)="logOut()"> <i class="fas fa-sign-out-alt "></i></button> -->

      <div class="nav-item dropdown" style="cursor: pointer">
        <a
          class="nav-link text-white"
          id="navbarDropdownMenuLink-332"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="position: relative"
        >
          <i class="far fa-bell text-white" style="font-size: 21px"></i>
          <div class="notification-number">{{ numberOfNotificaitons }}</div>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right dropdown-default shadow"
          style="top: 110%; padding: 0px"
          aria-labelledby="navbarDropdownMenuLink-332"
        >
          <div
            class="text-center py-3 alert alert-success"
            style="position: relative"
          >
            User notifications

            <div style="position: absolute" class="triangle-up"></div>
          </div>
          <div style="height: 350px; overflow: auto">
            <div *ngIf="notificationList.length > 0">
              <div
                *ngFor="let notification of notificationList"
                class="px-2 mb-1"
              >
                <a
                  class="dropdown-item alert"
                  [ngClass]="
                    notification.isReaded == true
                      ? 'alert-info'
                      : 'alert-danger'
                  "
                  (click)="handleNotification(notification)"
                >
                  <span class="d-flex">
                    <span class="align-self-center mr-2">
                      <i class="far fa-bell mr-2" style="font-size: 21px"></i>
                    </span>
                    <span>
                      {{ notification.notes }} <br />
                      <small>{{ notification.dateTime | date }}</small>
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-item dropdown" style="cursor: pointer">
        <a
          class="nav-link dropdown-toggle text-white"
          id="navbarDropdownMenuLink-333"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-user" style="font-size: 21px"></i>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right dropdown-default"
          style="top: 110%"
          aria-labelledby="navbarDropdownMenuLink-333"
        >
          <a class="dropdown-item" (click)="myProfile()">My profile</a>
          <a
            class="dropdown-item"
            href="https://account.activedirectory.windowsazure.com/ChangePassword.aspx?BrandContextID=O365&ruO365="
            target="_blank"
            >Change password</a
          >
          <a class="dropdown-item" (click)="logOut()" href="#"
            >Logout <i class="fas fa-sign-out-alt ml-2"></i
          ></a>
        </div>
      </div>
    </form>
  </div>
</nav>
