import { Component, OnInit, EventEmitter, Output, NgZone, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { AuthGuardService } from 'src/app/services/Auth/auth-guard.service';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { UserService } from 'src/app/services/User/user.service';
import decode from 'jwt-decode';
import { NotificationDTO } from '../interfaces/workflow';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
})
export class NavBarComponent implements OnInit , OnChanges {
    public totalNotificationNumber: number = 0;
    public isAuthenticated: boolean;
    public name: string;
    public status: boolean;
    public userNotifications: Array<NotificationDTO> = [];
    public isMenuOpened: boolean = true;
    public userID;
    @Output() isShowSidebar = new EventEmitter<boolean>();
    @Input() notificationList: Array<NotificationDTO>;
    @Input() numberOfNotificaitons: number;
    @Output() changeUnreadNotificationNumber = new EventEmitter<number>();

    constructor(private auth: AuthGuardService,
        private authservice: AuthService,
        private router: Router,
        private adlServ: AdalService
        , private userService: UserService,
        private ngZone: NgZone,
    ) {
        auth.checkAuthentication.subscribe(state => this.changeAuthentication(state));
        auth.name.subscribe(state => this.changeName(state));
        auth.isAdmin.subscribe(state => this.changeRoleStatus(state));
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngDoCheck(){

    }

    ngOnInit(): void {
        const token = localStorage.getItem('AuthToken');
        this.isAuthenticated = this.authservice.isAuthenticated();
        
    }

    private changeAuthentication(state: boolean): void {
        this.isAuthenticated = state;
    }

    private changeName(state: string) {
        this.name = state;
    }

    private changeRoleStatus(state: boolean): void {
        this.status = state;
    }

    public logOut(): void {
        localStorage.clear();
        this.isAuthenticated = false;
        this.adlServ.logOut();
        this.router.navigateByUrl('/login');
    }

    public myProfile(): any {
        if(this.checkAdminRolePermission()){
            return this.router.navigateByUrl('/admin/profile');
        }
        return this.router.navigateByUrl('/user/profile');
    }

    public checkAdminRolePermission():boolean{
        if(this.status){
            return true;
        }
        return false;
    }

    public goToNotification(notification): any {

        if(this.checkAdminRolePermission()){
            return   this.ngZone.run(() => this.router.navigateByUrl('/admin/workflow-form-instances/view/' + notification.workflowFormId));
        }
        return   this.ngZone.run(() => this.router.navigateByUrl('/user/workflow-form-instances/view/' + notification.workflowFormId));
    }

    public handleNotification(notification:NotificationDTO){


        if(notification.isReaded){
            return this.goToNotification(notification);
        }
        return this.updateNotificationStatus(notification);

    }

    public updateNotificationStatus(notification:NotificationDTO){

        this.userService.updateNotificationStatus(notification.id).subscribe(response=>{


            this.notificationList.forEach(notificationElement=>{
                if(notificationElement.id == notification.id){
                    notificationElement.isReaded=true;
                }
            })

            this.numberOfNotificaitons=this.numberOfNotificaitons-1;

            this.changeUnreadNotificationNumber.emit(this.numberOfNotificaitons);

            this.goToNotification(notification);

        },error=>{
        });
    }





    public changePassword(): void {
        if (this.status) {
            this.router.navigateByUrl('/admin/passwordreset');
        } else {
            this.router.navigateByUrl('/user/passwordreset');
        }
    }

    public openMenu(): void {
        this.isMenuOpened = !this.isMenuOpened;
        this.isShowSidebar.emit(this.isMenuOpened);
    }



}
