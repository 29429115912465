<div >

    <h1 mat-dialog-title style="font-weight: 350;font-size: 30px;">Send password to email ! </h1>
    <div mat-dialog-content class="my-3">
    
        <mat-form-field style="width: 100%;" class="my-3">
            <mat-label>Email</mat-label>
            <input matInput 
                   placeholder="Ex. pat@example.com" type="email" [formControl]="emailFormControl">
        </mat-form-field>
          
    </div>
    <mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button mat-flat-button color="primary" [disabled]="!this.emailFormControl.valid" (click)="sendLink()">Send Password</button>
    </mat-dialog-actions>

</div>