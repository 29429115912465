
<h3 class=" p-3 display-4">Workflow Forms List</h3>


<div *ngIf="spinner" class="d-flex justify-content-center align-items-center" style="height: 600px; width: 100%;">
    <spinner_small ></spinner_small>
</div>

<div *ngIf="!spinner" class="animate">

    <div class="card p-3 m-3 shadow-sm">

        <div class="card-body ">

            <div class="">
                <div class="d-flex justify-content-between align-items-center">

                    <div>
                        <mat-form-field style="width: 300px;" class="mr-3">
                            <mat-label>Filter by workflow form name</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                        </mat-form-field>
                        <mat-form-field class="ml-3">

                            <mat-label>Filter by status</mat-label>
                            <mat-select (selectionChange)="filterByStatus($event)">
                                <mat-option value="All">All</mat-option>
                                <mat-option value="NEW">NEW</mat-option>
                                <mat-option value="PROGRESS">PROGRESS</mat-option>
                                <mat-option value="COMPLETED">COMPLETED</mat-option>

                            </mat-select>

                        </mat-form-field>
                    </div>
                    <div>
                        <button class="btn bg-primary text-white" *ngIf="checkUserAdministrationRole()" (click)="addWorkflow()"><i class="fas fa-plus mr-2"></i> Create Workflow Form</button>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>


                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
                        <td mat-cell *matCellDef="let element" (click)="editWorkflow(element)" style="cursor: pointer;">
                       {{element.name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="statusDescription">
                        <th mat-header-cell *matHeaderCellDef > Status Description </th>
                        <td mat-cell *matCellDef="let element">

                            <span *ngIf="element.statusDescription == 'NEW'" class="badge badge-primary p-1"> {{element.statusDescription}}</span>
                            <span *ngIf="element.statusDescription == 'COMPLETED'" class="badge badge-success p-1"> {{element.statusDescription}}</span>
                            <span *ngIf="element.statusDescription == 'PROGRESS'" class="badge badge-warning p-1"> {{element.statusDescription}}</span>
                        </td>
                    </ng-container>



            

                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button style="color:  #386CB9;" (click)="editWorkflow(element)" title="view">
                                <mat-icon>tab</mat-icon>
                            </button>
                            <button *ngIf="checkUserAdministrationRole()" mat-icon-button style="color: #386CB9;" title="Change status"
                                (click)="deleteWorkflow(element)">
                                <mat-icon>delete</mat-icon>
                            </button>

                            
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10,20,50]"></mat-paginator>


            </div>
        </div>
    </div>


</div>