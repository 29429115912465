import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NavigationExtras, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { environment } from 'src/environments/environment';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData } from '../../interfaces/folder';



@Component({
  selector: 'create-folder-dialog',
  templateUrl: './searched-file-dialog.component.html',
  styleUrls: ['./searched-file-dialog.component.css']
})

export class SearchFileDialog implements OnInit {
  @BlockUI('folder-share') blockUI: NgBlockUI;

  private blobToken: string = environment.staticTokenBlob;

  public folderpathTitle: string = '';
  public folderpath: string = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private folderService: FolderService, private router: Router) { }


  ngOnInit() {

  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public previewFile(path, content) {

    this.folderService.getFileNameAfterDecoded(path).subscribe(response => {




      this.folderService.linkToPreview = {
        link: response.body + this.blobToken,
        element: null,
        content:content
      };



      if (content.includes('image')) {
        window.open(response.body + this.blobToken);
      } else {
        this.router.navigateByUrl('/admin/PreviewFile');
        this.dialogRef.close();

      }




    });

  }

  openSnackBar(name: string, className: string) {
    this._snackBar.open(name, `X`, {
      duration: 3000,
      panelClass: [className],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }


}