import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { FormBuilderService } from 'src/app/services/FormBuilder/formBuilder.services';
import { ActivatedRoute } from '@angular/router';
import { formDefinition, formDefinitionPutDTO } from 'src/app/components/shared/interfaces/formInstance';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
    selector: 'edit-form',
    templateUrl: './edit-form.component.html',

})

export class EditForm implements OnInit {
    public form: Object = { components: [] };
    public formJsonSrc: any;
    public spinner: boolean = true;
    public formToEditId: string;
    public formGetObject: formDefinition;
    public formjson: string;

    public name = new FormControl('');
    public description = new FormControl('');
    
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    onChange(event) {

    }


    constructor(private _location: Location,
        private _forminstanceService: FormBuilderService,private route: ActivatedRoute,private _snackBar: MatSnackBar) {

        this.formToEditId = this.route.snapshot.paramMap.get('id');
    }



    public ngOnInit(): void {
        this.getFormData();

    }


    private getFormData() {
        this.spinner = true;
        this._forminstanceService.getFormsCreated(this.formToEditId).subscribe(resp => {
            this.formGetObject = resp.body;
            this.spinner = false;
            this.formJsonSrc = JSON.parse(this.formGetObject.formJsondata);
            this.name = new FormControl(this.formGetObject.formName);
            this.description = new FormControl(this.formGetObject.description);
            this.form = this.formJsonSrc;
        });
    }

    onNoClick() {

        this._location.back();
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


    onEditSubmit() {
        let form: formDefinitionPutDTO = {
            formId:this.formGetObject.formId,
            instanceId:this.formGetObject.instanceId,
            formName:this.name.value,
            description:this.description.value,
            formJsondata: JSON.stringify(this.form),
            parentId:this.formGetObject.parentId,
            isActive:true
        }
        
        this._forminstanceService.editForm(form).subscribe(response=>{
            this.openSnackBar('Updating form successfully', 'bg-success');
            this._location.back();
        },err=>{
            this.openSnackBar('Updating form error', 'bg-danger');
          
        })


    }



}
