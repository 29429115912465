<h3 class="p-3">Shared Forms</h3>
<div class="card p-3 m-3 shadow-sm">
   
    <div class="card-body ">

        <div class="d-flex justify-content-between align-items-center"></div>
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="Form">
                <th mat-header-cell *matHeaderCellDef> Form Nr </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <div>
                        {{i+1}}
                    </div>
            </ng-container>

            <ng-container matColumnDef="FormName">
                <th mat-header-cell *matHeaderCellDef> Form Name </th>
                <td mat-cell *matCellDef="let element">
                    {{element.formName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element">
                    {{element.description}}
                </td>
            </ng-container>

            <ng-container matColumnDef="CreatedDate">
                <th mat-header-cell *matHeaderCellDef> Created Date </th>
                <td mat-cell *matCellDef="let element">
                    {{element.createdDate | date}}
                </td>
            </ng-container>

            <ng-container matColumnDef="NumberOfUsage">
                <th mat-header-cell *matHeaderCellDef>Number of Submits </th>
                <td mat-cell *matCellDef="let element">
                    {{element.NumberOfUsage}}
                </td>
            </ng-container>


            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button style="color:  #386CB9;" (click)="(element)" title="edit">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button style="color: #386CB9;" title="view" (click)="(element)">
                        <mat-icon>preview</mat-icon>
                    </button>
                    <button mat-icon-button style="color: #386CB9;" title="done" (click)="(element)">
                        <mat-icon>done</mat-icon>
                    </button>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[5,10,50]"></mat-paginator>
    </div>
</div>
