import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData, FolderNode } from '../../interfaces/folder';



@Component({
    selector: 'move-file-dialog',
    templateUrl: './move-file-dialog.component.html',
})

export class moveFileDialog implements OnInit {

    public tree_data: FolderNode[] = [];
    public label:string="Select folder";


    @BlockUI('delete-file') blockUI: NgBlockUI;
    public fileData;
    public currentNameFormControl;
    public newNameFormControl = new FormControl('', [
        Validators.required,
    ]);

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private folderService: FolderService) { }

    public NameFormControl = new FormControl('', [
        Validators.required
    ]);
    ngOnInit() {

        this.fileData = this.data;

        this.folderService.getFolderRoot().subscribe(data => {
            this.tree_data = data.body;

        })
    }

    newLabel(event){
       this.label=event;
    }
    onRenameFile() {

    }

    public onNoClick(): void {
        this.dialogRef.close();
        this.openSnackBar('Action cancelled', 'bg-primary');

    }


    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}