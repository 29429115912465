<div class = "background-white1">
<h3 class="p-3" >Edit Form</h3>
<spinner *ngIf="spinner"></spinner>
<form class="example-form">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
          <mat-label> Form Name</mat-label>
          <input matInput type="text" [formControl]="name">
          <mat-error *ngIf="name.invalid">Please enter email address</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
          <mat-label>Description</mat-label>
          <textarea matInput [formControl]="description"  rows="4"></textarea>
          <mat-error *ngIf="description.invalid">About me is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <form-builder [form]="form" (change)="onChange($event)"></form-builder>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="row">
      <div class="col-md-10"></div>
        <div class="col-md-2">
          <mat-dialog-actions align="end" class="mt-2">
            <button mat-button (click)="onNoClick()">Cancel</button>
            <button mat-button mat-flat-button color="primary" (click)="onEditSubmit()"
              [disabled]=" !name.valid || !description.valid">Submit</button>
          </mat-dialog-actions>
        </div>
    </div>
  </div>
</form>
</div>