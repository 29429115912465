import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';
import { DialogData } from 'src/app/components/shared/interfaces/folder';
import { createUserDTO } from 'src/app/components/shared/interfaces/user';
import { DepartamentService } from 'src/app/services/Departament/departament.service';
import { UserService } from 'src/app/services/User/user.service';

@Component({
    selector: 'rename-folder-dialog',
    templateUrl: './add-user-dialog.component.html',
})

export class AddUserDialog implements OnInit {
    public roles;
    public departaments;
    public hide: boolean = true;
    @BlockUI('add-user') blockUI: NgBlockUI;

    public email = new FormControl('', [
        Validators.required,
        Validators.email,
    ]);
    public username = new FormControl('', [
        Validators.required
    ]);
    public password = new FormControl('', [
        Validators.required
    ]);
    public firstname = new FormControl('', [Validators.required]);
    public rolename = new FormControl('', [Validators.required]);
    public lastname = new FormControl('', [Validators.required]);
    public aboutme = new FormControl('', [Validators.required]);
    public address = new FormControl('', [Validators.required]);
    public position = new FormControl('', [Validators.required]);
    public departament = new FormControl('', [Validators.required]);
    public telephone = new FormControl('', [Validators.required]);

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private userService: UserService, private departamentService: DepartamentService) { }

    public ngOnInit(): void {
        this.userService.getRolesForUser().subscribe(resp => {
            this.roles = resp.body;
        });

        this.departamentService.getDepartaments().subscribe(resp => {
            this.departaments = resp.body;
        })

    }

    public onNoClick(): void {
        this.dialogRef.close('close');
        this.openSnackBar('Action cancelled', 'bg-primary');
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    public onSubmit() {
        this.blockUI.start('Creating User...');
        if (this.email.valid && this.username.valid && this.password.valid) {

            let userDTO: createUserDTO = {

                UserData: {
                    Name: this.username.value,
                    Email: this.email.value,
                    AboutMe: this.aboutme.value,
                    Address: this.address.value,
                    Firstname: this.firstname.value,
                    Lastname: this.lastname.value,
                    Position: this.position.value,
                    Telephone: this.telephone.value,
                    DepartmentID:this.departament.value
                },
                Password: this.password.value,
                RoleID: this.rolename.value
            }


            this.userService.createUser(userDTO).subscribe(resp => {
                this.blockUI.stop();
                this.dialogRef.close('submited');
                this.openSnackBar('Creating user successfully', 'bg-success');
            }, err => {
                this.openSnackBar('Creating user error', 'bg-danger');
                this.blockUI.stop();
                this.dialogRef.close('close');
            })
        }
    }

}