<div *blockUI="'add-user'">

    <h1 mat-dialog-title style="font-weight: 350;font-size: 30px;" >Create user </h1>
    <form class="example-form"  >
    <div mat-dialog-content>

         
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" [formControl]="email" placeholder="pat@example.com">
                    <mat-error *ngIf="email.invalid">Please enter email address</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>Username</mat-label>
                    <input matInput [formControl]="username" >
                    <mat-error *ngIf="username.invalid">Username is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">

                  <mat-form-field appearance="fill" style="display: block;" >
                    <mat-label>Choose role</mat-label>
                    <mat-select [formControl]="rolename" >
                      <mat-option  *ngFor="let role of roles" [value]="role.RoleID">{{role.RoleName}}</mat-option>
                    
                    </mat-select>
                  </mat-form-field>
                  
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>First name</mat-label>
                    <input matInput [formControl]="firstname" >
                    <mat-error *ngIf="firstname.invalid">First name is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>Last name</mat-label>
                    <input matInput [formControl]="lastname" >
                    <mat-error *ngIf="lastname.invalid">Last name is required</mat-error>
                  </mat-form-field>
                </div>
                      
                <div class="col-md-6">
                  <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>Address</mat-label>
                    <input matInput [formControl]="address" >
                    <mat-error *ngIf="address.invalid">Address is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>Telephone</mat-label>
                    <input matInput [formControl]="telephone" >
                    <mat-error *ngIf="telephone.invalid">Telephone is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>Position</mat-label>
                    <input matInput [formControl]="position" >
                    <mat-error *ngIf="position.invalid">Position is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">

                  <mat-form-field appearance="fill" style="display: block;" >
                    <mat-label>Choose Departament</mat-label>
                    <mat-select [formControl]="departament" >
                      <mat-option  *ngFor="let dep of departaments" [value]="dep.DepartmentID">{{dep.DepartmentName}}</mat-option>
                    
                    </mat-select>
                  </mat-form-field>
                  
                </div>
                <div class="col-md-12">
                  <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>About</mat-label>
                    <textarea matInput [formControl]="aboutme" rows="4" ></textarea>
                    <mat-error *ngIf="aboutme.invalid">About me is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-form-field appearance="fill" style="display: block;">
                    <mat-label>Enter password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" [formControl]="password" autocomplete="new-password">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
    </div>

    <mat-dialog-actions align="end" class="mt-2">
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button mat-flat-button color="primary" type="submit" (click)="onSubmit()" [disabled]="!email.valid || !username.valid || !password.valid || !firstname.valid || !lastname.valid || !aboutme.valid || !position.valid || !telephone.valid || !address.valid ||!rolename.valid" >Submit</button>
    </mat-dialog-actions>

    
  </form>
</div>