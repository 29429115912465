import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilderService } from 'src/app/services/FormBuilder/formBuilder.services';
import decode from 'jwt-decode';
import { Router } from '@angular/router';
import { PreviewFormDialog } from './preview-form.component';
import { FormDTO } from 'src/app/components/shared/interfaces/formbuilder';

@Component({
  selector: 'my-form',
  templateUrl: './my-form.component.html',
  styleUrls: ['./my-form.component.css'],
})
export class MyFormComponent implements OnInit {
  public dataSource: MatTableDataSource<FormDTO>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public allForms: any;
  public spinner: boolean = true;
  public userLoggedIn: string;
  public forms = new FormControl('', [Validators.required]);
  displayedColumns: string[] = [
    'Form',
    'FormName',
    'Description',
    'CreatedDate',
    'Actions',
  ];

  constructor(
    public dialog: MatDialog,
    private formService: FormBuilderService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getMyForms();
  }

  public checkUserAdministrationRole(): boolean {
    const isAdmin = this.getDecodedToken().IsAdmin;
    console.log(this.getDecodedToken());
    this.userLoggedIn = this.getDecodedToken().Email;

    if (isAdmin == 'True') {
      return true;
    }
    return false;
  }

  private getDecodedToken(): any {
    const token = localStorage.getItem('AuthToken');
    const tokenPayload: any = decode(token);
    return tokenPayload;
  }

  private getMyForms(): void {
    this.spinner = true;

    this.formService.getAllFormsCreated().subscribe((resp) => {
      let allForms;
      if (this.checkUserAdministrationRole()) {
        allForms = resp.body;
      } else {
        allForms = this.allForms.filter(
          (form) => form.createdBy == this.userLoggedIn
        );
      }
      this.spinner = false;
      this.dataSource = new MatTableDataSource<FormDTO>(allForms);
      this.cdr.detectChanges();
      this.ngAfterViewInit();
    });
  }

  public OnEditClick(element: any) {
    if (this.checkUserAdministrationRole()) {
      return this.router.navigateByUrl('/admin/EditForm/' + element.formId);
    }

    return this.router.navigateByUrl('/user/EditForm/' + element.formId);
  }

  public OnPreviewClick(form): void {
    const dialogRef = this.dialog.open(PreviewFormDialog, {
      width: '600px',
      autoFocus: false,
      data: form,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'submited') {
        this.getMyForms();
      } else {
      }
    });
  }

  public OnCompletedForm(element: any): any {
    if (this.checkUserAdministrationRole()) {
      return this.router.navigateByUrl(
        '/admin/CompletedForm/' + element.formId
      );
    }
    return this.router.navigateByUrl('/user/CompletedForm/' + element.formId);
  }

  public addForm(): void {
    this.router.navigateByUrl('admin/MyForm/CreateForm');
  }
}
