export class ServerDetails{
  // 1. the Service name
  public static searchServiceName = '3i-dms-srvc';
  // 2. The Admin Key
  public static searchServiceAdminApiKey = '7846756F76767230983DEC70CF7B8075';
  // 3. The Index Name
  public static searchIndexName = 'azureblob-index';
  // 4. The API Version
  public static apiVersion = '2020-06-30';
  // 5. The Search URLS
  // tslint:disable-next-line: max-line-length
  public static searchUri = `https://3i-dms-srvc.search.windows.net/indexes/${ServerDetails.searchIndexName}/docs?api-version=2020-06-30`;
  // tslint:disable-next-line: max-line-length
 // public static searchUriLucene = `https://${ServerDetails.searchServiceName}.search.windows.net/indexes/${ServerDetails.searchIndexNameLucene}/docs/search?api-version=${ServerDetails.apiVersion}`;
  
}