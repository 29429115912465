import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowService } from 'src/app/services/Workflow/workflow.service';
import decode from 'jwt-decode';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
@Component({
  selector: 'display-workflowForm',
  templateUrl: 'display-workflowForm.component.html',
  styleUrls: ['display-workflowForm.component.css']
})

export class DisplayWorkFlowFormComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  public currentWorkflowForm: any;
  public workflowSteps: any;
  public userID: string = "";
  public spinner: boolean = true;
  public refreshForm;
  public formData;
  public isAdmin: string = 'False';
  public form: Object = { components: [] };
  private workflowFormID;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, private workflowService: WorkflowService, private router: Router, private _snackBar: MatSnackBar,) { }

  ngOnInit() {

    const token = localStorage.getItem('AuthToken');
    if (token != null) {
      const tokenPayload: any = decode(token);
      this.userID = tokenPayload.UserGuidID;
      this.isAdmin = tokenPayload.IsAdmin;
    }

    this.workflowFormID = this.route.snapshot.paramMap.get('id');


    this.route.params.subscribe(routeParams => {
      this.workflowFormID = routeParams.id;
      this.getWorkFlowByFormID(this.workflowFormID);
    });


    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });


  }


  public getWorkFlowByFormID(id): void {
    this.spinner = true;

    this.workflowService.getWorkflowFormByID(id).subscribe(resp => {
      this.spinner = false;

      this.currentWorkflowForm = resp.body.id;

      this.form = JSON.parse(this.currentWorkflowForm.formContentTemplate);
      this.workflowSteps = resp.body.id.workflowFormSteps;

      this.workflowSteps.forEach(element => {
        if (element.formContent != null) {
          this.formData = JSON.parse(element.formContent);
        }
      });

    })
  }

  onChange(event) {

  }
  submitForm(workflowstep) {

    // this.workflowService.submitWorkflowFormStep(workflowstep).subscribe(reps=>{

    // });

  }

  openSnackBar(name: string, className: string) {
    this._snackBar.open(name, `X`, {
      duration: 3000,
      panelClass: [className],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  onSubmit(submission: any, workflowstep) {

    let workflowStepPostDTO = workflowstep;

    workflowStepPostDTO.formContent = JSON.stringify({
      data: submission.data
    });


    if (submission.state == 'submitted') {
      this.workflowService.submitWorkflowFormStep(workflowStepPostDTO).subscribe(reps => {
        this.openSnackBar('Process was submited successfully', 'bg-success');
        this.getWorkFlowByFormID(this.workflowFormID);
      }, err => {
        this.openSnackBar('Error submiting process', 'bg-danger');
      });
    }


  }

  public goBack(): any {
    if (this.checkUserAdministrationRole()) {
      return this.router.navigateByUrl('/admin/workflow-form-instances');
    }
    else this.router.navigateByUrl('/user/workflow-form-instances');
  }

  private checkUserAdministrationRole(): boolean {
    const isAdmin = this.getDecodedToken().IsAdmin;
    if (isAdmin == 'True') {
      return true
    }
    return false;
  }

  private getDecodedToken(): any {
    const token = localStorage.getItem('AuthToken');
    const tokenPayload: any = decode(token);
    return tokenPayload;
  }


}