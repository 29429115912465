
export const environment = {
  production: false,
  app_Url: 'https://tdc-dms.azurewebsites.net/',
  //app_Url:'http://localhost:8041/',
  formBuilder_Url:'https://tdc-bpm.azurewebsites.net/',
  workflow_Url:'https://tdc-wf.azurewebsites.net',
  //app_Url:'http://192.168.1.25:9000/',
  staticTokenBlob:'?sp=rl&st=2020-11-04T08:27:35Z&se=2023-06-05T08:27:00Z&sv=2019-12-12&sr=c&sig=2%2BoVMFD0uvF75ybxixfVHsgkF3D9nsuUhCOXvNOvfvs%3D',
  adalConfig: {
    tenant: 'f88f2ac7-1a00-4a6b-b0c4-b5df12ae1e00', 
    clientId: '440f89b2-d8f5-4665-9ee2-1dbb02ec3475', 
    cacheLocation: 'localStorage', // enable thipaps for IE, as localStorage does not work for localhost.
    endpoints: {
      'https://graph.microsoft.com': 'https://graph.microsoft.com',
      'http://localhost:44308': 'http://localhost:44308',
      'https://localhost:44376': 'https://localhost:44376',
      'http://localhost:8081': 'http://localhost:8081',
      'https://tdc-dms.azurewebsites.net/': 'https://tdc-dms.azurewebsites.net/',
      'https://tdc-bpm.azurewebsites.net/' :'https://tdc-bpm.azurewebsites.net/',
      'https://tdc-wf.azurewebsites.net/': 'https://tdc-wf.azurewebsites.net/'
    },
  }
};

// mcurie@3ilms.onmicrosoft.com