import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { map, startWith } from 'rxjs/operators';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData } from '../../interfaces/folder';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
    selector: 'upload-file-dialog',
    templateUrl: './upload-file-dialog.html',
    styleUrls: ['./upload-file-dialog.css']
})


export class UploadFileDialog implements OnInit {

    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagsCtrl = new FormControl();
    filteredTags: any;
    tags: any = [];
    allTags: any = [];


    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;


    public folderpath: string = '';
    public folderpathTitle: string = '';
    uploadForm: FormGroup;

    @BlockUI('upload-file') blockUI: NgBlockUI;

    title = 'angular-material-file-upload-app';

    @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
    myfilename = 'Select File';

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private folderService: FolderService,
        private formBuilder: FormBuilder) {


        this.folderService.getAllAvailableTags().subscribe(resp => {

            this.allTags = resp.body;

            this.tags.push(resp.body[0]);

            this.filteredTags = this.tagsCtrl.valueChanges.pipe(
                startWith(null),
                map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
        });



    }

    ngOnInit() {
        this.uploadForm = this.formBuilder.group({
            fileUpload: ['']
        });

        this.folderpathTitle = this.folderService.currentFolderPath;

        if (this.folderpathTitle !== null) {
            const arr = this.folderpathTitle.split('/');
            this.folderpathTitle = arr[arr.length - 2];

            for (let i = 1; i <= arr.length - 2; i++) {
                this.folderpath = this.folderpath + arr[i] + '/';
            }

        }
    }

    add(event: MatChipInputEvent): void {


        const input = event.input;
        const value = event.value;

        // Add our tag
        if ((value || '').trim()) {
            const tagDTO = {
                TagID: 0,
                Text: value.trim()
            }
            this.tags.push(tagDTO);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.tagsCtrl.setValue(null);
    }

    remove(tag: string): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {


        const found = this.tags.indexOf(event.option.value);

        if (found == -1) {
            this.tags.push(event.option.value);
            this.tagInput.nativeElement.value = '';
            this.tagsCtrl.setValue(null);
        } else {
            this.openSnackBar('Tag already addedd', 'bg-danger');
        }


    }

    private _filter(value: string): string[] {

        const filterValue = value.toLowerCase();

        return this.allTags.filter(tag => tag.Text.toLowerCase().indexOf(filterValue) === 0);
    }


    public onNoClick(): void {

        this.dialogRef.close();
        this.openSnackBar('Action cancelled', 'bg-primary');

    }

    public onUploadingFile(): void {

        const formData = new FormData();

        Array.from(this.uploadForm.get('fileUpload').value).forEach((file: File, index) => {
            formData.append('file' + index, file);

        });


        var tagss = JSON.stringify(this.tags);

        formData.append('tagList',JSON.stringify(this.tags));




        this.blockUI.start('Uploading...');
        this.folderService.uploadFile(formData, this.folderpath, this.folderService.currentParentFolderId).subscribe(data => {
            this.blockUI.stop();
            this.dialogRef.close();
            this.openSnackBar('Uploaded successfully', 'bg-success');
        }, err => {
            this.blockUI.stop();
            this.dialogRef.close();
            this.openSnackBar('Failed to upload', 'bg-danger');
        })


    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


    fileChangeEvent(fileInput: any) {

        if (fileInput.target.files && fileInput.target.files[0]) {
            const file = fileInput.target.files;
            this.uploadForm.get('fileUpload').setValue(file);


            this.myfilename = '';
            Array.from(fileInput.target.files).forEach((file: File) => {
                this.myfilename += file.name + ' , ';
            });

            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    // Return Base64 Data URL
                    const imgBase64Path = e.target.result;
                };
            };

            reader.readAsDataURL(fileInput.target.files);

            // Reset File Input to Selct Same file again
            this.uploadFileInput.nativeElement.value = "";



        } else {
            this.myfilename = 'Select File';
        }

    }


}