import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from './auth.service';
import { AdalService } from 'adal-angular4';
import { catchError } from 'rxjs/operators';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
@Injectable()

export class TokenInterceptor implements HttpInterceptor {
  token: string = '';
  constructor(public auth: AuthService, private adalSvc: AdalService) { 

    this.adalSvc.acquireToken('https://graph.microsoft.com').subscribe(resp => {

      this.token = resp;
  })
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    // request = request.clone({
    //   setHeaders: {
    //     Authorization: `Bearer ` + this.adalSvc.userInfo.token
    //   }
    // });

    // //return next.handle(request); previous implementation
    // return next.handle(request).pipe(catchError(err => {
      
    //   // if ([401, 403].includes(err.status)) {
    //   //   this.adalSvc.logOut();
    //   // }
    //   const error = (err && err.error && err.error.message) || err.statusText;
    //   console.error(err);
    //   return throwError(error);
    // }))

    if (request.headers.has(InterceptorSkipHeader)) {

      this.adalSvc.acquireToken('https://graph.microsoft.com').subscribe(resp => {
        this.token = resp;
          request = request.clone({
              setHeaders: {
                  "Content-Type":
                      "application/json",
                  Authorization: 'Bearer ' + this.token //Microsoft Graph API Token
              }
          })
      })
      return next.handle(request)

  }
  else {
      request = request.clone({
          setHeaders: {
              Authorization: 'Bearer ' + this.adalSvc.userInfo.token  // LMS Token  = Azure AD Token
          }
      })
      return next.handle(request)
  }

  }

}