import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData } from '../../interfaces/folder';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
@Component({
    selector: 'file-properties-dialog',
    templateUrl: './file-properties-dialog.component.html',
    styleUrls:['./file-properties-dialog.component.css']
})

export class FilePropertiesDialog implements OnInit {
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagsCtrl = new FormControl();
    filteredTags: any;
    tags: any = [];
    allTags: any = [];

    public fileData;
    public tagList;
    public state:boolean=true;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar,private folderService:FolderService) { 

            this.fileData = this.data;
            this.folderService.getAllAvailableTags().subscribe(resp => {

                this.allTags = resp.body;
                this.filteredTags = this.tagsCtrl.valueChanges.pipe(
                    startWith(null),
                    map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
            });
    

        }

    ngOnInit() {
  
        this.folderService.getTagsByDocumentID(this.fileData.ID).subscribe(resp=>{
            this.tagList=resp.body;
            this.tags=this.tagList;
        })

    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    editTags(){
     this.state=!this.state;
    }
    
    saveTags(){
        this.folderService.updateTagsByDocumentID(this.fileData.ID,this.tags).subscribe(resp=>{
    
            this.state=true;
            this.openSnackBar('Updated tags successfully','bg-success');
        },err=>{
            this.openSnackBar('Error updating tags','bg-danger');
        });
    }

    
    add(event: MatChipInputEvent): void {


        const input = event.input;
        const value = event.value;

        // Add our tag
        if ((value || '').trim()) {
            const tagDTO = {
                TagID: 0,
                Text: value.trim()
            }
            this.tags.push(tagDTO);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.tagsCtrl.setValue(null);
    }

    remove(tag: string): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {


        const found = this.tags.indexOf(event.option.value);

        if (found == -1) {
            this.tags.push(event.option.value);
            this.tagInput.nativeElement.value = '';
            this.tagsCtrl.setValue(null);
        } else {
            this.openSnackBar('Tag already addedd', 'bg-danger');
        }


    }

    private _filter(value: string): string[] {

        const filterValue = value.toLowerCase();

        return this.allTags.filter(tag => tag.Text.toLowerCase().indexOf(filterValue) === 0);
    }


    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}