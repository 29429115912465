import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { UserInfo } from 'src/app/components/shared/interfaces/user';
import { UserService } from 'src/app/services/User/user.service';
import { AddUserDialog } from './add-user-dialog/add-user-dialog.component';
import { DeleteUserDialog } from './delete-user-dialog/delete-user-dialog.component';
import { EditUserDialog } from './edit-user-dialog/edit-user-dialog.component';
import { SyncUserDialog } from './sync-user-dialog/sync-user.component';

@Component({
  selector: 'app-admin-manageusers',
  templateUrl: './admin-ManageUsers.component.html',
  styleUrls: ['./admin-ManageUsers.component.css']
})

export class AdminManageUsersComponent implements OnInit {
  public listOfUsers: Array<any> = [];
  public globalData;
  public dataSource: MatTableDataSource<UserInfo>;
  public document: any = null;
  public spinner: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['UserID', 'Name', 'Email', 'IsAdministrator', 'position', 'telephone', 'DepartmentName', 'Status', 'Actions'];

  constructor(public dialog: MatDialog, public router: Router, private userService: UserService, private cdr: ChangeDetectorRef,private adalSvc: AdalService) {
    this.getUsersAndPermission();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.userService.getO365Users().subscribe(resp => {
      this.listOfUsers = resp.body.value;
    });
  }

  private filterNewTable(dataSorted): any {
    this.dataSource = new MatTableDataSource<UserInfo>(dataSorted);
    this.cdr.detectChanges();
    this.ngAfterViewInit();
  }

  public filterByStatus(event): any {
    if (event.value == 'active') {
      let dataSorted = this.globalData.filter(x => x.IsActive == true);
      this.filterNewTable(dataSorted);
    } else if (event.value == 'dissabled') {
      let dataSorted = this.globalData.filter(x => x.IsActive == false);
      this.filterNewTable(dataSorted);
    } else {
      this.filterNewTable(this.globalData);
    }
  }

  private getUsersAndPermission(): void {

    this.userService.getListOfUsers().subscribe(resp => {
      this.globalData = resp.body;
      this.spinner = false;
      this.filterNewTable(this.globalData);
    })
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public addUser(): void {
    const dialogRef = this.dialog.open(AddUserDialog, {
      width: '600px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'submited') {
        this.getUsersAndPermission();
      } else {

      }
    });

  }
  public editUser(element): void {

    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '700px',
      autoFocus: false,
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'submited') {
        this.getUsersAndPermission();
      } else {

      }
    });
  }

  public syncUsers():void{

    const dialogRef = this.dialog.open(SyncUserDialog, {
      width: '1100px',
      autoFocus: false,
      data: this.listOfUsers
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUsersAndPermission();
    });

  }

  deleteUser(element) {

    const dialogRef = this.dialog.open(DeleteUserDialog, {
      width: '600px',
      autoFocus: false,
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'submited') {
        this.getUsersAndPermission();
      } else {

      }
    });
  }

}
