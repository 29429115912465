import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { WorkflowDTO, workflowForm } from 'src/app/components/shared/interfaces/workflow';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class WorkflowService {
    constructor(private http: HttpClient) {

    }

    createWorkflow(workflowDTO: WorkflowDTO): Observable<HttpResponse<any>> {

        const url = environment.workflow_Url;

        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.post(url + '/api/workflow', workflowDTO, { headers: headers_object, observe: "response" });

    }

    getListOfWorkflows():Observable<HttpResponse<any>> {

        const url = environment.workflow_Url ;
    
        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
          });
          
        return this.http.get(url +'/api/workflow?page=1&batch=100' ,{ headers: headers_object,observe: "response" });
    
      }
      getWorkflowByID(id:string):Observable<HttpResponse<any>> {

        const url = environment.workflow_Url ;
    
        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
          });
          
        return this.http.get(url +'/api/workflow/'+id ,{ headers: headers_object,observe: "response" });
    
      }

      deleteWorkflowByID(id:string):Observable<HttpResponse<any>> {

        const url = environment.workflow_Url ;
    
        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
          });
          
        return this.http.delete(url +'/api/workflow/'+id ,{ headers: headers_object,observe: "response" });
    
      }

      deleteWorkflowFormByID(id:number):Observable<HttpResponse<any>> {

        const url = environment.workflow_Url ;
    
        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
          });
          
        return this.http.delete(url +'/api/workflow/'+id ,{ headers: headers_object,observe: "response" });
    
      }



      createWorkflowForms(workflowDTO: workflowForm): Observable<HttpResponse<any>> {

        const url = environment.workflow_Url;

        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.post(url + '/api/workflowForm', workflowDTO, { headers: headers_object, observe: "response" });

    }


      getListOfWorkflowsForms():Observable<HttpResponse<any>> {

        const url = environment.workflow_Url ;
    
        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
          });
          
        return this.http.get(url +'/api/WorkflowForm?page=1&batch=100' ,{ headers: headers_object,observe: "response" });
    
      }

      getListOfWorkflowsFormsByUserID(userID):Observable<HttpResponse<any>> {

        const url = environment.workflow_Url ;
    
        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
          });
          
        return this.http.get(url +`/api/WorkflowForm/specific/${userID}?page=1&batch=100` ,{ headers: headers_object,observe: "response" });
    
      }


      
      getWorkflowFormByID(id:string):Observable<HttpResponse<any>> {

        const url = environment.workflow_Url ;
    
        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
          });
          
        return this.http.get(url +'/api/WorkflowForm/'+id ,{ headers: headers_object,observe: "response" });
    
      }


      
      submitWorkflowFormStep(workflowStepDTO): Observable<HttpResponse<any>> {

        const url = environment.workflow_Url;

        let headers_object = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.post(url + '/api/WorkflowForm/next', workflowStepDTO, { headers: headers_object, observe: "response" });

    }
    
}