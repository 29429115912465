<div class="container-fluid">
    <div class="row">
        <div class="col-md-4 mb-4">
            <div class="card l-bg-cherry shadow anim anim-first border rounded">
                <div class="card-statistic-3 p-4">
                    <div class="card-icon card-icon-large"><i class="fas fa-cloud-upload-alt fa-2x text-primary"></i></div>
                    <div class="mb-4">
                        <h5 class="card-title mb-0">Available Space</h5>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0">
                                12.6 Gb
                            </h2>
                        </div>
                        <div class="col-4 text-right">
                            <span>30% <i class="fa fa-arrow-up"></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
                        <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                    </div>
                </div>
            </div><br>
            <div class="card m-b-30 shadow anim anim-third border rounded">
                <div class="card-header bg-primary text-white">                                
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h5 class="card-title mb-0 ">Important Files</h5>
                        </div>
                        <div class="col-4">
                            <ul class="list-inline-group text-right mb-1 pl-0">
                                <li class="list-inline-item mr-0 font-12"><i class="feather icon-more-vertical- font-20 text-primary"></i></li>
                            </ul>                                        
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="product-file-type">
                        <ul class="list-unstyled">
                            <li class="media mb-3">
                                <span class="mr-3 align-self-center img-icon primary-rgba text-primary">.doc</span>
                                <div class="media-body">
                                <h5 class="font-16 mb-1">Marketing Guidelines<i class="feather icon-download-cloud float-right"></i></h5>
                                <p>.doc, 5.3 MB</p>
                                </div>
                            </li>
                            <li class="media mb-3">
                                <span class="mr-3 align-self-center img-icon success-rgba text-success">.xls</span>
                                <div class="media-body">
                                <h5 class="font-16 mb-1">Complete Product Sheet<i class="feather icon-download-cloud float-right"></i></h5>
                                <p>.xls, 2.5 MB</p>
                                </div>
                            </li>
                            <li class="media mb-3">
                                <span class="mr-3 align-self-center img-icon danger-rgba text-danger">.pdf</span>
                                <div class="media-body">
                                <h5 class="font-16 mb-1">Annual Sales Report 2018-19<i class="feather icon-download-cloud float-right"></i></h5>
                                <p>.pdf, 10.5 MB</p>
                                </div>
                            </li>
                            <li class="media">
                                <span class="mr-3 align-self-center img-icon info-rgba text-info">.zip</span>
                                <div class="media-body">
                                <h5 class="font-16 mb-1">Brand Photography<i class="feather icon-download-cloud float-right"></i></h5>
                                <p>.zip, 53.2 MB</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col-md-4 mb-4">
            <div class="card l-bg-cherry shadow anim anim-second border rounded">
                <div class="card-statistic-3 p-4">
                    <div class="card-icon card-icon-large"><i class="fas fa-file-upload fa-2x text-warning"></i></div>
                    <div class="mb-4">
                        <h5 class="card-title mb-0">Uploaded files</h5>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0">
                                330 
                            </h2>
                        </div>
                        <div class="col-4 text-right">
                            <span>30% <i class="fa fa-arrow-up"></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
                        <div class="progress-bar bg-success" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 50%;"></div>
                    </div>
                </div>
            </div><br/>
            <div class="card m-b-30 shadow anim anim-last border rounded">
                <div class="card-header bg-primary text-white">                                
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h5 class="card-title mb-0">Audit Logs</h5>
                        </div>
                        <div class="col-4">
                            <ul class="list-inline-group text-right mb-1 pl-0">
                                <li class="list-inline-item mr-0 font-12"><i class="feather icon-more-vertical- font-20 text-primary"></i></li>
                            </ul>                                        
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="product-file-type">
                        <ul class="list-unstyled">
                            <li class="media mb-3">
                                <span class="mr-3 align-self-center img-icon primary-rgba text-primary"><i class="fas fa-clipboard-list"></i></span>
                                <div class="media-body">
                                <h5 class="font-16 mb-1">You uploaded file.txt<i class="feather icon-download-cloud float-right"></i></h5>
                                <p>12.02.2020</p>
                                </div>
                            </li>
                            <li class="media mb-3">
                                <span class="mr-3 align-self-center img-icon primary-rgba text-primary"><i class="fas fa-clipboard-list"></i></span>
                                <div class="media-body">
                                <h5 class="font-16 mb-1">You shared privacy.exel with group<i class="feather icon-download-cloud float-right"></i></h5>
                                <p>22.02.2020</p>
                                </div>
                            </li>
                            <li class="media mb-3">
                                <span class="mr-3 align-self-center img-icon primary-rgba text-primary"><i class="fas fa-clipboard-list"></i></span>
                                <div class="media-body">
                                <h5 class="font-16 mb-1">You have deleted 3 files.<i class="feather icon-download-cloud float-right"></i></h5>
                                <p>12.05.2020</p>
                                </div>
                            </li>
                            <li class="media">
                                <span class="mr-3 align-self-center img-icon primary-rgba text-primary"><i class="fas fa-clipboard-list"></i></span>
                                <div class="media-body">
                                <h5 class="font-16 mb-1">Directory has been changed<i class="feather icon-download-cloud float-right"></i></h5>
                                <p>14.03.2020</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 mb-4">
            <div class="card m-b-30 shadow anim anim-third border rounded">
                <div class="card-header bg-primary text-white">                                
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h5 class="card-title mb-0 ">Recent Activity</h5>
                        </div>
                        <div class="col-4">
                            <ul class="list-inline-group text-right mb-1 pl-0">
                                <li class="list-inline-item mr-0 font-12"><i class="feather icon-more-vertical- font-20 text-primary"></i></li>
                            </ul>                                        
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="product-file-type">
                        <ul class="list-unstyled">
                            <li class="media mb-3" *ngFor="let activity of activitys">
                                <span class="mr-3 align-self-center img-icon primary-rgba text-primary"><i class="fa fa-history" aria-hidden="true"></i></span>
                                <div class="media-body">
                                    <h5 class="font-16 mb-1">{{activity.Operation}}</h5>
                                    <p>{{activity.Date | date:'dd/MM/yyyy HH:mm'}} </p>
                                </div>
                            </li>
                        </ul>
                        <div *ngIf="activitys !=0">
                            <a href="#">Show more ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>