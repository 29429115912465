<div *blockUI="'folder-create'">
  <h1 mat-dialog-title style="font-weight: 350;font-size: 30px;">Add Folder to  <small class="text-primary">/{{folderpathTitle}} </small> </h1>
 
  <div mat-dialog-content>
    <form>
      <mat-form-field class="example-full-width" style="display: block;">
        <mat-label>Name</mat-label>
        <input  [formControl]="NameFormControl"  matInput placeholder="Ex. Folder 1" >
      </mat-form-field>
      
    </form>
  </div>

<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [disabled]="!NameFormControl.valid"  mat-flat-button color="primary" (click)="onFolderCreation()" >Ok</button>
</mat-dialog-actions>


</div>
 

