import { NgModule } from "@angular/core";
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { CardBodyComponent } from '../card-body/card-body.component';
import { CardHeaderComponent } from '../card-header/card-header.component';
import { TreeViewComponent } from '../tree-view/tree-view.component';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import { createFolderDialog } from '../dialogs/create-folder-dialog';
import { UploadFileDialog } from '../dialogs/upload-file-dialog/upload-file-dialog';
import { BreadCrumComponent } from '../breadcrum/breadcrum.component';
import { deleteFileDialog } from '../dialogs/delete-file-dialog/delete-file-dialog.component';
import { renameFolderDialog } from '../dialogs/rename-folder-dialog/rename-folder-dialog.component';
import { ShareFileDialog } from '../dialogs/share-file-dialog/share-file-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { moveFileDialog } from '../dialogs/move-file-dialog/move-file-dialog.component';
import { SearchFileDialog } from '../dialogs/searched-file-dialog/searched-file-dialog.component';
import { PreviewFileComponent } from '../PreviewFile/previewFile.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { RouterModule } from '@angular/router';
import { myProfileComponent } from '../myProfile/myprofile.component';
import { SpinnerComponent } from '../Spinner/spinner.component';
import { SpinnerSmallComponent } from '../Spinner_small/spinner_small.component';
import { AddPermissionFileDialog } from '../dialogs/add-permission-dialog/add-permission-dialog.component';
import { PasswordResetComponent } from '../password-reset/password-reset.component';
import { ForgotPasswordDialog } from '../dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { FilePropertiesDialog } from "../dialogs/file-properties-dialog/file-properties-dialog.component";
import { AddRoleDialog } from "../dialogs/add-role-users-dialog/add-role-users-dialog.component";
import { CreateWorkflowFormsDialog } from "../../Administrator/administrator/WorkflowForms/createWorkflowForms/create-workflowForm.component";
import { DeleteWorkflowDialog } from '../../Administrator/administrator/Workflow/RemoveWorkflow/remove-workflow.component';
import { DeleteWorkflowFormDialog } from '../../Administrator/administrator/WorkflowForms/removeWorkflowForms/remove-workflow-form.component';

@NgModule({

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        MatTreeModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        MatGridListModule,
        MatSidenavModule,
        A11yModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
        MatFormFieldModule,
        BlockUIModule,
        NgxMatSelectSearchModule,
        NgxDocViewerModule,
        RouterModule ]
    ,
    declarations: [
        CardHeaderComponent,
        TreeViewComponent,
        CardBodyComponent,
        createFolderDialog,
        UploadFileDialog,
        BreadCrumComponent,
        deleteFileDialog,
        renameFolderDialog,
        ShareFileDialog,
        moveFileDialog,
        SearchFileDialog,
        PreviewFileComponent,
        myProfileComponent,
        SpinnerComponent,
        SpinnerSmallComponent,
        AddPermissionFileDialog,
        PasswordResetComponent,
        ForgotPasswordDialog,
        FilePropertiesDialog,
        AddRoleDialog,
        CreateWorkflowFormsDialog,
        DeleteWorkflowDialog,
        DeleteWorkflowFormDialog

    ],
    exports: [
        CardHeaderComponent,
        TreeViewComponent,
        CardBodyComponent,
        createFolderDialog,
        UploadFileDialog,
        BreadCrumComponent,
        deleteFileDialog,
        renameFolderDialog,
        ShareFileDialog,
        moveFileDialog,
        SearchFileDialog,
        PreviewFileComponent,
        myProfileComponent,
        SpinnerComponent,
        SpinnerSmallComponent,
        AddPermissionFileDialog,
        PasswordResetComponent,
        ForgotPasswordDialog,
        FilePropertiesDialog,
        AddRoleDialog,
        CreateWorkflowFormsDialog,
        DeleteWorkflowDialog,
        DeleteWorkflowFormDialog
    ]
})


export class SharedModule { }