
<div class="m-3">
    <a [routerLink]="['/admin/UserFiles']"  class="btn btn-primary">
        Back
    </a>
</div>
<div *ngIf="state!=null">
    <div class="m-3 preview" *ngIf="state.content==null">
        <div *ngIf="state.element.BlobType.includes('pdf')">
            <ngx-doc-viewer   [url]="state.link" viewer="pdf" style="width:100%;height:100vh;"></ngx-doc-viewer>
        </div>
        <div *ngIf="state.element.BlobType.includes('msword')">
            <ngx-doc-viewer   [url]="state.link" viewer="office" style="width:100%;height:100vh;"></ngx-doc-viewer>
        </div>
        <div *ngIf="state.element.BlobType.includes('officedocument')">
            <ngx-doc-viewer   [url]="state.link" viewer="office" style="width:100%;height:100vh;"></ngx-doc-viewer>
        </div>
        <div *ngIf="state.element.BlobType.includes('text')">
            <ngx-doc-viewer   [url]="state.link" viewer="url" style="width:100%;height:100vh;"></ngx-doc-viewer>
        </div>
    </div>
    <div class="m-3 preview" *ngIf="state.content != null" >
        <div *ngIf="state.content.includes('pdf')">
            <ngx-doc-viewer   [url]="state.link" viewer="pdf" style="width:100%;height:100vh;"></ngx-doc-viewer>
        </div>
        <div *ngIf="state.content.includes('msword')">
            <ngx-doc-viewer   [url]="state.link" viewer="office" style="width:100%;height:100vh;"></ngx-doc-viewer>
        </div>
        <div *ngIf="state.content.includes('officedocument')">
            <ngx-doc-viewer   [url]="state.link" viewer="office" style="width:100%;height:100vh;"></ngx-doc-viewer>
        </div>
        <div *ngIf="state.content.includes('text')">
            <ngx-doc-viewer   [url]="state.link" viewer="url" style="width:100%;height:100vh;"></ngx-doc-viewer>
        </div>
    </div>
</div>
<div *ngIf="state == 'error'" >
    <div id="notfound">
		<div class="notfound">
			<div class="notfound-404"></div>
			<h1>404</h1>
			<h2>Oops! File Not Be Found</h2>
			<p>Sorry but the file you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
			<a (click)="backHome()" style="cursor: pointer;" >Back to homepage</a>
		</div>
	</div>
</div>


