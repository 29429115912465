<div *blockUI="'file-share'">
    <h1 mat-dialog-title>Found Files </h1>

    <div mat-dialog-content>

        <mat-list>
            <ng-container *ngFor="let element of data" >
                <mat-list-item *ngIf="element.metadata_storage_name !== 'skip.txt'" >

                    <div *ngIf="element.metadata_storage_content_type.includes('pdf')"><i
                            class="fas fa-file-pdf fa-2x text-danger"></i></div>
                    <div *ngIf="element.metadata_storage_name.includes('exel') || element.metadata_storage_name.includes('xlsx') "><i
                            class="fas fa-file-excel fa-2x text-success"></i></div>
                    <div *ngIf="element.metadata_storage_content_type.includes('word')"><i
                            class="fas fa-file-word fa-2x text-primary"></i></div>
                    <div *ngIf="element.metadata_storage_content_type.includes('image')"><i class="far fa-file-image fa-2x "></i>
                    </div>
                    <div *ngIf="element.metadata_storage_name.includes('.txt')"><i class="fas fa-file fa-2x "></i></div>
                    <div *ngIf="element.metadata_storage_name.includes('.ppt')"><i
                            class="fas fa-file-powerpoint fa-2x text-warning"></i></div>
                    <!-- <div *ngIf="element.metadata_storage_name.includes('.zip')"><i
                            class="far fa-file-archive fa-2x"></i></div> -->
                    <div class="ml-2" (click)="previewFile(element.metadata_storage_path,element.metadata_storage_content_type)" style="cursor: pointer; ">
                        {{element.metadata_storage_name}}
                    </div>

                </mat-list-item>

            </ng-container>

        </mat-list>

    </div>

    <mat-dialog-actions align="end" class="mt-2">
        <button mat-button (click)="onNoClick()">Close</button>
    </mat-dialog-actions>

</div>