import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';
import { LoginService } from './services/Login/login.service';
import { UserService } from './services/User/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'DocumentManagementSystem';
  public showWrapper:boolean=false;
  constructor(private adalSvc: AdalService,public router: Router,private userService: UserService, private loginService: LoginService) {
    this.adalSvc.init(environment.adalConfig);

  }

  ngOnInit() {
    this.adalSvc.handleWindowCallback();
  }

}
