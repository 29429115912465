import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogInComponent } from './components/login/login.component';
import { MainAppComponent } from './components/MainComponent/main.component';
import { ForgotPasswordComponent } from './components/shared/forgotpassword/forgotpassword.component';
import { AuthGuardService } from './services/Auth/auth-guard.service';
import { RoleGuardService } from './services/Auth/role-guard.service';

const routes: Routes = [
  { path: '', component:MainAppComponent},
  { path: 'login', component: LogInComponent },
  { path: 'generatedLink/:id', component: ForgotPasswordComponent },
  { path: 'admin',  loadChildren: () => import('./components/Administrator/administrator/administrator.module').then(m => m.AdministratorModule) ,  data: { 
    expectedRole: "True"
  },canActivate: [AuthGuardService],canLoad:[RoleGuardService] },
  { path: 'user', loadChildren: () => import('./components/SimpleUser/simple-user/simple-user.module').then(m => m.SimpleUserModule) , data: { 
    expectedRole: "False"
  },canActivate: [AuthGuardService],canLoad:[RoleGuardService]},
  { path: '**', pathMatch: 'full', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
