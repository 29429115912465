import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserService } from 'src/app/services/User/user.service';
import { createUserDTO, UserInfo } from '../interfaces/user';

@Component({
    selector: 'forgot-pass',
    templateUrl: './myprofile.component.html',
    styleUrls: ['./myprofile.component.css']
})
export class myProfileComponent implements OnInit {

    public delveLink:string;
    @BlockUI('update-user') blockUI: NgBlockUI;

    public myProfile: UserInfo = null;
    constructor(private userService: UserService,private _snackBar: MatSnackBar) {

    }

    public updateUserForm = new FormGroup({
        email: new FormControl('', Validators.email),
        firstname: new FormControl(''),
        lastname: new FormControl(''),
        address: new FormControl(''),
        telephone: new FormControl(''),
        position: new FormControl(''),
        about: new FormControl(''),
    });

    ngOnInit() {
        this.userService.getUserInfo().subscribe(resp => {
            this.myProfile = resp.body;
            this.delveLink=`https://eur.delve.office.com/?u=${resp.body.O365UserID}&v=work`;
            this.updateUserForm = new FormGroup({
                email: new FormControl({ value: this.myProfile.Email, disabled: true }),
                firstname: new FormControl(this.myProfile.Firstname),
                lastname: new FormControl(this.myProfile.Lastname),
                address: new FormControl(this.myProfile.Address),
                telephone: new FormControl(this.myProfile.Telephone),
                position: new FormControl(this.myProfile.Position),
                about: new FormControl(this.myProfile.AboutMe),
            })
        });
    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
        });
    }

    updateProfile() {
        this.blockUI.start('Updating profile...')
        const userDTO:createUserDTO ={
            UserData:{
                UserID:this.myProfile.UserID,
                IsAdministrator:this.myProfile.IsAdministrator,
                Name:this.myProfile.Name,
                Firstname:this.updateUserForm.getRawValue().firstname,
                Lastname:this.updateUserForm.getRawValue().lastname,
                Email:this.updateUserForm.getRawValue().email,
                Position:this.updateUserForm.getRawValue().position,
                Telephone:this.updateUserForm.getRawValue().telephone,
                Address:this.updateUserForm.getRawValue().address,
                AboutMe:this.updateUserForm.getRawValue().about
            }
        }

        this.userService.updateUser(userDTO).subscribe(resp=>{
            this.blockUI.stop();
            this.openSnackBar('Profile updated successfully','bg-success');
        },err=>{
            this.blockUI.stop();
            this.openSnackBar('Error updating profile','bg-danger');
        })
       
    }

}
