import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/User/user.service';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData } from '../../interfaces/folder';



@Component({
    selector: 'forgot-password-dialog',
    templateUrl: './forgot-password-dialog.component.html',
})

export class ForgotPasswordDialog implements OnInit {


    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private userService: UserService) { }

    public emailFormControl = new FormControl('', [
        Validators.required
    ]);

    ngOnInit() {

    }

    onRenameFile() {

    }

    public onNoClick(): void {
        this.dialogRef.close();


    }


    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    public sendLink(): void {
        if (this.emailFormControl.valid) {
            this.userService.forgotPassword(this.emailFormControl.value).subscribe(resp => {
                this.dialogRef.close();
                this.openSnackBar('Please check email to confirm password reset','bg-primary');
            }, err => {
                this.dialogRef.close();
                this.openSnackBar('Error acured !','bg-primary');
            });
        }


    }

}