import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AddDepartmentDTO, ChangeDepStatusDTO, EditDepartment } from 'src/app/components/shared/interfaces/departament';
import { Guid } from 'guid-typescript';
import { InterceptorSkipHeader } from '../Auth/token.interceptor';


@Injectable({
  providedIn: 'root'
})
export class DepartamentService {

  app_url:string = environment.app_Url;


  constructor(private http:HttpClient) { }

  getDepartaments():Observable<HttpResponse<any>> {
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    return this.http.get(url +'api/Departament/GetDepartments' ,{ headers: headers_object,observe: "response" });
  }

  createDepartament(departament:AddDepartmentDTO):Observable<HttpResponse<any>>{
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    return this.http.post(url +'api/Departament/Post' ,departament,{ headers: headers_object,observe: "response" });
  }

  changeStatusOfDepartament(departament:ChangeDepStatusDTO):Observable<HttpResponse<any>>{
 
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    return this.http.put(url +'api/Departament/ChangeStatus' ,departament,{ headers: headers_object,observe: "response" });
  }

  editDepartament(departament:EditDepartment):Observable<HttpResponse<any>>{
    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    return this.http.put(url +'api/Departament/Edit' ,departament,{ headers: headers_object,observe: "response" });
  }
  
  getDepartamentByID(depId:Guid):Observable<HttpResponse<any>>{

    const url = this.app_url ;

    let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    return this.http.get(url +`api/Departament/GetByID?depId=${depId}` ,{ headers: headers_object,observe: "response" });
  }

  createNewGroup(groupDto: any): Observable<any> {

    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    const url = `https://graph.microsoft.com/v1.0/groups`;
    return this.http.post(url, groupDto,{ headers: headers,observe: "response" });

  }




}
