import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { take, takeUntil } from 'rxjs/operators';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { UserService } from 'src/app/services/User/user.service';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData, FolderShare } from '../../interfaces/folder';
import decode from 'jwt-decode';
import { TokenDTO } from '../../interfaces/token';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'share-file-dialog',
  templateUrl: './share-file-dialog.component.html',
  styleUrls: ['./share-file-dialog.component.css']
})

export class ShareFileDialog implements OnInit, AfterViewInit, OnDestroy {
  public selectedUsers:Array<any>=null;
  public dataSource2;
  public state: number = 1;
  public documentFile: any = null;
  displayedColumns: string[] = ['position', 'UserName', 'HasRead', 'HasWrite', 'CanShare'];
  @BlockUI('share-file') blockUI: NgBlockUI;

  public companyUsers = [

  ];
  public fileData;
  public users: Array<any> = this.companyUsers;

  /** control for the selected bank for multi-selection */
  public usersMultiCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public usersMultiFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredUsersMulti: ReplaySubject<Array<any>> = new ReplaySubject<Array<any>>(1);

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private userService: UserService, private folderService: FolderService) {

  }

  ngOnInit() {

    const tokenPayload: TokenDTO = decode(localStorage.getItem('AuthToken'));

    this.fileData = this.data;
    if(this.fileData.TypeName=='Folder'){
      this.displayedColumns=['position', 'UserName', 'HasRead', 'HasWrite', 'CanShare','CanUpload'];
    }

    this.userService.getListOfUsers().subscribe(response => {



      let list = response.body.filter(user => user.UserID != parseInt(tokenPayload.UserId));

      list.forEach(user => {
        let uniqueUser = {
          UserName: user.Name,
          UserID: user.UserID,
          HasRead: true,
          HasWrite: false,
          CanUpload: false,
          CanShare: false
        }
        this.companyUsers.push(uniqueUser);
      })

      // set initial selection
      this.usersMultiCtrl.setValue([]);

      // load the initial bank list
      this.filteredUsersMulti.next(this.users.slice());

      // listen for search field value changes
      this.usersMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBanksMulti();
        });

    })


  }

  changeState(number) {
    this.state = number;
    this.selectedUsers = this.usersMultiCtrl.value;
    this.dataSource2 = new MatTableDataSource<any>(this.selectedUsers);
  }
  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredUsersMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.usersMultiCtrl.patchValue(val);
        } else {
          this.usersMultiCtrl.patchValue([]);
        }
      });
  }

  protected setInitialValue() {
    this.filteredUsersMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a, b) => a && b && a.id === b.id;
      });
  }

  protected filterBanksMulti() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.usersMultiFilterCtrl.value;
    if (!search) {
      this.filteredUsersMulti.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredUsersMulti.next(
      this.users.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  public onNoClick(): void {
    this.dialogRef.close();
    this.openSnackBar('Action cancelled', 'bg-primary');

  }
  private openSnackBar(name: string, className: string): void {
    this._snackBar.open(name, `X`, {
      duration: 3000,
      panelClass: [className],
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  public shareFile(): void {
    this.blockUI.start('Sharing file ...');



    const objShare: FolderShare = {

      NodeID: this.fileData.ID,
      permissions :this.selectedUsers
    }
    this.folderService.shareFile(objShare).subscribe(response => {
      this.blockUI.stop();
      this.dialogRef.close();
      this.openSnackBar('File shared successfully', 'bg-success');
    }, err => {
      this.openSnackBar('File shared error', 'bg-danger');
      this.dialogRef.close();
      this.blockUI.stop();
    })

  }

  addPermission(element, permission) {

    if (permission == 'HasWrite') {
      this.selectedUsers.forEach(user => {
        if (user.UserID == element.UserID) {
          user.HasWrite = !user.HasWrite
        }
      })
    }else if(permission == 'CanShare'){
      this.selectedUsers.forEach(user => {
        if (user.UserID == element.UserID) {
          user.CanShare = !user.CanShare
        }
      })
    }else if(permission == 'CanUpload'){
      this.selectedUsers.forEach(user => {
        if (user.UserID == element.UserID) {
          user.CanUpload = !user.CanUpload
        }
      })
    }

  }

}