<app-navbar (isShowSidebar)="showhide($event)" [notificationList]='notificationList' [numberOfNotificaitons]="numberOfUnreadNotifications" (changeUnreadNotificationNumber)="changeNotificationNumber($event)" ></app-navbar>
<spinner *ngIf="spinner"></spinner>
<mat-sidenav-container autosize>
    <mat-sidenav #sidenav mode="side" opened="true" *ngIf="!spinner">
        <mat-nav-list>
            <ul class="navbar-nav mr-auto" *ngIf="isAuthenticated && status">
                <li class="nav-item" *ngFor="let navItem of adminNavBarItems; let i = index">
                    <mat-list-item [routerLink]="[navItem.link]" class="nav-link" routerLinkActive="active">
                        <mat-icon mat-list-icon class="">{{navItem.icon}}</mat-icon>
                        <span *ngIf="isExpanded">{{navItem.label}}</span>
                    </mat-list-item>
                </li>
            </ul>

            <ul class="navbar-nav mr-auto" *ngIf="isAuthenticated && !status">
                <li class="nav-item" *ngFor="let navItem of simpleUserNavBarItems">
                    <mat-list-item [routerLink]="[navItem.link]" class="nav-link" routerLinkActive="active">
                        <mat-icon mat-list-icon class="">{{navItem.icon}}</mat-icon>
                        <span *ngIf="isExpanded">{{navItem.label}}</span>
                    </mat-list-item>
                </li>
            </ul>

            <!-- <div *ngIf="isAuthenticated && status">
                <mat-list-item (click)="isExpandedDropDown = !isExpandedDropDown">
                    <mat-icon mat-list-icon class="chevron-button1" [ngClass]="{'rotated1': isExpandedDropDown}">expand_more
                    </mat-icon>
                    <span *ngIf="isExpanded">Form Management</span>
                </mat-list-item>
    
                <mat-list-item [routerLink]="['admin/CreateForm']" *ngIf="(isExpandedDropDown && isExpanded)"
                    class="dropdown-mininav" routerLinkActive="active">
                    <mat-icon mat-list-icon class="">add_circle_outline</mat-icon>
                    <span>Create Form</span>
                </mat-list-item>
                <mat-list-item [routerLink]="['admin/MyForm']" *ngIf="(isExpandedDropDown && isExpanded)"
                    class="dropdown-mininav" routerLinkActive="active">
                    <mat-icon mat-list-icon class="">list_alt</mat-icon>
                    <span>My Forms</span>
                </mat-list-item>
                <mat-list-item [routerLink]="['admin/SharedForm']" *ngIf="(isExpandedDropDown && isExpanded)"
                    class="dropdown-mininav" routerLinkActive="active">
                    <mat-icon mat-list-icon class="">folder_shared</mat-icon>
                    <span>Shared Forms</span>
                </mat-list-item>
                <mat-list-item [routerLink]="['admin/workflow-form-instances']" *ngIf="(isExpandedDropDown && isExpanded)"
                    class="dropdown-mininav">
                    <mat-icon mat-list-icon class="">grid_view</mat-icon>
                    <span>Workflow Forms</span>
                </mat-list-item>
            </div> -->


        </mat-nav-list>
    </mat-sidenav>

    <div class="content">
        <router-outlet></router-outlet>
    </div>

</mat-sidenav-container>