import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { DialogOverviewExampleDialog } from '../card-body/card-body.component';
import { DialogData, FolderPost } from '../interfaces/folder';


@Component({
    selector: 'create-folder-dialog',
    templateUrl: './create-folder-dialog.html',
  })
  
  export class createFolderDialog implements OnInit {
    @BlockUI('folder-create') blockUI: NgBlockUI;
    
    public folderpathTitle:string='';
    public folderpath:string='';
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
      public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private folderService: FolderService) { }
  
    public NameFormControl = new FormControl('', [
      Validators.required
    ]);
    ngOnInit(){
      this.folderpathTitle=this.folderService.currentFolderPath;

      if(this.folderpathTitle !== null){
        const arr =this.folderpathTitle.split('/');
        this.folderpathTitle= arr[arr.length-2];
        
        for(let i=1;i<=arr.length-2;i++){
          this.folderpath=this.folderpath+arr[i]+'/';
        }


      }
    
    }
  
    public onNoClick(): void {
  
      this.dialogRef.close();
      this.openSnackBar('Action cancelled', 'bg-primary');
  
    }
  
    public onFolderCreation(): void {
      this.blockUI.start('Creating folder...');
  
      const folderDTO: FolderPost = {
        Title: this.NameFormControl.value,
        ParentFolderID: this.folderService.currentParentFolderId,
        FolderPath:this.folderpath
      }

      this.folderService.createFolder(folderDTO).subscribe(response => {

        this.dialogRef.close();
        this.openSnackBar('Folder created successfully', 'bg-success');
        this.blockUI.stop();
      }, err => {
        this.dialogRef.close();
        this.openSnackBar('Error creating folder', 'bg-danger');
        this.blockUI.stop();
      })
  
    }
  
    openSnackBar(name: string, className: string) {
      this._snackBar.open(name, `X`, {
        duration: 3000,
        panelClass: [className],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  
  
  }