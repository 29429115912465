import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { subscribeOn } from 'rxjs/operators';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { DialogOverviewExampleDialog } from '../../card-body/card-body.component';
import { DialogData } from '../../interfaces/folder';



@Component({
    selector: 'rename-folder-dialog',
    templateUrl: './rename-folder-dialog.component.html',
    styleUrls: ['./rename-folder-dialog.component.css']
})

export class renameFolderDialog implements OnInit {

    @BlockUI('delete-file') blockUI: NgBlockUI;
    public extention: string = '';
    public fileData;
    public currentNameFormControl;

    public newNameFormControl = new FormControl('', [
        Validators.required,
    ]);

    public NameFormControl = new FormControl('', [
        Validators.required
    ]);

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private _snackBar: MatSnackBar, private folderService: FolderService) { }


    ngOnInit() {

        this.fileData = this.data;
        const splitedWords = this.fileData.Title.split('.');
        this.extention = '.' + splitedWords[splitedWords.length - 1];

        this.currentNameFormControl = new FormControl(this.fileData.Title, [
            Validators.required
        ]);

        this.NameFormControl = new FormControl('', [
            Validators.required
        ]);

    }

    onRenameFile() {


        let objPut = {
            ID: this.fileData.ID,
            Title: this.fileData.Title,
            ParentFolderID: this.fileData.ParentFolderID,
            BlobName: this.fileData.BlobName,
            BlobUrl: this.fileData.BlobUrl ? this.fileData.BlobUrl.replace(this.fileData.Title, this.newNameFormControl.value+this.extention) : null
        }
        if (this.fileData.TypeName == 'Document') {
            this.blockUI.start('Renaming File...');
            this.folderService.renameFile(objPut, this.fileData.FolderPath.slice(1) + this.newNameFormControl.value+this.extention, this.fileData.FolderPath.slice(1) + objPut.Title, this.newNameFormControl.value+this.extention).subscribe(response => {
                this.blockUI.stop();
                this.dialogRef.close();
                this.openSnackBar('Successfully renamed file', 'bg-success');
            }, err => {
                this.dialogRef.close();
                this.openSnackBar('Rename file error', 'bg-danger');
                this.blockUI.stop();
            })

        } else {
            this.blockUI.start('Renaming Folder...');
            this.folderService.renameFolder(objPut, this.fileData.FolderPath.slice(1) + this.newNameFormControl.value + '/', this.fileData.FolderPath.slice(1) + objPut.Title + '/', this.newNameFormControl.value).subscribe(response => {
                this.blockUI.stop();
                this.dialogRef.close();
                this.openSnackBar('Successfully renamed file', 'bg-success');
            }, err => {
                this.dialogRef.close();
                this.openSnackBar('Rename file error', 'bg-danger');
                this.blockUI.stop();
            })
        }




    }

    public onNoClick(): void {
        this.dialogRef.close();
        this.openSnackBar('Action cancelled', 'bg-primary');

    }


    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


}