import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { FilePermissionService } from 'src/app/services/Folder/permission.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-hero-detail',
    templateUrl: './previewFile.component.html',
    styleUrls: ['./previewFile.component.css']
})
export class PreviewFileComponent implements OnInit {

    public state: any = null;
    private parameterValue: any = null;


    constructor(public router: Router, private folderService: FolderService, private _activatedRoute: ActivatedRoute,private permissionService:FilePermissionService) {

        this._activatedRoute.params.subscribe(parameter => {
            this.parameterValue = parameter['id'];
        })
    }

    backHome(){
        this.router.navigateByUrl('/');
    }

    ngOnInit() {

        if (this.parameterValue) {

            this.permissionService.getUserDataItemPermissions(this.parameterValue,4).subscribe(resp=>{

                if(resp.body.HasRead){
                    this.folderService.getFileByID(this.parameterValue).subscribe(response => {

                        if (response.body != null) {
                            this.folderService.linkToPreview = {
                                link: response.body.BlobUrl + environment.staticTokenBlob,
                                element: response.body,
                                content: null
                            };
                            this.state = this.folderService.linkToPreview;
                        } else {
                            this.state = 'error';
                        }
        
                    });
                }else{
                    this.state = 'error';
                }

            },err=>{
                this.state = 'error';
            })

        } else {
            if (this.folderService.linkToPreview) {
                this.state = this.folderService.linkToPreview;
            } else {
                this.router.navigateByUrl("/dashboard")
            }

        }

    }

}