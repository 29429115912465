import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  WorkflowFormListDTO,
  workFlowListDTO,
} from 'src/app/components/shared/interfaces/workflow';
import { WorkflowService } from 'src/app/services/Workflow/workflow.service';
import { CreateWorkflowFormsDialog } from './createWorkflowForms/create-workflowForm.component';
import decode from 'jwt-decode';
import { DeleteWorkflowFormDialog } from './removeWorkflowForms/remove-workflow-form.component';

import { FormBuilderService } from 'src/app/services/FormBuilder/formBuilder.services';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
@Component({
  selector: 'selector-name',
  templateUrl: 'workflowForms.component.html',
  styleUrls: ['workflowForms.component.css'],
})
export class WorkflowFormsComponent implements OnInit {
  public globalData;
  public workflowList;
  public formList;
  public dataSource: MatTableDataSource<WorkflowFormListDTO>;
  public document: any = null;
  public spinner: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  displayedColumns: string[] = ['name', 'statusDescription', 'Actions'];

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private workflowService: WorkflowService,
    private _snackBar: MatSnackBar,
    private formService: FormBuilderService
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getWorkflowFormsList();
  }

  openSnackBar(name: string, className: string) {
    this._snackBar.open(name, `X`, {
      duration: 4000,
      panelClass: [className],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  private getWorkflowFormsList(): void {
    this.spinner = true;

    this.handleMultipleRequests();

    if (this.checkUserAdministrationRole()) {
      return this.getWorkflowFormsListAdmin();
    }

    const userID = this.getDecodedToken().UserGuidID;

    return this.getWorkflowFormsListUser(userID);
  }

  private handleMultipleRequests(): any {
    forkJoin([
      this.workflowService.getListOfWorkflows(),
      this.formService.getAllFormsCreated(),
    ]).subscribe((allResults) => {
      this.workflowList = allResults[0].body;
      this.formList = allResults[1].body;
    });
  }

  public checkUserAdministrationRole(): boolean {
    const isAdmin = this.getDecodedToken().IsAdmin;
    if (isAdmin == 'True') {
      return true;
    }
    return false;
  }

  private getDecodedToken(): any {
    const token = localStorage.getItem('AuthToken');
    const tokenPayload: any = decode(token);
    return tokenPayload;
  }

  private getWorkflowFormsListAdmin(): void {
    this.workflowService.getListOfWorkflowsForms().subscribe((resp) => {
      this.globalData = resp.body.id;
      this.spinner = false;
      this.dataSource = new MatTableDataSource<WorkflowFormListDTO>(
        this.globalData
      );
      this.cdr.detectChanges();
      this.ngAfterViewInit();
    });
  }

  private getWorkflowFormsListUser(userID): void {
    this.workflowService
      .getListOfWorkflowsFormsByUserID(userID)
      .subscribe((resp) => {
        this.globalData = resp.body.id;
        this.spinner = false;
        this.dataSource = new MatTableDataSource<WorkflowFormListDTO>(
          this.globalData
        );
        this.cdr.detectChanges();
        this.ngAfterViewInit();
      });
  }

  public addWorkflow(): void {
    const dialogRef = this.dialog.open(CreateWorkflowFormsDialog, {
      width: '500px',
      autoFocus: false,
      data: { workflowList: this.workflowList, formList: this.formList },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'submited') {
        this.getWorkflowFormsList();
      }
    });
  }

  public deleteWorkflow(element): void {
    const dialogRef = this.dialog.open(DeleteWorkflowFormDialog, {
      width: '600px',
      autoFocus: false,
      data: element,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'submited') {
        this.getWorkflowFormsList();
      } else {
      }
    });
  }

  public editWorkflow(element): void {
    this.router.navigateByUrl(this.router.url + '/view/' + element.id);
  }
  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  private filterNewTable(dataSorted): any {
    this.dataSource = new MatTableDataSource<WorkflowFormListDTO>(dataSorted);
    this.cdr.detectChanges();
    this.ngAfterViewInit();
  }
  public filterByStatus(event): any {
    if (event.value == 'NEW') {
      let dataSorted = this.globalData.filter(
        (x) => x.statusDescription == 'NEW'
      );
      this.filterNewTable(dataSorted);
    } else if (event.value == 'PROGRESS') {
      let dataSorted = this.globalData.filter(
        (x) => x.statusDescription == 'PROGRESS'
      );
      this.filterNewTable(dataSorted);
    } else if (event.value == 'COMPLETED') {
      let dataSorted = this.globalData.filter(
        (x) => x.statusDescription == 'COMPLETED'
      );
      this.filterNewTable(dataSorted);
    } else {
      this.filterNewTable(this.globalData);
    }
  }
}
