import { createHostListener, Route } from '@angular/compiler/src/core';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthGuardService } from 'src/app/services/Auth/auth-guard.service';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { environment } from 'src/environments/environment';
import { deleteFileDialog } from '../dialogs/delete-file-dialog/delete-file-dialog.component';
import { moveFileDialog } from '../dialogs/move-file-dialog/move-file-dialog.component';
import { renameFolderDialog } from '../dialogs/rename-folder-dialog/rename-folder-dialog.component';
import { ShareFileDialog } from '../dialogs/share-file-dialog/share-file-dialog.component';
import { DialogData, FileElement } from '../interfaces/folder';
import decode from 'jwt-decode';
import { FilePermissionService } from 'src/app/services/Folder/permission.service';
import { FilePropertiesDialog } from '../dialogs/file-properties-dialog/file-properties-dialog.component';
import {  MarkFileAsImportantDialog } from '../dialogs/mark-as-important/mark-as-important-dialog.component';

@Component({
  selector: 'app-card-body',
  templateUrl: './card-body.component.html',
  styleUrls: ['./card-body.component.css']
})
export class CardBodyComponent implements OnInit {

  @Output() listView: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  @Input() elementData: FileElement[];

  @Input() small_spinner: boolean; 

  displayedColumns: string[] = ['position', 'Title', 'BlobSize', 'CreatedDate','AsImportant', 'symbol'];

  private status: boolean;
  public dataSource2;
  private userID: string = "0";

  ngAfterViewInit() {
    this.dataSource2.paginator = this.paginator;
    this.dataSource2.sort = this.sort;
  }

  constructor(public dialog: MatDialog,
    private folderService: FolderService,
    private _snackBar: MatSnackBar,
    private route: Router,
    private auth: AuthGuardService,
    private permissionService: FilePermissionService) {

  }

  ngOnInit(): void {

    const token = localStorage.getItem('AuthToken');
    // decode the token to get its payload
    const tokenPayload: any = decode(token);

    this.userID = tokenPayload.UserId;

    if(tokenPayload.IsAdmin == 'True'){
      this.status=true;
    }else{
      this.status=false;
    }

  }


  public showModal(event):void {
    let elements = document.querySelectorAll('.smallmenu');
    elements.forEach(element => {
      let el = element as HTMLElement;
      el.style.display = "none";
    })
    event.target.nextElementSibling.style.display = "block";

  }

  public showFolder(element):void {
    this.folderService.currentParentFolderId = element.ID;
    this.folderService.getFilesByFolderID(element.ID).subscribe(data => {
      this.elementData = data.body;
      this.listView.emit(null);
      this.dataSource2 = new MatTableDataSource<FileElement>(this.elementData);
      this.ngAfterViewInit();
      
    })
  }

  public deleteFile(element): void {

    const dialogRef = this.dialog.open(deleteFileDialog, {
      width: '600px',
      data: element,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.listView.emit(null);
    });

  }
  public viewFile(element):void {

    this.folderService.linkToPreview = {
      link: element.BlobUrl + environment.staticTokenBlob,
      element: element,
      content: null
    };

    

    if (element.BlobType.includes('image')) {
      window.open(this.folderService.linkToPreview.link);
    } else {
      if (this.status)
        this.route.navigateByUrl('/admin/PreviewFile');
      else
        this.route.navigateByUrl('/user/PreviewFile');

    }
  }

  public downloadFile(element):void {
 
    this.folderService.downloadFile(element.BlobName).subscribe(resp => {
      console.log(resp.url);
    }, err => {
      window.open(err.url);
    })
  }

  public renameFile(element):void {

    element.FolderPath = this.folderService.currentFolderPath;
    const dialogRef = this.dialog.open(renameFolderDialog, {
      width: '600px',
      data: element,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.listView.emit(null);
    });
  }

  public fileDetails(element):void {


  }

  // method implementation for folder
  public deleteFolder(element):void {
    const dialogRef = this.dialog.open(deleteFileDialog, {
      width: '600px',
      data: element,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.listView.emit(null);
    });

  }

  public downloadFolder(element):void {

    this.openSnackBar('Folder is preparing to download', 'bg-warning')
    setTimeout(() => {
      this.openSnackBar('Operation initiated in background', 'bg-primary')
    }, 2000);
    let folderPath = this.folderService.currentFolderPath.slice(1) + element.Title + '/';
    let blobName = folderPath + element.Title + '.zip';


    this.folderService.downloadFolder(blobName, folderPath).subscribe(response => {
      console.log(response);
    }, err => {
      window.open(err.url);
      this.openSnackBar('Folder is downloading succesfully', 'bg-success')
    })

  }

  public renameFolder(element):void {

    element.FolderPath = this.folderService.currentFolderPath;
    const dialogRef = this.dialog.open(renameFolderDialog, {
      width: '600px',
      data: element,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.listView.emit(null);
    });

  }

  public folderDetails(element):void {

  }

  public shareFolder(element):void {

    element.FolderPath = this.folderService.currentFolderPath;

    if (this.status) {
      this.generateModalFolder(element);
    } else {
      if (element.UserID != parseInt(this.userID)) {
        this.permissionService.getUserDataItemPermissions(element.ID, parseInt(this.userID)).subscribe(resp => {

          if (resp.body.CanShare) {
            this.generateModalFolder(element);
          } else {
            this.openSnackBar("You dont have permission to share folder", 'bg-danger');
          }

        }, err => {
          this.openSnackBar("You dont have permission to share folder", 'bg-danger');
        })

      } else {
        this.generateModalFolder(element);
      }
    }
  }

  public FilePermissions(element): void {
    if (this.status) {
      this.route.navigateByUrl('/admin/UserFiles/Permissions/' + element.ID);
    } else {
      if (element.UserID != parseInt(this.userID)) {

        this.openSnackBar("You dont have access to permissons", 'bg-danger');

      } else {
        this.route.navigateByUrl('/user/UserFiles/Permissions/' + element.ID);
      }
    }
  }

  public MarkAsImportant(element):void {
    const dialogRef = this.dialog.open(MarkFileAsImportantDialog, {
      width: '600px',
      data: element,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.listView.emit(null);
    });

  }
  

  public fileProperties(element):void{
    const dialogRef = this.dialog.open(FilePropertiesDialog, {
      width: '600px',
      data: element,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.listView.emit(null);
    });
    
  }

  private generateModalFolder(element): void {
    const dialogRef = this.dialog.open(ShareFileDialog, {
      width: '800px',
      data: element,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.listView.emit(null);
    });

  }

  public shareFile(element):void {

    element.FolderPath = this.folderService.currentFolderPath;

    if (this.status) {
      this.generateModalFile(element);
    } else {
      if (element.UserID != parseInt(this.userID)) {


        this.permissionService.getUserDataItemPermissions(element.ID, parseInt(this.userID)).subscribe(resp => {

          if (resp.body.CanShare) {
            this.generateModalFile(element);
          } else {
            this.openSnackBar("You dont have permission to share file", 'bg-danger');
          }

        }, err => {
          this.openSnackBar("You dont have permission to share file", 'bg-danger');
        })

      } else {
        this.generateModalFile(element);

      }
    }

  }

  private generateModalFile(element) {
    const dialogRef = this.dialog.open(ShareFileDialog, {
      width: '800px',
      data: element,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.listView.emit(null);
    });
  }




  public moveFile(element):void {
    element.FolderPath = this.folderService.currentFolderPath;

    const dialogRef = this.dialog.open(moveFileDialog, {
      width: '600px',
      data: element,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.listView.emit(null);
    });
  }

  ngOnChanges() {

    this.dataSource2 = new MatTableDataSource<FileElement>(this.elementData);
    this.ngAfterViewInit();
  }
  openSnackBar(name: string, className: string) {
    this._snackBar.open(name, `X`, {
      duration: 3000,
      panelClass: [className],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}

export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmitData(): void {

    this.dialogRef.close();
  }

}