<div class="card-header text-white" style="background-color: #386CB9;" *blockUI="'refresh-view'">
    <div class="d-flex justify-content-between">
      <div class="left">
        <span class="mr-4" (click)="createFolder()" style="cursor: pointer;">
          <i class="fas fa-folder mr-1 "></i>
          New Folder
        </span>
        <span class="mr-4" (click)="uploadFile()" style="cursor: pointer;">
          <i class="fas fa-upload mr-1 "></i>
          Upload
        </span>
      
        <span class="mr-4" (click)="refreshView()" style="cursor: pointer;">
          <i class="fas fa-sync mr-1 " ></i>
          Refresh
        </span>
      </div>
      <div class="right">
  
        <span class="mr-3">
          <i [class]='classList' (click)="showList()" style="cursor: pointer;"></i>
        </span>
        <!-- <span>
          <i class="fas fa-info-circle "></i>
        </span> -->
      </div>
    </div>
    </div>