
<spinner *ngIf="spinner"></spinner>
<div *ngIf="!spinner" class="animate">
    
    <div class="btn bg-primary text-white ml-3  mt-3" (click)="goBack()">
        Back
    </div>
    <h1 class="display-4 p-3">File Permissions</h1>

    <div class="d-flex mb-4 ml-3">
        <div class="div-1">
            <div class="text-center">
                <div >
                    <i class="fas fa-folder fa-3x text-warning" *ngIf="documentFile.Type=='1'"></i>
                    <i class="fas fa-file fa-3x" *ngIf="documentFile.Type=='2'"></i>
                </div>
                <div>
                   <strong>{{documentFile.Title}}</strong> 
                </div>
            </div>
        </div>
    </div>
  

    <div class="card p-3 m-3 shadow-sm">

        <div class="card-body ">

            <div class="">
                <div class="d-flex justify-content-between">
                    <div>

                    </div>
                    <div>
                        <mat-form-field>
                            <mat-label>Filter by user name</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                        </mat-form-field>
                    </div>
                </div>
           
                <table mat-table [dataSource]="dataSource2" matSort>
        
             
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef> <i class="fas fa-shield-alt fa-2x  "></i> </th>
                        <td mat-cell *matCellDef="let element">
                            <div >
                                <div ><i class="fas fa-user-alt " style="cursor: pointer;"></i></div>
                            </div>
                          
                        </td>
                    </ng-container>
            
              
                    <ng-container matColumnDef="UserName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> User name </th>
                        <td mat-cell *matCellDef="let element">
                       {{element.Name}}
                        </td>
                    </ng-container>
            
                   
                    <ng-container matColumnDef="HasRead">
                        <th mat-header-cell *matHeaderCellDef > Read </th>
                        <td mat-cell *matCellDef="let element">
        
                            <label class="switch" style="margin-bottom: -0.5rem;">
                                <input type="checkbox" [checked]="element.HasRead" (change)="addPermission(element,'read')" >
                                <div class="slider round"></div>
                            </label>
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="HasWrite">
                        <th mat-header-cell *matHeaderCellDef> Write </th>
                        <td mat-cell *matCellDef="let element">
                            <label class="switch" style="margin-bottom: -0.5rem;">
                                <input type="checkbox" [checked]="element.HasWrite" (change)="addPermission(element,'write')">
                                <div class="slider round"></div>
                              </label> </td>
                    </ng-container>
                
                    <ng-container matColumnDef="CanShare">
                        <th mat-header-cell *matHeaderCellDef> Share </th>
                        <td mat-cell *matCellDef="let element">
                            <label class="switch" style="margin-bottom: -0.5rem;">
                                <input type="checkbox" [checked]="element.CanShare" (change)="addPermission(element,'share')">
                                <div class="slider round"></div>
                            </label>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="CanUpload" *ngIf="documentFile.Type=='1'">
                        <th mat-header-cell *matHeaderCellDef> Upload </th>
                        <td mat-cell *matCellDef="let element">
                            <label class="switch" style="margin-bottom: -0.5rem;">
                                <input type="checkbox" [checked]="element.CanUpload" (change)="addPermission(element,'upload')">
                                <div class="slider round"></div>
                            </label>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
                </table>
            
                <mat-paginator [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
            
            
            </div>
        </div>
    </div>
 

</div>


