import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthGuardService } from 'src/app/services/Auth/auth-guard.service';
import { UserService } from 'src/app/services/User/user.service';
import { ChangePasswordDTO, createUserDTO, UserInfo } from '../interfaces/user';

@Component({
    selector: 'forgot-pass',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
    public hide: boolean = true;
    public email: string;

    public updateUserForm = new FormGroup({
        email: new FormControl('', Validators.email),
        oldpassword: new FormControl(''),
        newpassword: new FormControl(''),
        repeatpassword: new FormControl('')
    });
    @BlockUI('update-user') blockUI: NgBlockUI;

    public myProfile: UserInfo = null;
    constructor(private userService: UserService, private _snackBar: MatSnackBar, private auth: AuthGuardService) {
        this.userService.getUserInfo().subscribe(resp => {
            this.myProfile = resp.body;
            this.updateUserForm = new FormGroup({
                email: new FormControl({ value: this.myProfile.Email, disabled: true }),
                oldpassword: new FormControl(''),
                newpassword: new FormControl(''),
                repeatpassword: new FormControl('')
            })
        });
    }


    ngOnInit() {

    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
        });
    }

    updatePassword() {
        this.blockUI.start('Updating user password...');
        if (this.updateUserForm.value.newpassword === this.updateUserForm.value.repeatpassword) {
            let changePasswordDTO: ChangePasswordDTO = {
                NewPassword: this.updateUserForm.value.newpassword,
                OldPassword: this.updateUserForm.value.oldpassword,
                UserID: this.myProfile.UserID

            }
            this.userService.changePassword(changePasswordDTO).subscribe(resp => {
                this.blockUI.stop();
                this.openSnackBar('Password changed successfully', 'bg-success');
            }, err => {

                this.blockUI.stop();
                this.openSnackBar('Error changing password', 'bg-danger');
            });
        }
        else {
            alert('Please check your password!');
        }

    }

}
