<div class = "background-white">
<div class="  mt-3 ml-3 btn bg-primary text-white" (click)="goBack()">
    <i class="fas fa-chevron-left mr-2"></i> Back
</div>
<h3 class="p-3 display-4 mt-2">Form Builder</h3>

<div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
        <mat-horizontal-stepper linear>
            <mat-step label="Create Instance">
                <div class="row title-stepper">
                    <div class="col-md-4"></div>

                    <div class="col-md-4">
                        <h4>Create Instance</h4>
                    </div>

                    <div class="col-md-4"></div>
                </div><br />

                <div class="row">
                    <div class="col-md-2"></div>

                    <div class="col-md-4">
                        <mat-radio-button value="Create" (change)="radioChange($event)">Create an Instance Name
                        </mat-radio-button>
                    </div>

                    <div class="col-md-4">
                        <mat-radio-button value="Choose" (change)="radioChange($event)"> Choose an Instance Name
                        </mat-radio-button>
                    </div>

                    <div class="col-md-2"></div>

                </div>

                <div class="new-instance" *ngIf="this.selectedRadioButton == true">
                    <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                        <mat-label>Add instance name </mat-label>
                        <input matInput [formControl]="formname">
                        <mat-error *ngIf="formname.invalid">Instance name is required</mat-error>
                    </mat-form-field><br />

                    <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                        <mat-label>Give an description...</mat-label>
                        <textarea matInput [formControl]="description" rows="7"></textarea>
                        <mat-error *ngIf="description.invalid">Description is required</mat-error>
                    </mat-form-field><br />

                    <mat-form-field appearance="fill" style="display: block;">
                        <mat-label>Choose parent instance </mat-label>
                        <mat-select [formControl]="parent_instance">
                            <mat-option *ngFor="let parent of parents" [value]="parent.instanceId">
                                {{parent.instanceName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="parent_instance.invalid"> Parent instance is required</mat-error>
                        <mat-error></mat-error>
                    </mat-form-field><br>

                    <!-- choose Instance name -->
                    <!-- <mat-form-field appearance="fill" style="display: block;" >
                        <mat-label>Enter product type </mat-label>
                        <mat-select>
                            <mat-option>test</mat-option>
                        </mat-select>
                        <mat-error *ngIf="product_type.invalid">Product type is required</mat-error>
                    </mat-form-field><br> -->

                    <div class="row px-3">
                        <div class="col-md-10"></div>
                        <div class="col-md-2">
                            <button mat-button matStepperNext type="button" class="btn bg-primary text-white"
                                [disabled]=" !formname.valid || !description.valid || !parent_instance.valid"
                                (click)="submitInstance()">
                                Next <i class="fas fa-caret-right"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="exist" *ngIf="this.selectedRadioButton == false">
                    <h2>Please go to the next step Design Form!</h2>
                </div>
            </mat-step>

            <mat-step label="Design Form">
                <h4 class="title-stepper">Design Form</h4><br />
                <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>Choose the Instance Name </mat-label>
                    <input matInput [formControl]="forminstance" [value]="this.instanceCreatedName">
                    <mat-error *ngIf="forminstance.invalid">Instance name is required</mat-error>
                </mat-form-field><br />

                <!-- <mat-label>Choose the Instance Name</mat-label>
                    <mat-select [formControl]="forminstance">
                        <mat-option  *ngFor="let dep of formInstance" [value]="dep.instanceId">{{this.instanceCreatedName}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="forminstance.invalid">Form Instance is required</mat-error> -->

                <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>Add form name </mat-label>
                    <input matInput [formControl]="formName">
                    <mat-error *ngIf="formname.invalid">Form Name is required</mat-error>
                </mat-form-field><br />

                <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
                    <mat-label>Give an description...</mat-label>
                    <textarea matInput [formControl]="formDescription" rows="7"></textarea>
                    <mat-error *ngIf="description.invalid">Description is required</mat-error>
                </mat-form-field><br />

                <mat-form-field appearance="fill" style="display: block;">
                    <mat-label>Choose parent form </mat-label>
                    <mat-select [formControl]="parent_form">
                        <mat-option *ngFor="let form of forms" [value]="form.formId">{{form.formName}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="parent_form.invalid"> Parent form is required</mat-error>
                    <mat-error></mat-error>
                </mat-form-field><br>

                <div class="p-3">
                    <form-builder [form]="form" (change)="onChange($event)"></form-builder>
                </div>

                <div class="row px-3">

                    <div class="col-md-10">
                        <button mat-button matStepperPrevious type="button"
                            class="btn bg-primary text-white customFlexContainer" (click)="refresh()"><i
                                class="fas fa-caret-left"></i> Back</button>
                    </div>

                    <div class="col-md-2">
                        <button mat-button matStepperNext type="button" class="btn bg-primary text-white"
                            [disabled]="!formName.valid || !formDescription.valid || !parent_form.valid"
                            (click)="submitForm()">Next <i class="fas fa-caret-right"></i></button>
                    </div>
                </div>
            </mat-step>

            <mat-step label="Form Roles">
                <div class="row title-stepper">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h4>Form Roles</h4>
                    </div>
                    <div class="col-md-4"></div>
                </div><br />

                <div class="row">
                    
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                            </div>
                            <div>
                                <button class="btn bg-primary text-white" (click)="addNewRole()">
                                    <i class="fas fa-plus mr-2"></i> Add Role
                                </button>
                            </div>
                        </div>
                        <div id="add-new-role" style="display: none;">
                            <mat-form-field class="example-full-width" style="display:block;"appearance="fill">
                                <mat-label>Add role name </mat-label>
                                <input matInput [formControl]="role_name">
                                <mat-error *ngIf="role_name.invalid">Role name is required</mat-error>
                            </mat-form-field><br />
                                <div class="col-md-8"></div>
                                <div class="col-md-4">
                                    <button mat-button type="button"
                                        class="btn bg-primary text-white customFlexContainer"
                                        (click)="closeaddrole()"><i class="fas fa-caret-left"></i> Cancel</button>
                                    <button mat-button type="button" class="btn bg-primary text-white"
                                        [disabled]="!role_name.valid" (click)="submitRole()"> Next <i
                                            class="fas fa-caret-right"></i></button>
                                </div>

                        </div>

                        <div class="exist">
                            <mat-form-field appearance="fill" style="display: block;">
                                <mat-label>Choose Roles from list </mat-label>
                                <mat-select multiple [formControl]="formRole">
                                    <mat-option *ngFor="let role of roles" [value]="role">{{role.roleName}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="product_type.invalid">Roles is required</mat-error>
                            </mat-form-field><br>
                            <div class="row px-3">
                                <div class="col-md-10">
                                    <button mat-button matStepperPrevious type="button"
                                        class="btn bg-primary text-white customFlexContainer" (click)="refresh()"><i
                                            class="fas fa-caret-left"></i> Back</button>

                                </div>
                                <div class="col-md-2">
                                    <button mat-button matStepperNext type="button" class="btn bg-primary text-white"
                                        [disabled]="!formRole.valid" (click)="chooseRoleFromExisting()">Next <i
                                            class="fas fa-caret-right"></i></button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- <div class="col-md-2"></div>

                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                        <mat-radio-button value="Create" (change)="rolesRadioChange($event)">Create new Form Roles
                        </mat-radio-button>
                    </div>
                    <div class="col-md-4">
                        <mat-radio-button value="Choose" (change)="rolesRadioChange($event)"> Choose from Existing Roles
                            List</mat-radio-button>
                    </div>
                    <div class="col-md-2"></div> -->
                </div>

                <!-- <div class="new-instance" *ngIf="this.roleSelectedRadioButton == true">
                    <mat-form-field class=" add-new example-full-width" style="display: block;" appearance="fill">
                        <mat-label>Add role name </mat-label>
                        <input matInput [formControl]="role_name">
                        <mat-error *ngIf="role_name.invalid">Role name is required</mat-error>
                    </mat-form-field><br />
                    <div class="row px-3">
                        <div class="col-md-10">
                            <button mat-button matStepperPrevious type="button"  class="btn bg-primary text-white customFlexContainer" (click)="refresh()"><i class="fas fa-caret-left"></i> Back</button>

                        </div>

                        <div class="col-md-2">
                            <button mat-button  type="button"  class="btn bg-primary text-white" [disabled]="!role_name.valid"
                                (click)="submitRole()"> Next <i class="fas fa-caret-right"></i></button>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="exist" *ngIf="this.roleSelectedRadioButton == false">
                    <mat-form-field appearance="fill" style="display: block;">
                        <mat-label>Choose Roles from list </mat-label>
                        <mat-select multiple [formControl]="formRole">
                            <mat-option *ngFor="let role of roles" [value]="role">{{role.roleName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="product_type.invalid">Roles is required</mat-error>
                    </mat-form-field><br>
                    <div class="row px-3">
                        <div class="col-md-10">
                            <button mat-button matStepperPrevious type="button"  class="btn bg-primary text-white customFlexContainer" (click)="refresh()"><i class="fas fa-caret-left"></i> Back</button>

                        </div>
                        <div class="col-md-2">
                            <button mat-button matStepperNext type="button"  class="btn bg-primary text-white" [disabled]="!formRole.valid"
                                (click)="chooseRoleFromExisting()">Next <i class="fas fa-caret-right"></i></button>
                        </div>
                    </div>
                </div> -->

            </mat-step>

            <mat-step label="Add User">
                <div class="row title-stepper">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h4>Add User</h4>
                    </div>
                    <div class="col-md-4"></div>
                </div><br />
                <div class="card-body ">

                    <div class="d-flex justify-content-between align-items-center"></div>
                    <table mat-table [dataSource]="dataSource2" matSort>


                        <ng-container matColumnDef="position">
                            <th mat-header-cell *matHeaderCellDef> <i class="fas fa-shield-alt fa-2x  "></i> </th>
                            <td mat-cell *matCellDef="let  users ">
                                <div>
                                    <div><i class="fas fa-user-alt " style="cursor: pointer;"></i></div>
                                </div>

                            </td>
                        </ng-container>


                        <ng-container matColumnDef="UserName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> User name </th>
                            <td mat-cell *matCellDef="let user">
                                {{user.firstname}} {{user.lastname}}
                            </td>
                        </ng-container>

                        <ng-container *ngFor="let role of addedRoles" matColumnDef="{{role.roleId}}">
                            <th mat-header-cell *matHeaderCellDef>{{role.roleName}}</th>
                            <td mat-cell *matCellDef="let user">
                                <label class="switch" style="margin-bottom: -0.5rem;">
                                    <input type="checkbox" [checked]="user.addedRoles" (change)="addRole(user, role)">
                                    <div class="slider round"></div>
                                </label>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5,10,50]"></mat-paginator>
                </div>
                <div class="row px-3">
                    <div class="col-md-10">
                        <button mat-button matStepperPrevious type="button"
                            class="btn bg-primary text-white customFlexContainer" (click)="refresh()"><i
                                class="fas fa-caret-left"></i> Back</button>
                    </div>

                    <div class="col-md-2">
                        <button mat-button matStepperNext type="button" class="btn bg-primary text-white">Next <i
                                class="fas fa-caret-right"></i></button>
                    </div>

                </div>
            </mat-step>

            <mat-step label="Preview Form">
                <h4 class="title-stepper">Preview Form</h4><br />

                <formio *ngIf="formJsonSrc" [form]="formJsonSrc"></formio>

                <div class="row px-3">

                    <div class="col-md-10"></div>

                    <div class="col-md-2">
                        <button mat-button matStepperPrevious type="button"
                            class="btn bg-primary text-white customFlexContainer" (click)="refresh()"><i
                                class="fas fa-caret-left"></i> Back</button>
                        <button mat-button matStepperNext type="button" class="btn bg-primary text-white"
                            (click)="goBack()"> Finish<i class="fa fa-angle-down"></i></button>
                    </div>

                    <div class="col-md-2"></div>

                </div>
            </mat-step>

        </mat-horizontal-stepper>

    </div>
    <div class="col-md-1">

    </div>
</div>

<!-- <div class="row px-3">
    <div class="col-md-10">
        
    </div>
    <div class="col-md-2">
        <div class="btn btn-primary btn-block" (click)="createForm()">
            Generate Form <i class="fas fa-cogs ml-2"></i>
       </div>
    </div>
</div> -->
<!-- <div class="row mt-3">
    <div class="col-md-2">

    </div>
    <div class="col-md-10">
        {{formJson}}
    </div>
</div> -->
</div>