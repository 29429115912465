import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SimpleUserDashboardComponent } from '../../dashboards/simple-user-dashboard/simple-user-dashboard.component';
import { AuthGuardService } from 'src/app/services/Auth/auth-guard.service';
import { SimpleUserFilesComponent } from './SimpleUserFiles/user-UserFiles.component';
import { SimpleUserSharedWithMeComponent } from './SimpleUserSharedWithMe/user-SharedWithMe.component';
import { SimpleUserArchiveComponent } from './SimpleUserArchive/user-Archive.component';
import { UserWidgetsComponent } from '../../dashboards/simple-user-dashboard/user-widgets/user-widgets.component';
import { SharedModule } from '../../shared/Sharedmodule/shared.module';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { BlockUIModule } from 'ng-block-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { myProfileComponent } from '../../shared/myProfile/myprofile.component';
import { PreviewFileComponent } from '../../shared/PreviewFile/previewFile.component';
import { PasswordResetComponent } from '../../shared/password-reset/password-reset.component';
import { UserPermissionComponent } from './Permission/UserPermission.component';
import { WorkflowFormsComponent } from '../../Administrator/administrator/WorkflowForms/workflowForms.component';
import { DisplayWorkFlowFormComponent } from '../../Administrator/administrator/WorkflowForms/ViewWorkflowForm/display-workflowForm.component';
import { FormBuilderComponent } from '../../Administrator/Form-builder/form-builder.component';
import { EditForm } from '../../Administrator/administrator/MyForms/edit-form.component';
import { MyFormComponent } from '../../Administrator/administrator/MyForms/my-form.component';
import { CompletedForm } from '../../Administrator/administrator/MyForms/completed-form.component';
import { PreviewFormDialog } from '../../Administrator/administrator/MyForms/preview-form.component';
import { SharedFormComponent } from '../../Administrator/administrator/SharedForms/shared-form.component';

const routes: Routes = [
  { path:'',redirectTo:'Dashboard',pathMatch:'full'},
  { path: 'Dashboard', component: SimpleUserDashboardComponent},

  // { path: 'UserFiles', redirectTo: 'UserFiles/', pathMatch: 'full' , canActivate: [AuthGuardService]},

  {
    path: 'UserFiles',
    children: [
      { path: '', redirectTo: 'Manage', pathMatch: 'full' },
      {
        path: 'Manage', component: SimpleUserFilesComponent,
      },
      { path: 'Permissions', redirectTo: 'Permissions/', pathMatch: 'full',  },
      {
        path: 'Permissions/:id', component: UserPermissionComponent,
      }
    ]
  },


  // { path: 'UserFiles/:id', component: SimpleUserFilesComponent,canActivate:[AuthGuardService]},
  { path: 'profile', component: myProfileComponent,},
  { path: 'workflow-form-instances', component: WorkflowFormsComponent,  }, 
  { path: 'workflow-form-instances/view/:id', component: DisplayWorkFlowFormComponent, }, 
  { path: 'passwordreset', component: PasswordResetComponent, },
  { path: 'SharedWithMe', component: SimpleUserSharedWithMeComponent,},
  { path: 'Archive', component: SimpleUserArchiveComponent,},
  { path: 'PreviewFile',redirectTo: 'PreviewFile/', pathMatch: 'full' , },
  { path: 'PreviewFile/:id', component: PreviewFileComponent, },
  { path: 'EditForm/:id', component: EditForm,  },
  { path: 'PreviewFormDialog', component: PreviewFormDialog,  },
  { path: 'CompletedForm/:id', component: CompletedForm,  },
  { path: 'FormCompletedDialog', redirectTo: 'FormCompletedDialog/', pathMatch: 'full',   },
  { path: 'FormBuilder', component: FormBuilderComponent,   },
  { path: 'MyForm', component: MyFormComponent,   },
  { path: 'SharedForm', component: SharedFormComponent,   },

];

@NgModule({
  declarations: [
    SimpleUserDashboardComponent,
    SimpleUserFilesComponent,
    SimpleUserSharedWithMeComponent,
    SimpleUserArchiveComponent,
    UserWidgetsComponent,
    UserPermissionComponent,

  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    MatTreeModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    BlockUIModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    ReactiveFormsModule,
  ]
})
export class SimpleUserModule { }
