import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DialogOverviewExampleDialog } from 'src/app/components/shared/card-body/card-body.component';

@Component({
    selector: 'preview-form',
    templateUrl: './preview-form.component.html',
    styleUrls: ['./preview-form.component.css']
  })

  export class PreviewFormDialog implements OnInit {
    @BlockUI('privew-form') blockUI: NgBlockUI;
    public formJson = new FormControl('');
    public formJsonSrc: any;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    constructor(
      public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
      @Inject(MAT_DIALOG_DATA) public data, private _snackBar: MatSnackBar) {


        this.formJson = new FormControl(data.formJsondata, [
            Validators.required
        ]);

    }
    

    
      public ngOnInit(): void {
        this.formJsonSrc = JSON.parse(this.formJson.value);
    }

    onNoClick() {
      this.dialogRef.close('close');
      this.openSnackBar('Action cancelled', 'bg-primary');
  }
  openSnackBar(name: string, className: string) {
    this._snackBar.open(name, `X`, {
        duration: 3000,
        panelClass: [className],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
    });
}
}
