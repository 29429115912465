import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AddPermissionFileDialog } from 'src/app/components/shared/dialogs/add-permission-dialog/add-permission-dialog.component';
import { PermissionElement } from 'src/app/components/shared/interfaces/folder';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { FilePermissionService } from 'src/app/services/Folder/permission.service';

@Component({
    selector: 'app-hero-detail',
    templateUrl: './admin-permissions.component.html',
    styleUrls: ['./admin-permissions.component.css']
})
export class AdminPermissionComponent implements OnInit {

    private elementData: PermissionElement[] = [];
    public dataSource2;
    private fileID: string = null;
    public documentFile: any = null;
    public spinner: boolean = true;
    public userList: Array<any> = [];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    displayedColumns: string[] = ['position', 'UserName', 'HasRead', 'HasWrite', 'CanShare'];

    constructor(public dialog: MatDialog, public router: Router, private folderService: FolderService, private _activatedRoute: ActivatedRoute, private permissionService: FilePermissionService) {
        this._activatedRoute.params.subscribe(parameter => {
            this.fileID = parameter['id'];
        })
    }

    ngAfterViewInit() {
        this.dataSource2.paginator = this.paginator;
        this.dataSource2.sort = this.sort;
    }

    ngOnInit() {

        this.folderService.getFolderAndDocumentByID(this.fileID).subscribe(response => {
            this.documentFile = response.body;
            if (this.documentFile.Type == '1') {
                this.displayedColumns = ['position', 'UserName', 'HasRead', 'HasWrite', 'CanShare', 'CanUpload'];
            }

            this.spinner = false;
            this.getUserList();
        });


    }

    getUserList() {
        this.permissionService.getFileUserListAndPermissions(this.fileID).subscribe(Response => {
            this.userList = Response.body;
            this.elementData = this.userList;
            this.dataSource2 = new MatTableDataSource<PermissionElement>(this.elementData);
        })
           
    }

    goBack() {
        this.router.navigateByUrl('/admin/UserFiles/Manage');
    }


    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource2.filter = filterValue.trim().toLowerCase();
    }
    addPermission(element, permission) {

        let objToSend = {
            element,
            permission,
            action: true
        }

        if (permission == 'read') {
            if (element.HasRead) {
                objToSend.action = false;
            } else {
                objToSend.action = true;
            }
        } else
            if (permission == 'write') {
                if (element.HasWrite) {
                    objToSend.action = false;
                } else {
                    objToSend.action = true;
                }
            } else
                if (permission == 'share') {
                    if (element.CanShare) {
                        objToSend.action = false;
                    } else {
                        objToSend.action = true;
                    }
                }
                else
                    if (permission == 'upload') {
                        if (element.CanUpload) {
                            objToSend.action = false;
                        } else {
                            objToSend.action = true;
                        }
                    }

        const dialogRef = this.dialog.open(AddPermissionFileDialog, {
            width: '600px',
            data: objToSend,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
           this.getUserList();
        });

    }

}