// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AdalService } from 'adal-angular4';


@Injectable()
export class AuthService {

  // @Output() checkAuthentication: EventEmitter<any> = new EventEmitter();
  // @Output() name: EventEmitter<any> = new EventEmitter();
  // @Output() isAdmin: EventEmitter<boolean> = new EventEmitter();

  public roleType: string;
  constructor(
    private adalSvc: AdalService) {

  }

  public isAuthenticated(): boolean {

    const helper = new JwtHelperService();

    const token = this.adalSvc.userInfo.token;

    if (token) return !helper.isTokenExpired(token);

    return false;

  }


}