<div *blockUI="'delete-file'">
    <h1 mat-dialog-title *ngIf="fileData.TypeName=='Document'" style="font-weight: 350;font-size: 30px;">Rename file  </h1>
    <h1 mat-dialog-title *ngIf="fileData.TypeName=='Folder'" style="font-weight: 350;font-size: 30px;">Rename Folder  </h1>

    <div mat-dialog-content>
        <form class="example-form">
            <mat-form-field class="example-full-width" style="display: block;">
              <mat-label>Current name</mat-label>
              <input matInput [formControl]="currentNameFormControl" readonly >
            </mat-form-field>
          
            <mat-form-field class="example-full-width" style="display: block;">
              <mat-label>New name</mat-label>
              <input matInput placeholder="folder 1..." [formControl]="newNameFormControl">
              <span class="extention">{{extention}}</span>
            </mat-form-field>
          </form>
    </div>

    <mat-dialog-actions align="end" class="mt-2">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button mat-flat-button color="primary" [disabled]="!newNameFormControl.valid" (click)="onRenameFile()">Submit</button>
    </mat-dialog-actions>


</div>