import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/User/user.service';
import { ChangePasswordDTO, UserInfo } from '../interfaces/user';

@Component({
    selector: 'forgot-pass',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    private uniquecode :string;
    public hide: boolean = true;
    public myProfile: UserInfo = null;
    constructor(private userService: UserService,private _snackBar: MatSnackBar,private route: ActivatedRoute,private router: Router) {
        this.uniquecode = this.route.snapshot.params['id'];

    }

    public updateUserForm = new FormGroup({
        newpassword: new FormControl(''),
        repeatpassword: new FormControl('')
    });


    ngOnInit() {

    }

    openSnackBar(name: string, className: string) {
        this._snackBar.open(name, `X`, {
            duration: 3000,
            panelClass: [className],
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
        });
    }

    updatePassword() {
       
        if (this.updateUserForm.value.newpassword === this.updateUserForm.value.repeatpassword) {
            let changePasswordDTO:ChangePasswordDTO = {
                NewPassword: this.updateUserForm.value.newpassword
            }
            this.userService.resetPassword(changePasswordDTO,this.uniquecode).subscribe(resp => {
       
                this.openSnackBar('Password reseted successfully', 'bg-success');
                this.router.navigateByUrl('/login');
                
            }, err => {

                this.openSnackBar('Error resating password', 'bg-danger');
            });
        }
        else {
            alert('Please check your password!');
        }

    }


}
