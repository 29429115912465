import { Component, ViewChild, HostListener, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { SearchFileDialog } from 'src/app/components/shared/dialogs/searched-file-dialog/searched-file-dialog.component';
import { FileElement, FolderNode } from 'src/app/components/shared/interfaces/folder';
import { FolderService } from 'src/app/services/Folder/folder.service';

@Component({
  selector: 'app-admin-userfiles',
  templateUrl: './admin-UserFiles.component.html',
  styleUrls: ['./admin-UserFiles.component.css']
})
export class AdminUserFilesComponent implements OnInit {

  private foundFiles:Array<any>=[];
  public spinner:boolean=true;
  public spinner_small: boolean = true;


  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    let exclude = event.target as HTMLElement;

    if (!exclude.classList.contains('showlittlemenu')) {
      let elements = document.querySelectorAll('.smallmenu');
      elements.forEach(element => {
        let el = element as HTMLElement;
        el.style.display = "none";
      });
    }
  }

  public searchFormControl = new FormControl('', [
    Validators.required,
  ]);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public element_data: FileElement[] = [];
  public breadcrumElements: Array<any> = [];
  public tree_data: FolderNode[] = [];

  constructor(private folderService: FolderService,public dialog: MatDialog,private _snackBar: MatSnackBar,private _activatedRoute:ActivatedRoute) {
    this._activatedRoute.params.subscribe(parameter => {
      if(parameter['id'])
      this.folderService.currentParentFolderId = parameter['id'];
  })
  }

  ngOnInit() {
    this.getNodeList();
    this.getFileList();
  }
  onRefresh(event) {
    this.getNodeList();
  }
  onRefreshUi(event) {
    this.getNodeList();
    this.getFileList();
  }
  onViewFiles(event) {
    this.getFileList();
  }
  getNodeList() {
    this.folderService.getFolderRoot().subscribe(data => {
      this.tree_data = data.body;
      this.spinner_small = false;
    })
  }
  getFileList() {
    this.spinner_small = true;
    this.folderService.getFilesByFolderID(this.folderService.currentParentFolderId).subscribe(data => {
      this.element_data = data.body;
      this.spinner_small = false;
    })

    this.folderService.getBreadcrumElements(this.folderService.currentParentFolderId).subscribe(data => {
      this.breadcrumElements = data.body;
      let filepath = '';
      data.body.forEach(el => {
        filepath = filepath + '/' + el.Title
      })
      this.folderService.currentFolderPath = filepath + '/';
    }, err => {
      this.breadcrumElements = [];
      this.folderService.currentFolderPath = null;
    })
    
  }

  searchForFile() {
    if(this.searchFormControl.value==''){
      alert('Please enter a text to search');
    }else{
      this.folderService.getFoundFiles(this.searchFormControl.value).subscribe(data=>{
        this.foundFiles=data.value;

        if(this.foundFiles.length==0){
          this.openSnackBar('No files found, search again!','bg-danger');
        }else{
          this.openSnackBar(`Successfully found ${this.foundFiles.length} files`,'bg-success');
          const dialogRef = this.dialog.open(SearchFileDialog, {
            width: '900px',
            data:this.foundFiles
          });
      
        }
     
      })
  
    }

  }

  openSnackBar(name: string, className: string) {
    this._snackBar.open(name, `X`, {
      duration: 3000,
      panelClass: [className],
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }



}



