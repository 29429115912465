import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FolderService } from 'src/app/services/Folder/folder.service';
import { createFolderDialog } from '../dialogs/create-folder-dialog';
import { UploadFileDialog } from '../dialogs/upload-file-dialog/upload-file-dialog';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.css']
})
export class CardHeaderComponent implements OnInit {

  public classList:string="fas fa-list";
  public isList:boolean=true;
  @BlockUI('refresh-view') blockUI: NgBlockUI;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() refreshui: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog, private folderService: FolderService) { }

  ngOnInit(): void {
    
  }

  public createFolder(): void {
    const dialogRef = this.dialog.open(createFolderDialog, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshui.emit(null);
    });

  }

  public uploadFile(): void {

    const dialogRef = this.dialog.open(UploadFileDialog, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshui.emit(null);
    });

  }
  public refreshView(): void {

    this.blockUI.start('Refreshing view...');
    setTimeout(() => {
    
      this.folderService.currentParentFolderId = 766;
      this.folderService.currentFolderPath=null;
      this.refreshui.emit(null);
      this.blockUI.stop();
    }, 1500);

  }
  public showList():void{
    this.isList=!this.isList;
     let tree = document.querySelector('#tree-list') as HTMLElement;
     let table = document.querySelector('#table-list') as HTMLElement;
     if(this.isList === true){
       tree.style.display="block";
       table.className="col-md-10";
       this.classList="fas fa-list"
     }else{
       tree.style.display="none";
       table.className="col-md-12";
       this.classList="fas fa-table"
     }
    
  }

}






