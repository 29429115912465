<div *blockUI="'upload-file'">

    <h1 mat-dialog-title style="font-weight: 350;font-size: 30px;">Move file </h1>
    <div mat-dialog-content class="my-3">
        <div class="text-center">
            <div class="mb-2">
                <i class="fas fa-folder fa-3x text-warning" *ngIf="fileData.TypeName=='Folder'"></i>
                <i class="fas fa-file fa-3x" *ngIf="fileData.TypeName=='Document'"></i>
            </div>
            <div>
                {{fileData.Title}}
            </div>
        </div>

      

        <mat-form-field appearance="fill" style="width: 100%;" class="my-3">
            <mat-label>{{label}}</mat-label>
            <mat-select>
                <mat-option disabled>Please select an item from below</mat-option>

                <app-tree-view [treeData]="tree_data" [moveFile]="true" (selectedNode)="newLabel($event)"> </app-tree-view>
            </mat-select>
          </mat-form-field>
          

    </div>
    <mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button mat-flat-button color="primary">Move file<i class="ml-2 fas fa-copy"></i></button>
    </mat-dialog-actions>

</div>