import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AdalService } from 'adal-angular4';
import decode from 'jwt-decode';
import { UserService } from '../User/user.service';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  
  @Output() checkAuthentication: EventEmitter<any> = new EventEmitter();
  @Output() name: EventEmitter<any> = new EventEmitter();
  @Output() email: EventEmitter<any> = new EventEmitter();
  @Output() isAdmin: EventEmitter<boolean> = new EventEmitter();

  public roleType: string;
  constructor(public auth: AuthService, public router: Router,private userService: UserService , private adalSvc: AdalService){

  }
  canActivate(): boolean {


    if (!this.auth.isAuthenticated()) {

      this.router.navigateByUrl('/login');
      this.checkAuthentication.emit(false);
      return false;
    }else{


      const token = localStorage.getItem('AuthToken');
      if(token != null){
        const tokenPayload: any = decode(token);

        this.name.emit(tokenPayload.GivenName);
        this.email.emit(tokenPayload.Email);

        let isadminrefactor:boolean=true;
        if(tokenPayload.IsAdmin == 'True'){
          isadminrefactor=true;
        }else{
          isadminrefactor=false;
        }
        this.isAdmin.emit(isadminrefactor);
        if (isadminrefactor) {
                  this.roleType = 'admin';
                } else {
                  this.roleType = 'user';
         }
      }
      return true;
    }
  
  }

  
}