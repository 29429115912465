<h3 class="p-3 display-4">Manage Users</h3>

<spinner *ngIf="spinner"></spinner>
<div *ngIf="!spinner" class="animate">
    <div class="card p-3 m-3 shadow-sm">
        <div class="card-body ">
            <div class="">
                <div class="d-flex justify-content-between align-items-center">

                    <div>
                        <mat-form-field style="width: 300px;" class="mr-3">
                            <mat-label>Filter by user name</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                        </mat-form-field>
                        <mat-form-field class="ml-3">

                            <mat-label>Filter by status</mat-label>
                            <mat-select (selectionChange)="filterByStatus($event)">
                                <mat-option value="all">All</mat-option>
                                <mat-option value="active">Active</mat-option>
                                <mat-option value="dissabled">InActive</mat-option>

                            </mat-select>

                        </mat-form-field>
                    </div>
                    <div>
                        <button class="btn bg-primary text-white" (click)="addUser()"><i class="fas fa-plus"></i> Add
                            user</button>

                            <button class="btn bg-primary text-white ml-3" (click)="syncUsers()"><i class="fas fa-sync-alt mr-1"></i> Sync
                                Users</button>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="UserID">
                        <th mat-header-cell *matHeaderCellDef> <i class="fas fa-user-alt fa-2x  "></i> </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div>
                                {{i+1}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> User name </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.Name}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef> Email </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.Email}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="IsAdministrator">
                        <th mat-header-cell *matHeaderCellDef> Roles </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.RoleName}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef> Position </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.Position}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="telephone">
                        <th mat-header-cell *matHeaderCellDef> Telephone </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.Telephone}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="DepartmentName">
                        <th mat-header-cell *matHeaderCellDef > Department Name </th>
                        <td mat-cell *matCellDef="let element">
                         {{element.DepartmentName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let element">
                                <span *ngIf="!element.IsActive" class="badge badge-danger p-1">InActive</span>
                                <span *ngIf="element.IsActive" class="badge badge-success p-1">Active</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button style="color:  #386CB9;" (click)="editUser(element)" title="edit">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button *ngIf="element.IsActive" mat-icon-button style="color: #386CB9;" title="change status"
                                (click)="deleteUser(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button *ngIf="!element.IsActive" mat-icon-button style="color: #386CB9;" title="change status"
                            (click)="deleteUser(element)">
                            <mat-icon>add</mat-icon>
                        </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10,20,50]"></mat-paginator>

            </div>
        </div>
    </div>


</div>