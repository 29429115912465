import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  CanLoad,
  Route,
  UrlSegment,
} from '@angular/router';
import { UserService } from '../User/user.service';
import { AuthService } from './auth.service';
import decode from 'jwt-decode';
import { AdalService } from 'adal-angular4';
import { Observable } from 'rxjs/internal/Observable';
@Injectable()
export class RoleGuardService implements CanLoad {
  roleType: string;
  constructor(
    public auth: AuthService,
    public router: Router,
    public userService: UserService,
    private AdalServ: AdalService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    const expectedRole = route.data.expectedRole;
    const token = localStorage.getItem('AuthToken');

    if (token == null) this.AdalServ.logOut();

    // decode the token to get its payload
    const tokenPayload: any = decode(token);

    const userID = tokenPayload.UserGuidID.toUpperCase();
    if (!this.auth.isAuthenticated() || tokenPayload.IsAdmin !== expectedRole) {
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
}
