<div *blockUI="'edit-departament'">

    <h1 mat-dialog-title style="font-weight: 350;font-size: 30px;" >Edit Departament </h1>
    <form class="example-form">
    <div mat-dialog-content >
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput type="text" [formControl]="name" >
            <mat-error *ngIf="name.invalid">Please enter email address</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
            <mat-label>Logo</mat-label>
            <input matInput [formControl]="logo" >
            <mat-error *ngIf="logo.invalid">Username is required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12">
          <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
            <mat-label>Address</mat-label>
            <input matInput [formControl]="address" >
            <mat-error *ngIf="address.invalid">Address is required</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12">
          <mat-form-field class="example-full-width" style="display: block;" appearance="fill">
            <mat-label>Description</mat-label>
            <textarea matInput [formControl]="description" rows="4" ></textarea>
            <mat-error *ngIf="description.invalid">About me is required</mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>

    <mat-dialog-actions align="end" class="mt-2">
      <button mat-button (click)="onNoClick()" >Cancel</button>
      <button mat-button mat-flat-button color="primary" (click)="onEditSubmit()" [disabled]=" !name.valid || !logo.valid || !description.valid || !address.valid" >Submit</button>
    </mat-dialog-actions>

    
  </form>


</div>