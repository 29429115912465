
<h3 class=" p-3 display-4">Workflow List</h3>


<spinner *ngIf="spinner"></spinner>
<div *ngIf="!spinner" class="animate">

    <div class="card p-3 m-3 shadow-sm">

        <div class="card-body ">

            <div class="">
                <div class="d-flex justify-content-between align-items-center">

                    <div>
                        <mat-form-field style="width: 300px;" class="mr-3">
                            <mat-label>Filter by workflow name</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                        </mat-form-field>
                    </div>
                    <div>
                        <button class="btn bg-primary text-white" (click)="addWorkflow()"><i class="fas fa-plus"></i> Create Workflow</button>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" matSort>


                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let element" (click)="editWorkflow(element)" style="cursor: pointer;">
                       {{element.name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef > Description </th>
                        <td mat-cell *matCellDef="let element">
                         {{element.description}}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="createdDate">
                        <th mat-header-cell *matHeaderCellDef> Created date </th>
                        <td mat-cell *matCellDef="let element">
                         {{element.createdDate | date}}
                        </td>
                    </ng-container>
               

                    <ng-container matColumnDef="isActive">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let element">
                                <span *ngIf="!element.isActive" class="badge badge-danger p-1">InActive</span>
                                <span *ngIf="element.isActive" class="badge badge-success p-1">Active</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button style="color:  #386CB9;" (click)="editWorkflow(element)" title="view">
                                <mat-icon>tab</mat-icon>
                            </button>
                            <button *ngIf="element.isActive" mat-icon-button style="color: #386CB9;" title="Change status"
                                (click)="deleteWorkflow(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button *ngIf="!element.isActive" mat-icon-button style="color: #386CB9;" title="change status"
                            (click)="deleteWorkflow(element)">
                            <mat-icon>add</mat-icon>
                            </button>

                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10,20,50]"></mat-paginator>


            </div>
        </div>
    </div>


</div>