import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilderService } from 'src/app/services/FormBuilder/formBuilder.services';
import decode from 'jwt-decode';

@Component({
  selector: 'app-shared-form',
  templateUrl: './shared-form.component.html',
  styleUrls:['./shared-form.component.css']
})
export class SharedFormComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource: MatTableDataSource<any>;
  public allForms: any;
  public spinner: boolean = true;
  public userLoggedIn: string;
  public forms = new FormControl('', [Validators.required]);

  displayedColumns: string[] = ['Form', 'FormName','Description', 'CreatedDate', 'NumberOfUsage', 'Actions'];


  constructor(public dialog: MatDialog,
    private formService: FormBuilderService) { }
    ngAfterViewInit(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

  ngOnInit(): void {
    this.getSharedForms();
    const token = localStorage.getItem('AuthToken');
    // decode the token to get its payload
    const tokenPayload: any = decode(token);
    this.userLoggedIn = tokenPayload.Email;

  }
  private getSharedForms(): void {
    this.spinner = true;

    this.formService.getAllFormsCreated().subscribe(resp => {
      this.allForms = resp.body;
      this.allForms = this.allForms.filter(form => form.createdBy != this.userLoggedIn);
      this.spinner = false;
      this.dataSource = new MatTableDataSource<any>(this.allForms);
      this.ngAfterViewInit();
    });
  }

}
